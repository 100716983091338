import React,{useEffect} from "react";
import { useScreenSize } from "../ResizeHook";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useTranslation } from "react-i18next";
const CustomPagination = (props: {
    count: number,
    rowsPerPage: number,
    page: number,
    onChangePage: (newPage: number) => void,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  }) => {
    const { count, rowsPerPage, page, onChangePage, setCurrentPage } = props;
    const screenSize = useScreenSize();
    const {t} = useTranslation();
  
    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
      onChangePage(newPage - 1);
    };
  
    useEffect(() => { setCurrentPage(page); }, [page])
  
    // Implement your custom rendering logic here
    return (
      <Pagination
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center !important",
        }}
        color="primary"
        variant="outlined"
        shape="rounded"
        size={screenSize.width <= 500 || (screenSize.width < 1005 && screenSize.width > 760) ? "small" : "medium"}
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        onChange={handlePageChange}
        boundaryCount={screenSize.width <= 540 || (screenSize.width < 1005 && screenSize.width > 760) ? 0 : 1}
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component="div"
            // sx={{
            //   fontFamily: 'Montserrat',
            //   margin: "0px 1px !important",
            //   border: "1px solid rgba(0, 0, 0, 0.23)!important",
            //   borderRadius: "4px !important",
            //    padding: "0px 4px !important",
            //   '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF', border: '1px solid rgba(25, 118, 210, 0.5) !important' },
            // }}
            sx={{
                fontFamily: 'Montserrat',
                margin: "0px 1px !important",
                border: `1px solid ${item.selected ? 'rgba(25, 118, 210, 0.5)' : 'rgba(0, 0, 0, 0.23)'} !important`,
                borderRadius: "6px !important",
                padding: "0px 4px !important",
                backgroundColor: item.selected ? '#006FF4' : 'transparent',
                color: item.selected ? '#FFFFFF' : 'inherit',
                '&:hover:not(:active)': {
                    backgroundColor: item.selected ? '#006FF4' : '#006FF4',
                    color: item.selected ? '#FFFFFF' : '#FFFFFF',
                    border: item.selected ? '1px solid rgba(25, 118, 210, 0.5) !important' : '1px solid rgba(0, 0, 0, 0.23) !important',
                },
                "&.Mui-selected": {
                backgroundColor: "#006FF4",
                color: "white",
                borderColor: "transparent",
                borderRadius: "6px"
              }
            }}
            slots={{
              previous: () => screenSize.width <= 768
                ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
              next: () => screenSize.width <= 768
                ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
            }}
          />
        )}
      />
    );
  };

  export default CustomPagination;