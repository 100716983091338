import React from "react";
import userIcon from "../../../assets/images/user-img.png";
import NotificationCard from "../NotificationCard";
import ProfileCard from "../ProfileCard";
import ExchangeRatesCard from "../ExchangeRatesCard";
import RightSideBarProps from "./RightSideBarProps";
import ExpeneAndPayments from "../Expense&Payments";
import AccountSwitch from "../AccountsSwitch";
import "./index.css";
import { WebchatWidget } from "../WebChat/src/components/WebchatWidget";
import { Provider } from "react-redux";
import { store } from "../WebChat/src/store/store";
import InterestAccountCard from "../InterestAccountCard";

const RightSideBar = (props: RightSideBarProps) => {
  const firstName: any = localStorage.getItem("firstName");
  const lastName: any = localStorage.getItem("lastName");

  return (
    <div className="right-bar">
      <ProfileCard firstName={firstName} lastName={lastName} image={userIcon} />
      {props.accountSwitch ? <AccountSwitch /> : null}
      <NotificationCard />
      <div>
        {props.exchangeRates ? <ExchangeRatesCard /> : null}
        {props.expense_payments ? <ExpeneAndPayments /> : null}
        {/* {props.interestAccount ? <InterestAccountCard /> : null} */}
      </div>
      {/* <Provider store={store}>
        <WebchatWidget />
      </Provider> */}
    </div>
  );
};

export default RightSideBar;
