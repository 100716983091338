import { ConvertPayResponse, ConvertResponse } from "./interface";
import { createSlice } from "@reduxjs/toolkit";
import { ConvertCurr, ConvertPay } from "./operations";

const initialState = {
  data: {},
} as ConvertResponse;

const convertDetailsSlice = createSlice({
  name: "convertDtails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ConvertCurr.pending, (state: any) => {
      state.data.loading = true;
    });
    builder.addCase(ConvertCurr.fulfilled, (state: any, action) => {
      state.data = action.payload;
      state.data.loading = false;
    });
    builder.addCase(ConvertCurr.rejected, (state: any) => {
      state.data.loading = true;
    });
  },
});

const initialPayState = {
  data: {},
} as ConvertPayResponse;
const convertDetailsPaySlice = createSlice({
  name: "convertPayDtails",
  initialState: initialPayState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ConvertPay.pending, (state: any) => {
      state.data.loading = true;
    });
    builder.addCase(ConvertPay.fulfilled, (state: any, action) => {
      state.data = action.payload;
      state.data.loading = false;
    });
    builder.addCase(ConvertPay.rejected, (state: any) => {
      state.data.loading = true;
    });
  },
});

export default {
  convertDetailsSlice: convertDetailsSlice.reducer,
  convertDetailsPaySlice: convertDetailsPaySlice.reducer,
};
