import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import {
  GetUserCardsPayload,
  GetUserCardsResponse,
  GetCardDetailsResponse,
  GetCardDetailsPayload,
  CardPaymentDetailsTokenResponse,
  CardPaymentDetailsTokenPayload,
  PinControlTokenResponse,
  PinControlTokenPayload,
  UpdateCardDataResponse,
  UpdateCardDataPayload,
  CreateCardResponse,
  CreateCardPayload,
  ReauthenticatePasswordResponse,
  ReauthenticatePasswordPayload,
  CardTopUpResponse,
  CardTopUpPayload,
  AllCardsNotificationsResponse,
  AllCardsNotificationsPayload,
  CreateVirtualCardResponse,
  CreateVirtualCardPayload,
  AllCardsOrdersResponse,
  AllCardsOrdersPayload,
  ActivateCardResponse,
  ActivateCardPayload,
  AllCardsNotificationsPaginatedPayload,
  CreateCardPromptResponse,
  CreateCardPromptPayload,
  CardAutoTopUpTogglePayload,
  CardAutoTopUpToggleResponse,
  CardUnloadResponse,
  CardUnloadPayload,
} from "./interface";

// get all cards created by user on B4B from our DB
export const getAllCards = createAsyncThunk<
  GetUserCardsResponse, GetUserCardsPayload
>("cards/allCards", async (payload) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/allCards/${payload.userId}`,
      "get",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    // console.log("Error getting all user cards:", error)
    throw new Error("Error getting all user cards.");
  }
});

// get specific card details from B4B
export const getCardDetails = createAsyncThunk<
  GetCardDetailsResponse, GetCardDetailsPayload
>("cards/getCard", async (payload) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/getCard/${payload.userId}/${payload.cardId}`,
      "get",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    // console.log("Error getting specific card details:", error)
    throw new Error("Error getting specific card details.");
  }
});

// get JWT Token generated from the BE for virtual card payment details
export const initiateCardPaymentDetails = createAsyncThunk<
  CardPaymentDetailsTokenResponse, CardPaymentDetailsTokenPayload
>("cards/cardDetails", async ({ userId, cardId }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/cardDetails/${userId}/${cardId}`,
      "post",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    // console.log("Error initiating card payment details:", error)
    throw new Error("Error creating JWT token for card PAN authentication.");
  }
});

// get physical card PIN from B4B
export const cardPinControlToken = createAsyncThunk<
  PinControlTokenResponse, PinControlTokenPayload
>("cards/cardPin", async ({ userId, ...payload }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/cardPin/${userId}`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    // console.log("Error gettin pin control token:", error)
    throw new Error("Error getting card pin.");
  }
});

// update a cards status or label
export const updatePrepaidCard = createAsyncThunk<
  UpdateCardDataResponse, UpdateCardDataPayload
>("cards/updateCard", async ({ userId, cardLabel, ...payload }) => {
  const token = localStorage.getItem("token");
  const formattedURL = `cards/updateCard/${userId}${cardLabel ? `?cardLabel=${encodeURIComponent(cardLabel)}` : ''}`;
  try {
    const response = await apiHelper(
      formattedURL,
      "patch",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error updating B4B card.");
  }
});

// order a physical card on B4B
export const createPhysicalCard = createAsyncThunk<
  CreateCardResponse, CreateCardPayload
>("cards/createCard", async (payload) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/createCard/`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error creating a physical card.");
  }
});

// create a virtual card on B4B
export const createVirtualCard = createAsyncThunk<
  CreateVirtualCardResponse, CreateVirtualCardPayload
>("cards/createVirCard", async (payload) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/createVirCard/`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error creating a virtual card.");
  }
});

// reauthenticate user pasword for SCA requests
export const reauthenticatePassword = createAsyncThunk<
  ReauthenticatePasswordResponse, ReauthenticatePasswordPayload
>("cards/reauthPassword", async ({ userId, ...payload }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/reauthPassword/${userId}`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error reauthenticating user password.");
  }
});

// topup B4B Master prepaid card
export const cardTopUp = createAsyncThunk<
  CardTopUpResponse, CardTopUpPayload
>("cards/cardTopup", async ({ userId, ...payload }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/cardTopup/${userId}`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error reauthenticating user password.");
  }
});

// unload card 
export const unloadCard = createAsyncThunk<
CardUnloadResponse, CardUnloadPayload
>("card/cardUnload", async ({ userId, ...payload }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/cardUnload/${userId}`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Card unload unsuccessful. Please try again.");
  }
})

// get all B4B notifications
export const getAllCardsNotifications = createAsyncThunk<
  AllCardsNotificationsResponse, AllCardsNotificationsPayload
>("cards/cardNotifications", async ({ userId }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/cardNotifications/${userId}`,
      "get",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching all user card notifications.");
  }
});
// get all B4B notifications
export const getAllCardsNotificationsPaginated = createAsyncThunk<
  AllCardsNotificationsResponse, AllCardsNotificationsPaginatedPayload
>("cards/cardNotifications/paginated", async (payload) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/cardNotifications/paginated/${payload.userId}?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
      "get",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error("Error fetching all user card notifications.");
  }
});

// get all physical card orders made to B4B from our DB
export const getAllCardOrders = createAsyncThunk<
  AllCardsOrdersResponse, AllCardsOrdersPayload
>("cards/cardOrders", async ({ userId }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/cardOrders/${userId}`,
      "get",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching all user card notifications.");
  }
});

// activate physical card 
export const activatePhysicalCard = createAsyncThunk<
  ActivateCardResponse, ActivateCardPayload
>("cards/activateCard", async ({ userId, ...payload }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/activateCard/${userId}`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`${error}`);
  }
});

// show user the create card prompt
export const showCreateCardPrompt = createAsyncThunk<
  CreateCardPromptResponse, CreateCardPromptPayload
>("cards/createCardPrompt", async ({ userId }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/createCardPrompt/${userId}`,
      "post",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`${error}`);
  }
});

export const cardAutoTopupToggle = createAsyncThunk<
CardAutoTopUpToggleResponse, CardAutoTopUpTogglePayload
>("cards/toggleAutoTopup", async ({ userId, ...payload }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await apiHelper(
      `cards/toggleAutoTopup/${userId}`,
      "post",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error configuring Auto Top-Up.");
  }
});