import { ChangePasswordResponse, LoginResponse, OTPResponse, ProfileDetailsResponse, ProfileUpdateResponse, QrResponse, UploadCompanyLogoResponse, VerifyOTPResponse } from "./interface";
import { createSlice } from "@reduxjs/toolkit";
import {
  ForgotPasswordOp,
  Logout,
  QrVerification,
  ResetPasswordOp,
  UserLogin,
  UserRegistration,
  SendOTP,
  OTPVerification,
  SendOTPVoice,
  UploadCompanyLogo,
  changePasswordOp
} from "./operation";
import { resetStatus, updateSelectedMerchant } from "../../actions";

const initialStateProfile = {
  data: {},
} as ProfileDetailsResponse;

const initialStateUpdateProfile = {
  data: {},
} as ProfileUpdateResponse;

const initialStateLogin = {
  data: {},
} as LoginResponse;

const initialStateQr = {
  data: {},
} as QrResponse;

const initialStateOtp = {
  data: {},
} as VerifyOTPResponse;

const initialStateSendOTP = {
  message: "",
  status: ""
} as OTPResponse;

const initialStateSocket = {
  isEstablishingConnection: false,
  isConnected: false,
  threeDSData: null,
  threeDSModal: false,
  cardNotifications: [], // Add this to store card notifications
  jwtToken: "",
  userId: "",
  callbackResponse: null,
  loader: false,
  confirmation: false,
};

const initialStateCompanyLogo = {
  message: "",
  uploadStatus: false,
  status: ""
} as UploadCompanyLogoResponse;

const initialStateChangePassword = {
  message: "",
  status : ""
} as ChangePasswordResponse;

const profileDetailsSlice = createSlice({
  name: "profileDtails",
  initialState: initialStateProfile,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserRegistration.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(UserRegistration.fulfilled, (state: any, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(UserRegistration.rejected, (state: any) => {
      state.status = "failed";
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = {};
      state.status = "";
    });
  },
});
const updateProfileDetailsSlice = createSlice({
  name: "updateProfileDtails",
  initialState: initialStateUpdateProfile,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserRegistration.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

const loginDetailsSlice = createSlice({
  name: "loginDetails",
  initialState: initialStateLogin,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserLogin.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

const otpDetailsSlice = createSlice({
  name: "sendOTP",
  initialState: initialStateSendOTP,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SendOTP.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(SendOTP.fulfilled, (state: any, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(SendOTP.rejected, (state: any) => {
      state.status = "failed";
    });
    builder.addCase(resetStatus, (state) => {
      state.status = ''; // Reset status to an empty string
    });
  },
});

const sendOtpCallSlice = createSlice({
  name: "sendOTPCall",
  initialState: initialStateSendOTP,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SendOTPVoice.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(SendOTPVoice.fulfilled, (state: any, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(SendOTPVoice.rejected, (state: any) => {
      state.status = "failed";
    });
    builder.addCase(resetStatus, (state) => {
      state.status = ''; // Reset status to an empty string
    });
  },
});

const qrDetailsSlice = createSlice({
  name: "qrDetails",
  initialState: initialStateQr,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(QrVerification.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

const selectedMerchantSlice = createSlice({
  name: "selectedMerchant",
  initialState: initialStateQr,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(QrVerification.fulfilled, (state: any, action) => {
      state.data = action.payload.data?.userMerchants[0]?.merchantId;
    });

    builder.addCase(updateSelectedMerchant, (state: any, action) => {
      state.data = action.payload
      // state.data = action.payload.data.userMerchants[0];
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});



const otpVerifyDetailsSlice = createSlice({
  name: "otpVerifyDetails",
  initialState: initialStateOtp,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(OTPVerification.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

const forgotPassDetailsSlice = createSlice({
  name: "forgotPassDetails",
  initialState: initialStateQr,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ForgotPasswordOp.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

const resetPassDetailsSlice = createSlice({
  name: "resetPassDetails",
  initialState: initialStateQr,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ResetPasswordOp.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

const socketSlice = createSlice({
  name: "socketSlice",
  initialState: initialStateSocket,
  reducers: {
    startConnecting: (state, action) => {
      state.isEstablishingConnection = true;
      state.jwtToken = action.payload.jwtToken;
      state.userId = action.payload.userId;
    },
    connectionEstablished: ((state) => {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    }),
    threeDSWebhookReceived: (state, action) => {
      state.threeDSData = action.payload;
      state.threeDSModal = true;
    },
    threeDSResponseReceived: (state) => {
      state.threeDSData = null;
      state.threeDSModal = false;
    },
    disconnect: (state) => {
      state.isEstablishingConnection = false;
      state.isConnected = false;
      state.threeDSData = null;
      state.threeDSModal = false;
      state.cardNotifications = [];
      state.jwtToken = "";
      state.userId = "";
      state.callbackResponse = null;
      state.loader = false;
      state.confirmation = false;
    },
    callbackResponseReceived: (state, action) => {
      state.callbackResponse = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setConfirmation: (state, action) => {
      state.confirmation = action.payload;
    },
    clearCallbackResponse: (state) => {
      state.callbackResponse = null;
      state.loader = false;
      state.confirmation = false;

    },
    emitWebhookResponse: (state, action) => {
      // No need to change state directly; handled by middleware
    },
    // cardNotificationReceived: (state, action) => {
    //   state.cardNotifications = [...state.cardNotifications, action.payload];
    // },
  },
});

const companyLogoSlice = createSlice({
  name: "companyLogo",
  initialState: initialStateCompanyLogo,
  reducers: {
    resetCompanyLogoData: (state) => {
      state.message = "";
      state.status = "";
      state.uploadStatus = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(UploadCompanyLogo.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(UploadCompanyLogo.fulfilled, (state: any, action) => {
      state.message = action.payload.message;
      state.uploadStatus = action.payload.uploadStatus;
      state.status = "succeeded";
    });
    builder.addCase(UploadCompanyLogo.rejected, (state: any) => {
      state.status = "failed";
    });
  },
});

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: initialStateChangePassword,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changePasswordOp.pending, (state: any) =>{
      state.status = "loading";
    });
    builder.addCase(changePasswordOp.fulfilled,(state: any, action) => {
      state.status = "succeeded";
      state.message = action.payload.message;
    });
    builder.addCase(changePasswordOp.rejected,(state: any) => {
      state.status = "failed";
      state.message = "";
    });
  }
});

export const socketActions = socketSlice.actions;
export const companyLogoActions = companyLogoSlice.actions;

export default {
  profileDetailsSlice,
  loginDetailsSlice,
  qrDetailsSlice,
  forgotPassDetailsSlice,
  resetPassDetailsSlice,
  sendOtpCallSlice,
  otpDetailsSlice,
  otpVerifyDetailsSlice,
  updateProfileDetailsSlice,
  socketSlice,
  selectedMerchantSlice,
  companyLogoSlice,
  changePasswordSlice
};
