import React, { useState } from "react";
import { Col, Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import BasicButton from "../../../components/shared/Buttons/Basic";
import { useNavigate } from "react-router-dom";
import { UploadCompanyLogo } from "../../../redux/toolkit/auth/operation";
import { AppDispatch } from "../../../redux/store";
import { UploadCompanyLogoPayload } from "../../../redux/toolkit/auth/interface";
import { getCompanyLogoSelec } from "../../../redux/toolkit/auth/selector";
import { companyLogoActions } from "../../../redux/toolkit/auth/reducer";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 1500,
})

const UploadLogoModal = (props: {
  showUploadLogoModal: boolean,
  handleClose: () => void,
  companyID: string
}) => {
  const { showUploadLogoModal, handleClose, companyID } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [companyLogoBase64, setCompanyLogoBase64] = useState<string | undefined>("");
  const [companyLogoFile, setCompanyLogoFile] = useState<Blob | null>();
  const [logoError, setLogoError] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const getCompanyLogoSelector = useSelector(getCompanyLogoSelec);

  const handleClick = () => {
    if (companyLogoFile) {
      // call upload API
      const payload: UploadCompanyLogoPayload = {
        companyID,
        companyLogoBase64: companyLogoFile
      }

      dispatch(UploadCompanyLogo(payload))
        .unwrap()
        .then((res) =>
          Toast.fire({
            icon: 'success',
            title: res.message,
            iconColor: 'green'
          }).then(() => {
            dispatch(companyLogoActions.resetCompanyLogoData());
            // navigate to home login page upload
            navigate("/")
          }
          )
        );
    }
  };

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;  // Early return if no file is selected

    // 1,000,000 = 1 MB
    if (file.size > 1000000) {
      setLogoError("Selected image is too large, please choose another.");
      setCompanyLogoBase64('');  // Clear the existing image preview
      setCompanyLogoFile(null);   // Clear the file as well
      return;
    }

    setLogoError('');
    const reader = new FileReader();

    reader.onloadend = () => setCompanyLogoBase64(reader.result as string);  // Directly set the Base64 string

    reader.readAsDataURL(file);
    setCompanyLogoFile(file);  // Store raw file for potential server upload
  };

  return (
    <Modal
      show={showUploadLogoModal}
      // onHide={handleClose}
      centered >
      <Modal.Header style={{ borderBottom: "0px" }}>
        <div>
          <Modal.Title>Upload Company Logo</Modal.Title>
        </div>
        {/* <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton> */}
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">
        <p style={{ fontWeight: '600' }}>
          Would you like to upload a logo for your company?
        </p>

        {companyLogoFile && (
          <div style={{ display: "flex", justifyContent: "center", margin: "25px" }}>
            <img
              className="componyLogo"
              style={{ width: 200 }}
              src={URL.createObjectURL(companyLogoFile)}
              alt="company_logo"
            />
          </div>
        )}

        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleLogoUpload}
        />

        {logoError &&
          <p style={{ color: "red", margin: 0, paddingTop: '10px' }}>{logoError}</p>
        }

        <p style={{ fontSize: "10px", margin: 0, marginTop: "20px", color: "#ADB6C0" }}>Maximum file size allowed is 1MB</p>
      </Modal.Body>
      <ModalFooter style={{ borderTop: "0px" }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              onClick={handleClick}
              isLoading={getCompanyLogoSelector.status === "loading"}
              text={"Upload now"}
              style={{ width: '95%' }}
              disabled={!companyLogoFile}
            />
          </Col>

          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              variant="outlined"
              type="button"
              text={"Maybe later"}
              onClick={handleClose}
              style={{ width: '95%' }}
              disabled={getCompanyLogoSelector.status === "loading"}
            />
          </Col>
        </div>
      </ModalFooter>
    </Modal>
  )
};

export default UploadLogoModal;