import React from "react";
import { Col } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";

import DisabledCard from "./DisabledSettingsCard";

import "./index.css";

const SettingButtons = (props: { data: any }) => {
  const { data } = props;

  return (
    <>
      {data.isDisabled ? (
        <Tooltip title={`${data.tooltipMessage}`}>
          <div className="disabledBox cardSetting-MainBox">
            <DisabledCard data={props} />
          </div>
        </Tooltip>
      ) : (
        <div className={`mainBox cardSetting-MainBox ${data.isDisabled}`} onClick={data.onClick}>
          <Col className="mainBox-icon col-2 col-md-3 col-lg-3 ">
            <img src={data.mainIcon} alt={data.name} />
          </Col>
          <Col className="mainBox-text col-8">{data.name}</Col>
        </div>
      )}
    </>
  );
};

export default SettingButtons;