import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";

export const getAllCardConfig = {
    key: "allCards",
    storage: sessionStorage,
};

export const getAllCardOrdersConfig = {
    key: "cardOrders",
    storage: sessionStorage,
};