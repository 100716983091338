let BASE_URL = ""

if(process.env.REACT_APP_ENV === "staging") {
  BASE_URL = "https://bkstaging.onemoneyway.com/"
}

if (process.env.REACT_APP_ENV === "production") {
  BASE_URL = "https://bkprod.onemoneyway.com/"
}
if (process.env.REACT_APP_ENV === "dev") {
  BASE_URL = "http://localhost:3001/"
}

export default BASE_URL