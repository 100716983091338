import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { Modal, Spinner,Button } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format, isValid } from "date-fns";
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");
// import { getSymbolFromCurrency } from 'currency-symbol-map';
// import { updatePaymentStatus } from "../../../redux/toolkit/sendFunds/operations";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
// import { Spinner } from "react-bootstrap";
// import Card from "react-bootstrap/Card";
// import { Row, Col, } from "react-bootstrap";
// import { customToolBarIcons } from "../../../constants/stringConstants/muiDataTable";
import {customToolBarIcons} from '../../shared/MuiDataTable/ToolbarIcons'
import CustomPagination from "../CustomPagination";
import { DeleteBlackIcon, EditPenIcon, PaymentVerifictionIcon,ConfimationImage } from "../../../constants/Images";
import Accordion from 'react-bootstrap/Accordion';
import InputTypeText from "../../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../../components/shared/Buttons/Basic";
import ModalHeader from "../../../components/shared/Modal/ModalHeader";
import FundsTransferUpdateForm from "../../../pages/SendFunds/FundTransferUpdateForm";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import SmsIcon from "@mui/icons-material/Sms";
import OtpInput from "../../../components/shared/OTP";
import CallIcon from "@mui/icons-material/Call";
import CustomIconButton from "../../../components/shared/Buttons/IconButton";
import TransactionDetail from "../TransactionDetail";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import Swal from "sweetalert2";



import {
    OTPVerification,
    SendOTP,
    SendOTPVoice,
  } from "../../../redux/toolkit/auth/operation";


export const CustomSchedulingDataTable = ({ data, updatePaymentStatus, SendScheduledFundsData, getSchPayments, resendCustomFunds, deletePayment }: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingId, setLoadingId] = useState("");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [code, setCode] = useState("");
    const [loading2, setLoading2] = useState(false);
    const [twofaErr, setTwofaErr] = useState("");
    const [show2fa, setShow2fa] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const dispatch = useDispatch<AppDispatch>();
    const toggle2faHandle = () => setShow2fa(!show2fa);
    const userId: any = localStorage.getItem("userId");
    
    const userPhoneNo: any = localStorage.getItem("userPhone");
    const [loadingOTP, setLoadingOTP] = useState(false);
    const [showOTPInput, setShowOTPInput] = useState(false);
    const [otpBtnText, setOtpBtnText] = useState("Send");
    const [otp, setOtp] = useState("");
    const onChange = (value: string) => setOtp(value);
    const [loadingOTPVerify, setLoadingOTPVerify] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState("");
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [dataDetail, setDataDetail] = useState({})
    const [resendLoadingId, setResendLoadingId] = useState(null);


    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        "root": {
                            padding: '10px',
                            fontFamily: 'Montserrat',
                            fontSize: 18,
                            // backgroundColor: '#CDCAC6',
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: 'Montserrat',
                            // fontSize:14,
                            fontWeight: "600",
                            textAlign: 'left',
                            paddingLeft: 0
                        },
                    }
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            fontWeight: "500 !important",
                        }

                    }
                },
                // MuiToolbar: {
                //   styleOverrides:{
                //     regular: {
                //     minHeight: '8px',
                //   }}
                // }
                MuiList:{
                    styleOverrides: {
                        root: {
                            display:"flex",
                            flexDirection:'column',
                            background:'#ffffff',
                        }
                    }
                },
                MuiTypography: {
                    styleOverrides: {
                      root: {
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#ADB6C0',
                      }
                    }
                  },
                  MUIDataTableFilter:{
                    styleOverrides:{
                      root:{fontFamily:'Montserrat'},
                      reset:{
                        // backgroundColor:"red",
                        '& button': { 
                          marginLeft:'10px',
                          paddingLeft:"6px",
                        }
                      },
                    }
                  },
                  MuiFormControl:{
                    styleOverrides:{
                      root:{
                        padding:'35px 20px 20px 20px'
                      }
                    }
                  },
            }
        });

    const columns = [
        {
            name: "recurringDate",
            label: t("recurringPayments.dateAndDay"),
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    const dateStr = data[dataIndex]?.recurringDate;
                    const dateOnly = dateStr ? dateStr.split("T")[0] : dateStr;

                    let date = null;
                    if(isValid(new Date(data[dataIndex]?.recurringDate))) {
                        const [year, month, day] = dateOnly.split('-') 
                        date = `${month}-${day}-${year}`;
                    }
                    else {
                        const [year, month, day] = data[dataIndex].recurringDate.split('-') 
                        date = `${month}-${day}-${year}`;
                    }

                    return <div style={{ minWidth: "max-content" }}> {date} </div>
                    // return <div style={{ minWidth: "max-content" }} >{
                    //     isValid(new Date(data[dataIndex]?.recurringDate)) ? dateOnly : data[dataIndex]?.recurringDate
                    // }
                    // </div>
                }
            },
        },
        {
            name: "beneficiary_name",
            label: t("recurringPayments.beneficiary"),
            options: { filter: true, sort: false },
        },
        {
            name: "beneficiary_iban",
            label: "IBAN",
            options: { filter: true, sort: false },
        },
        {
            name: "payment_reference",
            label: t("recurringPayments.paymentReference"),
            options: { filter: true, sort: false },
        },
        {
            name: "payment_amount",
            label: t("recurringPayments.amount"),
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <div style={{ minWidth: "55px" }} >{data[dataIndex]?.payment_amount}</div>
                }
            },
        },
        {
            name: "payment_currency",
            label: t("recurringPayments.currency"),
            options: { filter: true, sort: false },
        },
        {
            name: "description",
            label: t("schedulingPayments.description"),
            options: { filter: true, sort: false },
        },
        {
            name: "status",
            label: t("recurringPayments.status"),
            options: { filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return (
                      <div style={{ minWidth: "55px" }}>
                        {t(`paymentStatus.${data[dataIndex]?.status}`)}
                      </div>
                    );
                },
             },
        },
        {
            name: "payment_amount",
            label: t("recurringPayments.action"),
            options: {
                filter: false, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <>
                    <Box display="flex" alignItems="center" justifyContent="space-between">   
                        <IconButton className={"scheduling-check-approve-icon"} style={{padding: "0px", display:'flex',  justifyContent: 'center'}}  disabled={isLoading}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (data[dataIndex]?.is2FAVerified === false) {
                                    toggle2faHandle();
                                    setCurrentRecord(data[dataIndex]);
                                    // approveHandler(data[dataIndex])
                                }
                            }}>
                        { data[dataIndex]._id === loadingId && isLoading ? <CircularProgress color="inherit" size={window.innerWidth < 576 ? 8 : 17} />
                            : data[dataIndex]?.is2FAVerified ?
                                <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.approved")}>
                                    <CheckCircleIcon sx={{ color: "#006FF4", }} />
                                </Tooltip>
                                :
                                <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.clickToApprove")}>
                                    <Button className="approve-btn-class" variant="outline-primary">
                                        {t("schedulingPayments.approve")}
                                    </Button>
                                </Tooltip>
                        }
                        </IconButton>
                            <IconButton disabled={data[dataIndex]?.status?.toLowerCase() === 'processing' ? false: true} onClick={(event) => {
                                event.stopPropagation();
                                setShowUpdateForm(true)
                                setSelectedRecord(data[dataIndex]);
                            }}>
                                <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.edit")}>
                                    <img src={EditPenIcon} style={{
                                        filter: data[dataIndex]?.status?.toLowerCase() === 'processing' ? 'none' : 'grayscale(100%)',
                                        cursor: data[dataIndex]?.status?.toLowerCase() === 'processing' ? 'pointer' : 'not-allowed',
                                    }} />
                                </Tooltip>
                            </IconButton>

                           

                            <IconButton disabled={ data[dataIndex]?.status?.toLowerCase() !== 'processing'} onClick={(event) => {
                                event.stopPropagation();
                                deleteHandler(data[dataIndex]?._id);
                            }}>
                                <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.delete")}>
                                    {/* <DeleteIcon sx={{ color: "red" }} /> */}

                                    <img
                                        src={DeleteBlackIcon}
                                        style={{
                                            filter: 'invert(31%) sepia(78%) saturate(747%) hue-rotate(193deg) brightness(97%) contrast(98%)'
                                        }}
                                    />
                                </Tooltip>
                            </IconButton>
                            {
                                data[dataIndex]?.status?.toLowerCase() === 'failed' ? (
                                    <IconButton onClick={async (event) => {
                                        console.log('... its clicked ')
                                        event.stopPropagation();
                                        const currentId = data[dataIndex]?._id;
                                        setResendLoadingId(currentId);
                                        // setResendLoadingId(data[dataIndex]?._id);
                                        // resendCustomFunds({id: data[dataIndex]?._id});
                                        try {
                                            await resendCustomFunds({ id: currentId });
                                            // console.log('Resend successful'); 
                                          }  finally {
                                            setResendLoadingId(null);
                                          }
                                    }}
                                    disabled={resendLoadingId === data[dataIndex]._id}
                                    >
                                        <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.resend")}>
                                        {resendLoadingId === data[dataIndex]._id ? (
                <Spinner animation="border" size="sm" />
            ) : (
                <HistoryIcon color="primary" />
            )}
                                        </Tooltip>
                                    </IconButton>
                                ): null
                            }
                    {/* <IconButton onClick={() => {
                            setIsShowDetail(true);
                            setDataDetail(data[dataIndex]);
                        }}>
                            <Tooltip title={""}>
                                <VisibilityIcon sx={{
                                    color: "#006FF4",
                                }} />
                            </Tooltip>
                    </IconButton> */}
                    {/* <button
                        className="btn-transfer"
                        style={{ background: data[dataIndex]?.is2FAVerified ? "#006FF4" : "#f40f00",  cursor: isLoading ? "" : "pointer"}}
                        onClick={() => {
                            if (data[dataIndex]?.is2FAVerified === false) {
                                toggle2faHandle();
                                setCurrentRecord(data[dataIndex]);
                            }
                        }}
                        disabled={isLoading}
                    >
                        {
                           data[dataIndex]._id === loadingId && isLoading ? (
                                <CircularProgress color="inherit" size={window.innerWidth < 576 ? 8: 15} />
                            ): (
                                !data[dataIndex]?.is2FAVerified ? "Approve" : "Approved"
                            )
                        }                        
                    </button> */}
                    {/* <Button onClick={() => { 
                        setShowUpdateForm(true) 
                        setSelectedRecord(data[dataIndex]);
                        }} className="btn-transfer"> Edit </Button> */}
                    </Box>
                    </>

                }
            },
        },
    ];

    const handleSendOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setLoadingOTP(true);
        const payload = {
          phoneNo: userPhoneNo,
        };
        dispatch(SendOTP(payload)).then(() => {
          setLoadingOTP(false);
          setShowOTPInput(true);
          setOtpBtnText("Resend");
        });
      };

    const verifyOTPLengthOnBlur = (e: any) => {
        if (e.length < 6) {
          setOtpErrorMsg("Enter all otp digits");
        } else {
          setOtpErrorMsg("");
        }
      };

    const deleteHandler = async (id: string) => {
        try {
            Swal.fire({
      imageUrl: ConfimationImage,
      title: `${t("sweetAlerts.areYouSure")}`,
      text: `${t("sweetAlerts.deleteRecord")}`,
      // icon: "question",
      showCancelButton: true,
      confirmButtonText: `${t("sweetAlerts.yes")}`,
      cancelButtonText: `${t("sweetAlerts.no")}`,
      customClass: {
        confirmButton: 'modalBtnOK',
        cancelButton: "modalBtnCancel"
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const res = await dispatch(deletePayment({id}));
        await getSchPayments();
       
      } else {
        // User clicked "No" or closed the SweetAlert popup
        // Handle the cancellation or any other action you want
      }
    });
           
        } catch(error) {
            console.log('... error ', error);
        }
        setIsLoading(false)
    }
    
    const approveHandler = async (data: any) => {
        try {
            setIsLoading(true)
            setLoading2(true);
            setLoadingId(data?._id);
            // console.log("approveHandler =>", data);
            // const payload = { id: data._id, status: data.isRecurring ? false : true }
            const payload = {
                // beneficiary_uuid: data?.beneficiary_uuid,
                // description: data?.description,
                // payment_reference: data?.payment_reference,
                // payment_amount: data?.payment_amount,
                // payment_currency: data?.payment_currency,
                // payment_reason: data?.payment_reason,
                // uuid: data?.uuid,
                // userID: data?.userID,
                id: data?._id,
                authCode: otp || code,
                userId: userId
            }
            const res = await dispatch(updatePaymentStatus(payload))
            await getSchPayments();
            // console.log(res)
            setIsLoading(false)
            setLoading2(false);
            setLoadingId("");
            setShow2fa(false);
        } catch (error) {
            setIsLoading(false)
            setLoading2(false);
            setLoadingId("");
        }
        setOtp("");
        setShowOTPInput(false);
    }


    return (
        <>
            <TransactionDetail isShow={isShowDetail} data={dataDetail} />
            {/* 2FA Modal */}
            <Modal
                show={show2fa}
                onHide={toggle2faHandle}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader onClose={() => toggle2faHandle()}/>
                <Modal.Body className="sendFund-2FA-modal">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <div className="mb-2">
                    <img src={PaymentVerifictionIcon} width='100' height='100'/>
                    </div>
                    <div className="paymentVerifyText mb-3">
                    <h2 className="ver-right-title m-0">{t("sendFunds.modal.title")}</h2>
                    </div>
                </div>

                <Accordion>
                    <Accordion.Item eventKey="0" className="p-0 accordionTab">
                    <Accordion.Header>{t("verifyNew.2faDescription")}</Accordion.Header>
                        <Accordion.Body>
                            <form
                                className="verification-form"
                                // onSubmit={() => approveHandler(currentRecord)}
                                >
                                <InputTypeText
                                    placeholder= {t("verifyNew.enterCode")}
                                    onChange={(e) => {
                                    setCode(e.target.value);
                                    setTwofaErr("");
                                    }}
                                    errorMsg={twofaErr}
                                />
                                <BasicButton
                                    // type="submit"
                                    onClick={() => approveHandler(currentRecord)}
                                    text={t("verify.send")}
                                    isLoading={loading2 ? true: false}
                                />
                                </form>
                            </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion>
                    <Accordion.Item eventKey="0" className="mt-4 p-0 accordionTab">
                        <Accordion.Header>{t("verify.otp.description")}</Accordion.Header>
                        <Accordion.Body>
                        <div className="mr-xl-1">
                        <p>{t("verifyNew.otpSmsDescription")}</p>
                        </div>

                        <div className="mr-xl-1">
                        <CustomIconButton 
                            text={
                            loadingOTP ? <>
                                {t("verifyNew.sending")} <Spinner animation={"border"} />
                            </>:
                            // (`${otpBtnText} ${userPhoneNo}`)
                            (
                            `${otpBtnText === 'Send' ? t("verifyNew.send") : t("verifyNew.resend")} ${userPhoneNo}`
                            )
                            }
                            isLoading={loadingOTP?true:false}
                            disabled={loadingOTP ? true : false}
                            onClick={handleSendOTPButtonClick}
                            suffixIcon={<SmsIcon />}
                        />
                        </div>

                        {showOTPInput ? (
                        <>
                            <div
                            style={{
                                alignContent: "center",
                                width: "100%",
                                padding: 10,
                            }}
                            >
                            <OtpInput
                                value={otp}
                                valueLength={6}
                                onChange={onChange}
                                onBlur={verifyOTPLengthOnBlur}
                            />
                            <div className="mr-xl-1">
                                <BasicButton 
                                text={
                                    loadingOTPVerify? t("verifyNew.verifying") : t("verifyNew.verify")
                                }
                                className="mt-2"
                                disabled={loadingOTPVerify ? true : false}
                                onClick={() => approveHandler(currentRecord)}
                                isLoading={isLoading?true:false}
                                />
                            </div>
                            </div>
                            <div className="mr-xl-1">
                            {/* <div
                                onClick={handleSendOTPVoiceButtonClick}
                                style={{ fontSize: 13 }}
                            >
                                {" "}
                                Having trouble Receiving SMS,{" "}
                                {loadingOTPVoice ? (
                                <Spinner
                                    style={{ width: 15, height: 15 }}
                                    animation={"border"}
                                />
                                ) : (
                                <CallIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                />
                                )}{" "}
                                call me instead.
                            </div> */}
                            </div>
                        </>
                        ) : (
                        <></>
                        )}
                    </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </Modal.Body>
            </Modal>
            <Modal
                show={showUpdateForm}
                onHide={() => setShowUpdateForm(!showUpdateForm)}
                backdrop="static"
                keyboard={false}
                size="lg"
                // className={show2FA ? 'd-none' : 'd-block'}
            >
                <ModalHeader title={t("sendFunds.title")} onClose={() => setShowUpdateForm(!showUpdateForm)} />
                <Modal.Body
                // ref={trandferMoneyModalRef}
                >
                    <FundsTransferUpdateForm record={selectedRecord} onClose={() => setShowUpdateForm(!showUpdateForm)} getSchPayments={getSchPayments}/>
                </Modal.Body>
            </Modal>
            {/* <Row> */}
            {/* Content area collomns */}
            {/* <Col sm={12} md={12} lg={12}> */}
            {/* <Card className="p-3 p-xl-5 my-3 my-xl-4"> */}
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title= {<p className="mb-0 recuringTbl">{t("schedulingPayments.title")}</p>}
                    data={data}
                    columns={columns}
                    components={{icons:customToolBarIcons}}
                    options={{
                        onRowClick: (rowData, rowMeta) => {
                            const index = 10*currentPage + (rowMeta.rowIndex)
                            setIsShowDetail(true);
                            setDataDetail(data[index]);
                            console.log('... record ', data[index])
                        },
                        selectableRows: "multiple",
                        selectableRowsHideCheckboxes: true,
                        selectableRowsOnClick: false,
                        // fixedHeader:true
                        responsive: 'standard',
                        textLabels:{ 
                            body:{
                              noMatch: t("recurringPayments.noMatch")
                            },
                            toolbar: {
                                search: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.search")}`,
                                downloadCsv: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.downloadCsv")}`,
                                print: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.print")}`,
                                viewColumns: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.viewColumns")}`,
                                filterTable: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.filterTable")}`,
                              },
                          },
                        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                            <CustomPagination
                              count={count}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onChangePage={changePage}
                              setCurrentPage={setCurrentPage}
                            />
                          )
                    }}
                />
            </ThemeProvider>
            {/* </Card> */}
            {/* </Col> */}
            {/* </Row> */}
        </>
    );
};