import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import { CartPaymentsPayload, CartPaymentsResponse } from "./interface";

export const getMonthlyRevenue = createAsyncThunk<any, any>(
  "cardPayments/getMonthlyRevenue", async (payLoad) => {
    const token = localStorage.getItem("token");
    const auth = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    const res = await apiHelper(`shift4cards/getMonthlyRevenue/`, "post", payLoad, auth);
    return res.data;
  });


export const checkIfDataIsFetched = createAsyncThunk<any, any>(
  "checkIfDataIsFetched/checkIfDataIsFetched", async (payLoad) => {
    const token = localStorage.getItem("token");
    const auth = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    const res = await apiHelper(`shift4cards/checkIfDataIsFetched/`, "post", payLoad, auth);

    return res.data;
  });


export const getPaginatedTransactions = createAsyncThunk<any, any>(
  "getPaginatedTransactionData/getPaginatedTransactionData", async (payLoad) => {




    const token = localStorage.getItem("token");
    const auth = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    const res = await apiHelper(`shift4cards/getPaginatedTransactions`, "post", payLoad, auth);

    return {
      transactions: res.data.data.transactions,
      totalCount: res.data.data.totalCount
    };
  })


export const getGraphData = createAsyncThunk<any, any>(
  "getGraphData/getGraphDataForEachCurrency", async (payLoad) => {





    const token = localStorage.getItem("token");
    const auth = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    const res = await apiHelper(`shift4cards/getGraphData`, "post", payLoad, auth);



    return res.data
  })


export const getStatsData = createAsyncThunk<any, any>(
  "getStatsData/getStatsData", async (payLoad) => {





    const token = localStorage.getItem("token");
    const auth = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    const res = await apiHelper(`shift4cards/getStats`, "post", payLoad, auth);

    console.log("res.data", res.data.merchantInfo
    );


    return res.data.merchantInfo

  })
