import React from "react";
import LeftSideBar from "../../components/shared/LeftSidebar";
import { useTranslation } from "react-i18next";
import RightSideBar from "../../components/shared/RightSidebar";
import Footer from "../../components/shared/Footer";
import "./index.css";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import { AddbeneficiaryForm } from "./AddBenificiaryForm";
import { Col, Row } from "react-bootstrap";
import ExpeneAndPayments from "../../components/shared/Expense&Payments";

const Addbeneficiary = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <div className="addbeneficiary"> */}
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="addbeneficiary" />
      </div> */}
      {/* <div className="left_sidebar position-fixed">
        <LeftSideBar name="addbeneficiary" />
      </div> */}
      {/* <div className="inner__content position-relative pb-5">
        <h1 className="addbene_title">{t("addRecipient.title")}</h1>
        <div className="addbene-card-box">
          <AddbeneficiaryForm />
        </div>
        <Footer />
      </div>
      <RightSideBar expense_payments summaryBox />
    </div> */}

      <Row className="addbeneficiary justify-content-between">
        <Col xs={12} sm={12} md={7} lg={7} xl={7} className="mainFormBox">
          {/* <div className="addbenef-form"> */}
          <div className="basicFormWidth">
          <h1 className="mainHeading">{t("addRecipient.title")}</h1>
          <AddbeneficiaryForm />
          </div>
        </Col>
        <Col md={5} lg={4} xl={4} className="MonthlyRevenueTracker" >
          {/* <MonthlyRevenueTracker /> */}
          <ExpeneAndPayments/>
          {/* <RightSideBar expense_payments summaryBox /> */}
        </Col>
      </Row>
    </>
  );
};

export default Addbeneficiary;
