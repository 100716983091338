/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Table } from "react-bootstrap";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import LeftSideBar from "../../components/shared/LeftSidebar";
import RightSideBar from "../../components/shared/RightSidebar";
import './index.css'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-main">
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="privacypolicy" />
      </div> */}

      {/* <div className="left_sidebar position-fixed">
        <LeftSideBar name="privacyPolicy" />
      </div> */}

      <div className="inner__content">
        <div className="privacyPolicy container">
          <p className="privacy-policy-title font24">One Money Way Privacy Policy</p>
          <div>
            <p className="Privacy-policy-paragraph">
              Hi! We’re OneMoneyWay. We put our Members at the heart of what we
              do.We want to build long-lasting relationships based on
              transparency and trust. This privacy policy aims to give you
              information on how OneMoneyWay collects and processes your
              personal data through your use of this website, including any data
              you may provide through this website when you process payments,
              take actions relating to your account, communicate with our Client
              Managers or during the onboarding process. It is important that
              you read this privacy policy together with any other privacy
              policy or fair processing policy we may provide on specific
              occasions when we are collecting or processing personal data about
              you so that you are fully aware of how and why we are using your
              data. This privacy policy supplements other notices and privacy
              policies and is not intended to override them.
            </p>
            <p className="privacy-policy-title font24">Who we are</p>
            <p className="Privacy-policy-paragraph">
              We’re OMW Europe Ltd, but you’ll know us better as OneMoneyWay.
              We’re a company that respects your privacy and is committed to
              protecting your personal data – which is just what this privacy
              notice is for. Personal data we collect from you will be held by
              OMW Europe Ltd and other entities that are or may become part of
              the OMW Group, collectively referred to as “OneMoneyWay”,“OMW”
              “we” or “us”. We’ll let you know which company you have a
              relationship with when you take out a product or service with us.
              We are registered with the Information Commissioner’s Office
              registration nr ZB449652 Changes to the privacy policy and your
              duty to inform us of changes It is important that the personal
              data we hold about you is accurate and current. Please keep us
              informed if your personal data changes during your relationship
              with us.
            </p>
            <p className="privacy-policy-title font24">OMW is your Data Controller</p>
            <p className="Privacy-policy-paragraph">
              OMW is the Data Controller of your data, which means we’re
              responsible for your personal data processed in relation to your
              use of the products and services accessible via the OneMoneyWay
              Platform. When you receive services via the OneMoneyWay Platform
              we collect, process, use and are responsible for certain personal
              data about you. When we do so, we are regulated under the
              applicable laws on the protection of personal data, privacy and
              electronic communications, including but not limited to The Data
              Protection Act 2018 (the “DPA 2018”, the United Kingdom General
              Data Protection Regulation (the “UK GDPR”) and The Privacy and
              Electronic Communications Regulations (“PECR”) and the EU GDPR.
            </p>
            <p className="privacy-policy-title font24">
              OMW & B4B Payments is also your Data Processor in some cases
            </p>
            <p className="Privacy-policy-paragraph">
              When we or B4B Payments process personal data you provide to us
              that is related to other individuals (such as your employees or
              customers), OMW & B4B Payments may be acting as a Data Processor
              (discussed below).
            </p>
            <p className="privacy-policy-title font24">When we are a ‘Data Processor’</p>
            <p className="Privacy-policy-paragraph">
              When using some of our services and products, OMW and or B4B
              Payments is a ‘Data Processor’ and you are a ‘Data Controller’,
              which means OMW and B4B Payments process personal data solely for
              the purposes of providing you with our OMW Products and Services
              and based on your instructions. In particular, when you engage
              with OMW Products and Services, or those of our partners, that
              involve you providing your employee, customer or other third-party
              personal data, OMW and B4B Payments will likely be acting as a
              Data Processor of that data. When OMW and B4B Payments act as a
              Data Processor we are required to have a written agreement in
              place with you which outlines how we process the personal data we
              process on your behalf and that you provide to us in your capacity
              of a Data Controller. We will let you know if you are using OMW
              Products and Services for which you are a ‘Data Controller’ and
              where you shall review and enter into a Data Processing Agreement
              (‘DPA’) with us. By using those services, you will be deemed to
              have read, reviewed and agreed to our DPA with you..
            </p>
            <p className="privacy-policy-title font24">Personal data we collect</p>
            <p className="Privacy-policy-paragraph">
              We collect personal data for a variety of reasons, including to
              meet our legal obligations, manage our operations, improve our
              business and deliver our services and products to you. Below is a
              list of types of personal data that we may collect and use when
              you apply for, or use, any of our products or services.
            </p>
            <div className="privacy-policy-content-table">
              <Table>
                <thead>
                  <tr>
                    <th>Type of personal data</th>
                    <th>Description</th>
                    <th>Purpose</th>
                    <th>Lawful basis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Contact</td>
                    <td>
                      Your name, addresses, e-mail addresses, phone numbers and
                      other ways in which to contact you
                    </td>
                    <td>
                      <p>
                        Managing our relationship with you or your business.
                        Communicating with you about our and our business
                        partners’ products and services.
                      </p>
                      <p>
                        Delivering the OMW Products and Services to you,
                        including to facilitate your use of the different OMW
                        Services via single sign-on.
                      </p>
                      <p>
                        Developing and carrying out marketing or business
                        development activities.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>Communicate with our customers.</li>
                        <li>Keep our records up to date.</li>
                        <li>
                          Resolve issues and improve the service we provide to
                          you.
                        </li>
                        <li>Optimise your user experience</li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>Transactional</td>
                    <td>
                      Details about the transactions you carry out and the
                      payments to and from your accounts with us
                    </td>
                    <td>
                      <p>
                        Making and managing customer payments. Delivering our
                        and our business partners’ products and services.
                      </p>
                      <p>
                        Managing fees, charges and interest due on customer
                        accounts.
                      </p>
                      <p>Collecting and recovering money that is owed to us.</p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Facilitate delivery of our services and products.
                        </li>
                        <li>
                          Develop products and services, our pricing for them
                          and types of Members that may want to use them.
                        </li>
                        <li>
                          Develop and improve how we deal with financial crime,
                          as well as discharging our legal duties in this
                          respect.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Contractual</td>
                    <td>
                      Details about the products or services we provide to you
                    </td>
                    <td>
                      <p>
                        Carrying out our obligations arising from and exercising
                        our rights set out in our contracts. Collecting and
                        recovering money that is owed to us.
                      </p>
                      <p>
                        Operating our business in an efficient and proper way,
                        including managing our financial position, business
                        capability, planning, and audit.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Understand and improve how we contract with our
                          customers
                        </li>
                        <li>
                          Understand each party’s obligations and risks under
                          any agreements
                        </li>
                        <li>
                          Be efficient about how we fulfill our legal and
                          contractual duties.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Locational</td>
                    <td>
                      Data we get about where you are. This may come from the IP
                      address assigned to your mobile phone or computer when you
                      connect to the internet. It may also include locations
                      where you used your OMW payment card or post codes.
                    </td>
                    <td>
                      <p>
                        Delivering our and our business partners’ products and
                        services. Identifying, investigating, reporting and
                        preventing fraud, security breaches, money laundering
                        and other crime.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Be efficient about how we fulfil our legal and
                          contractual duties.
                        </li>
                        <li>
                          Determine the risks that applicants for a OMW account
                          may pose to OMW’s business and our Members.
                        </li>
                        <li>
                          Develop products and services, our pricing for them
                          and types of Members that may want to use them.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Behavioural</td>
                    <td>
                      Details about how you use products and services offered on
                      the OMW Platform from us and other organisations
                    </td>
                    <td>
                      <p>
                        Studying how our Members use products and services from
                        us and other organisations. Delivering more personalised
                        user experiences to our Members.
                      </p>
                      <p>
                        Developing and carrying out marketing or business
                        development activities.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Work out which of our products and services may
                          interest you and telling you about them.
                        </li>
                        <li>
                          Develop products and services, our pricing for them
                          and types of Members that may want to use them
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Technical</td>
                    <td>
                      Details on the devices and technology you use, for example
                      your website browser settings, marketing choices
                    </td>
                    <td>
                      <p>
                        Delivering our products and services to you. Delivering
                        our and our business partners’ products and services.
                      </p>
                      <p>
                        Identifying, investigating, reporting and preventing
                        fraud, money laundering and other crime.
                      </p>
                      <p>
                        Developing and carrying out marketing or business
                        development activities.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Be efficient about how we fulfil our legal and
                          contractual duties.
                        </li>
                        <li>
                          Develop products and services, our pricing for them
                          and types of Members that may want to use them
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>Communication</td>
                    <td>
                      Any emails, calls or other communications you’ve sent to
                      us or we’ve sent to you What we learn about you from
                      communications between us
                    </td>
                    <td>
                      <p>
                        Investigating and responding to your enquiries,
                        complaints and feedback.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>Communicate with our customers.</li>
                        <li>
                          Be efficient about how we fulfil our legal and
                          contractual duties.
                        </li>
                        <li>
                          Resolve issues and improve the service we provide to
                          you.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Public and third-party records</td>
                    <td>
                      Details about you that are in public records, such as the
                      Electoral Register, Companies House or similar local
                      databases, and data about you that is publicly available
                      on the Internet. We also collect data about you which we
                      receive from other companies, such as credit reference or
                      fraud protection agencies like the CIFAS register.
                    </td>
                    <td>
                      <p>
                        Identifying, investigating, reporting and preventing
                        fraud, money laundering and other crime. Registering you
                        with a OMW account.
                      </p>
                      <p>
                        Developing and carrying out marketing or business
                        development activities.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Determine the risks that applicants for a OMW account
                          may pose to OMW’s business and our members.
                        </li>
                        <li>Keep our records up to date.</li>
                        <li>
                          Work out which of our products and services may
                          interest you and telling you about them.
                        </li>
                        <li>
                          Develop products and services, our pricing for them
                          and types of Members that may want to use them
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Usage data</td>
                    <td>Data about how you use our products and services</td>
                    <td>
                      <p>
                        Studying how our Members use products and services from
                        us and other organisations Testing new products
                      </p>
                      <p>
                        Managing how we work with other companies that provide
                        services to us and our Members
                      </p>
                      <p>
                        Managing how we work with other companies that provide
                        services to us and our Members
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Managing how we work with other companies that provide
                          services to us and our Members
                        </li>
                        <li>
                          Determine the risks that OMWMembers may pose to OMW’s
                          business and our Members.
                        </li>
                        <li>
                          Develop products and services, our pricing for them
                          and types of Members that may want to use them
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Documentary data</td>
                    <td>
                      Details about you that are stored in documents in
                      different formats, or copies of them. This could include
                      things like your passport, driver’s licence, photographs
                      or birth certificate.
                    </td>
                    <td>
                      <p>
                        Identifying, investigating, reporting and preventing
                        fraud, money laundering and other crime.
                      </p>
                      <p>Complying with laws and regulations.</p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>
                          Be efficient about how we fulfil our legal and
                          contractual duties.
                        </li>
                        <li>
                          Develop and improve how we deal with financial crime,
                          as well as doing our legal duties in this respect.
                        </li>
                        <li>
                          Determine the risks that applicants for a OMWaccount
                          may pose to OMW’s business and our Members.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Consents</td>
                    <td>
                      Any permissions, consents or preferences that you give us
                    </td>
                    <td>
                      <p>
                        So we know what your preferences are in regards to
                        marketing, automated decision-making and profiling
                        (where this activity is based on your consent), cookies
                        and any other relevant processing activities that you
                        can opt-out of. Developing and carrying out marketing or
                        business development activities.
                      </p>
                    </td>
                    <td>
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>When you consent to it.</p>
                        <p>When it is in our legitimate interest to:</p>
                      <ul>
                        <li>Keep our records up to date.</li>
                        <li>
                          Ask for your consent when we need it to contact you.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Special category data and criminal offence data</td>
                    <td>
                      Categories of data defined as ‘special category’ or
                      ‘sensitive’ by applicable laws, including personal data
                      related to your racial or ethnic origin, political
                      opinions, religious or philosophical beliefs, trade union
                      members, genetic data, biometric data, health, sex life or
                      sexual orientation. We may also process data about your
                      criminal offence history. In particular, we may collect
                      data related to your health (such as disability data),
                      biometric data (photos of you) and racial or ethnic origin
                      (how you identify your ethnicity).
                    </td>
                    <td>
                      <p>
                        Understanding our community and the type of Members we
                        work with, including about ethnicity, gender and health.
                        Operating our business in a proper way, including
                        corporate responsibility.
                      </p>
                    </td>
                    <td>
                    
                        <p>Fulfilling our contract with you.</p>
                        <p>When it is our legal duty.</p>
                        <p>
                          When you provide explicit consent. It is in the public
                          interest. It is in your vital interest to protect
                          yours or someone else’s life.
                        </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Your customer or employee data</td>
                    <td>
                      Any data that you provide us with about your employees or
                      customers including contact details, personal details
                      (e.g., name, date of birth, bank account information) in
                      our capacity as your data processor.
                    </td>
                    <td>
                      <p>
                        Delivering our and our business partners’ products and
                        services to you
                      </p>
                    </td>
                    <td>
                        <p>
                          When you have instructed us to do so and by virtue of
                          your existing contractual relationship with us.
                        </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <p className="privacy-policy-title font24">
              How do we collect your data? We use different methods to collect
              data from and about you including through:
            </p>
            <p className="Privacy-policy-paragraph">
              Direct interactions. You may give us your Identity, Company,
              Contact, Financial and Transactional Data by filling in forms or
              by corresponding with us by post, phone, email or otherwise. This
              includes personal data you provide when you:
            </p>
            <ul>
              <li>apply for our services;</li>
              <li>create an account on our website;</li>
              <li>subscribe to our service or publications</li>
              <li>use our services;</li>
              <li>request marketing to be sent to you;</li>
              <li>give us feedback or contact us for any reason.</li>
            </ul>

            <p className="Privacy-policy-paragraph">
              Automated technologies or interactions. As you interact with our
              website, we will automatically collect Technical Data about your
              equipment, browsing actions and patterns. We collect this personal
              data by using cookies, server logs and other similar technologies.
              Please see our cookie policy for further details.
            </p>
            <p className="Privacy-policy-paragraph">
              Third parties or publicity available sources. We are also working
              closely with third parties (including, service providers, business
              partners, sub-contractors in technical, payment and delivery
              services, advertising networks, analytics providers, search
              information providers, credit reference agencies) and may receive
              and exchange information about you with them.
            </p>
            <p className="privacy-policy-title font24">What we do with your data?</p>
            <p className="Privacy-policy-paragraph">
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>

            <ul>
              <li>
                Where we need to perform the contract we are about to enter into
                or have entered into with you.
              </li>
              <li>
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests.
              </li>
              <li>
                Where we need to comply with our legal and/or regulatory
                obligations.
              </li>
            </ul>
            <p>
              Generally, we do not rely on consent as a legal basis for
              processing your personal data although we will get your consent
              before sending third party direct marketing communications to you
              via email or text message. You have the right to withdraw consent
              to marketing at any time by contacting us.
            </p>
            <p className="privacy-policy-title font24">How long will we keep your data for?</p>
            <p className="Privacy-policy-paragraph">
              We will only retain your personal data for as long as reasonably
              necessary to fulfil the purposes we collected it for, including
              for the purposes of satisfying any legal, regulatory, tax,
              accounting or reporting requirements. We may retain your personal
              data for a longer period in the event of a complaint or if we
              reasonably believe there is a prospect of litigation in respect to
              our relationship with you.
            </p>
            <p className="Privacy-policy-paragraph">
              To determine the appropriate retention period for personal data,
              we consider the amount, nature and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal,
              regulatory, tax, accounting or other requirements.
            </p>
            <p className="Privacy-policy-paragraph">
              By law we have to keep basic information about our customers
              (including Contact, Identity, Financial and Transaction Data) for
              a period of 5 years after you cease being customers for tax and
              regulatory purposes.
            </p>
            <p className="Privacy-policy-paragraph">
              In some circumstances you can ask us to delete your data: see your
              legal rights below for further information.
            </p>
            <p className="Privacy-policy-paragraph">
              In some circumstances we will anonymise your personal data (so
              that it can no longer be associated with you) for research or
              statistical purposes, in which case we may use this information
              indefinitely without further notice to you. Internal transfers
            </p>
            <p className="Privacy-policy-paragraph">
              We take the security of your data very seriously, and all of your
              personal data will be kept according to strict safeguards and in
              compliance with the UK-GDPR and EU-GDPR. We share your personal
              data within OMW Europe Ltd.. Your data will be stored on cloud
              servers within the EEA and we will only store your data outside
              the EEA in the event that the jurisdiction in question has been
              assessed as compliant with the GDPR.
            </p>
            <p className="Privacy-policy-paragraph">Third Parties</p>

            <p className="Privacy-policy-paragraph">External Third Parties</p>
            <ul>
              <li className="Privacy-policy-paragraph">
                Service providers acting as processors based in the United
                Kingdom or the European Union provide IT, system administration,
                identification and two-factor authentication services.
              </li>
              <li className="Privacy-policy-paragraph">
                Professional advisers acting as processors or joint controllers
                including lawyers, bankers, auditors and insurers based in the
                United Kingdom and the European Union who provide consultancy,
                banking, legal, insurance and accounting services.
              </li>
              <li className="Privacy-policy-paragraph">
                HM Revenue & Customs, regulators and other authorities acting as
                processors or joint controllers based in the United Kingdom, the
                European Union who require reporting of processing activities in
                certain circumstances.
              </li>
              <li className="Privacy-policy-paragraph">
                Third-party credit reference agencies, in circumstances where we
                may need to assess your creditworthiness.
              </li>
              <li className="Privacy-policy-paragraph">
                Our partners such as Payment Card Solutions (UK) Limited co reg.
                05941947, UAB B4B Payments Europe registered in Lithuania with
                company code 305539054 provide you with the necessary services
                and Mambu GmBh co reg F1103R_HRB149780
              </li>
            </ul>

            <p className="privacy-policy-title font24">Cookies and other tracking technologies</p>

            <p className="Privacy-policy-paragraph">
              We may use cookies to distinguish you from other users of our
              products or services when you visit Our Website. This helps us
              provide you with a good experience, allows us to improve our
              products or services, keep Our Website safe and present you with
              advertising content that is relevant to you. A cookie is a small
              file of letters and numbers that we store on your browser or the
              hard drive of your device when you visit Our Website. Cookies send
              data back to the originating website on each subsequent visit, or
              to another website which recognises that cookie. Cookies also make
              it easier for you to log in and use the OMW Platform.
            </p>
            <p className="Privacy-policy-paragraph">We may use the following cookies:</p>

            <ul>
              <li className="Privacy-policy-paragraph">
                Strictly necessary cookies required for the operation of our
                products or services (including, for example, cookies that
                enable you to log into secure accounts and use interactive
                features);
              </li>
              <li className="Privacy-policy-paragraph">
                Analytical/performance cookies that allow us to recognise and
                count the number of visitors and users and see how they use our
                products or services (e.g., to help us improve the way our
                products or services work or are provided, by ensuring that
                users easily find what they are looking for);
              </li>
              <li className="Privacy-policy-paragraph">
                Functionality cookies to help us recognise you when you return
                to Our Website (this enables us to e.g., personalise our content
                for you, greet you by name and remember your preferences, such
                as choice of language or region)
              </li>
              <li className="Privacy-policy-paragraph">
                Targeting cookies to record your visit to Our Website, the pages
                you have visited and the links you have followed. We may use
                this data to make our products and services and the data
                displayed on it, which we reasonably think is more relevant to
                your interests. We may also share this data with third parties
                for this purpose. In some instances, with respect to targeting
                cookies we act as joint controllers with third parties, such as
                social media platform.
              </li>
            </ul>
            <p className="Privacy-policy-paragraph">
              For more detailed information on what specific cookies we use,
              please read our cookie policy..
            </p>

            <p className="Privacy-policy-paragraph">
              You can block or disable cookies by activating the setting on your
              website browser that allows you to refuse the setting of all or
              some cookies or through the banner on Our Website. All browsers
              provide tools that allow you to control how you handle cookies:
              accept, reject or delete them. These settings are normally
              accessed via the ‘settings’, ‘preferences’ or ‘options’ menu of
              the browser you are using, but you could also look for a ‘help’
              function or contact the browser provider. However, if you set your
              browser settings to block or disable all cookies (including
              essential cookies) you may not be able to access all or parts of
              the OMW Platform for which we require the use of cookies.
            </p>
            <p className="privacy-policy-title font24">How to withdraw your consent or opt-out of processing</p>
            <p className="Privacy-policy-paragraph">
              You can withdraw your consent to our processing of your data at
              any time. Please contact us if you want to do so at
              contact@onemoneyway.com
            </p>
            <p className="Privacy-policy-paragraph">
              This will only affect the way we use data when our basis for
              processing your data is your consent. See the section ‘Your
              Rights’ and more specifically your right to restricting use of
              your data.
            </p>
            <p className="Privacy-policy-paragraph">
              You may also opt out of some forms of data processing we are
              conducting, such as:
            </p>
            <ul>
              <li className="Privacy-policy-paragraph">Marketing, including email, phone and SMS marketing.</li>
              <li className="Privacy-policy-paragraph">
                Social media and targeted marketing, including retargeting and
                curated audiences.
              </li>
              <li className="Privacy-policy-paragraph">
                Non-essential cookie collection on Our Website. You may be
                unable to opt out of ‘necessary’ cookies as discussed above.
              </li>
              <li className="Privacy-policy-paragraph">
                Non-essential profiling and automated decision-making, including
                those activities undertaken for marketing purposes.
              </li>
            </ul>
            <p className="Privacy-policy-paragraph">
              If you withdraw your consent and/or opt-out, we may not be able to
              provide certain products or services to you. If this is so, we
              will tell you. You then have the option to give us your consent
              again if you want to access our products or services.
            </p>
            <p className="privacy-policy-title font24">Keeping your data accurate</p>
            <p className="Privacy-policy-paragraph">
              We will use reasonable efforts to ensure that your personal data
              is accurate, complete and up-to-date. Please ensure you notify us
              without undue delay of any changes to the personal data that you
              have provided to us by updating your details on the
              OneMoneyWayPlatform or by contacting us at the details provided in
              this Privacy Policy.
            </p>
            <p className="privacy-policy-title font24">Your legal rights</p>
            <p className="Privacy-policy-paragraph">
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data. Please click on the links
              below to find out more about these rights:
            </p>
            <ul>
              <li className="Privacy-policy-paragraph">To be informed.</li>
              <li className="Privacy-policy-paragraph">Request access to your personal data.</li>
              <li className="Privacy-policy-paragraph">Request correction of your personal data.</li>
              <li className="Privacy-policy-paragraph">Request erasure of your personal data.</li>
              <li className="Privacy-policy-paragraph">Object to processing of your personal data.</li>
              <li className="Privacy-policy-paragraph">Request restriction of processing your personal data.</li>
              <li className="Privacy-policy-paragraph">Request transfer of your personal data.</li>
              <li className="Privacy-policy-paragraph">Right to withdraw consent.</li>
            </ul>
            <p className="privacy-policy-title font24">Your legal rights:</p>
            <p className="Privacy-policy-paragraph">You have the right to:</p>
            <p className="Privacy-policy-paragraph">
              Be informed about what personal data we hold and how we are using
              it. This information is contained within this privacy notice.
            </p>
            <p className="Privacy-policy-paragraph">
              Request access to your personal data (commonly known as a "data
              subject access request"). This enables you to receive a copy of
              the personal data we hold about you and to check that we are
              lawfully processing it. You can do so, by making a ‘subject access
              request’ to us in writing.
            </p>
            <p className="Privacy-policy-paragraph">
              Request correction of the personal data that we hold about you.
              This enables you to have any incomplete or inaccurate data we hold
              about you corrected, though we may need to verify the accuracy of
              the new data you provide to us.
            </p>
            <p className="Privacy-policy-paragraph">
              Request erasure of your personal data. This enables you to ask us
              to delete or remove personal data where there is no good reason
              for us continuing to process it. You also have the right to ask us
              to delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we
              may have processed your information unlawfully or where we are
              required to erase your personal data to comply with local law.
              Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons which will be
              notified to you, if applicable, at the time of your request.
            </p>
            <p className="Privacy-policy-paragraph">
              Object to processing of your personal data where we are relying on
              a legitimate interest (or those of a third party) and there is
              something about your particular situation which makes you want to
              object to processing on this ground as you feel it impacts on your
              fundamental rights and freedoms. You may always object to further
              use of your data for direct marketing purposes by clicking the
              “unsubscribe” button within marketing emails or by contacting us
              by writing to us at contact@onemoneyway.com. In some cases, we may
              demonstrate that we have compelling legitimate grounds to process
              your information which override your rights and freedoms.
            </p>
            <p className="Privacy-policy-paragraph">
              Request restriction of processing of your personal data. This
              enables you to ask us to suspend the processing of your personal
              data in the following scenarios:
            </p>
            <ul>
              <li className="Privacy-policy-paragraph">If you want us to establish the data's accuracy</li>
              <li className="Privacy-policy-paragraph">
                Where our use of the data is unlawful but you do not want to
                erase it.
              </li>
              <li className="Privacy-policy-paragraph">
                Where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims.
              </li>
              <li className="Privacy-policy-paragraph">
                You have objected to our use of your data but we need to verify
                whether we have overriding legitimate grounds to use it.
              </li>
            </ul>
            <p className="Privacy-policy-paragraph">
              Request the transfer of your personal data to you or to a third
              party. We will provide to you, or a third party you have chosen,
              your personal data in a structured, commonly used,
              machine-readable format free of charge and on request. Note that
              this right only applies to automated information which you
              initially provided consent for us to use or where we used the
              information to perform a contract with you.
            </p>
            <p className="Privacy-policy-paragraph">
              Withdraw consent at any time where we are relying on consent to
              process your personal data. However, this will not affect the
              lawfulness of any processing carried out before you withdraw your
              consent. If you withdraw your consent, we may not be able to
              provide certain products or services to you. We will advise you if
              this is the case at the time you withdraw your consent.
            </p>
            <p className="Privacy-policy-paragraph">
              If you wish to exercise any of the rights set out above, please
              contact us by emailing contact@onemoneyway.com
            </p>
            <p className="Privacy-policy-paragraph">
              Should you be dissatisfied with our response or wish to complain
              to the relevant supervisory authority, you can do so by contacting
              the Information Commissioner’s Office (ICO). Contact details for
              the ICO can be found on the ICO’s website.
            </p>
            <p className="privacy-policy-title font24">No fee usually required</p>
            <p className="Privacy-policy-paragraph">
              You will not have to pay a fee to access your personal data (or to
              exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or
              excessive. Alternatively, we could refuse to comply with your
              request in these circumstances. What we may need from you We may
              need to request specific information from you to help us confirm
              your identity and ensure your right to access your personal data
              (or to exercise any of your other rights). This is a security
              measure to ensure that personal data is not disclosed to any
              person who has no right to receive it. We may also contact you to
              ask you for further information in relation to your request to
              speed up our response. Time limit to respond We try to respond to
              all legitimate requests within one month. Occasionally it could
              take us longer than a month if your request is particularly
              complex or you have made a number of requests. In this case, we
              will notify you and keep you updated.
            </p>
            <p className="Privacy-policy-paragraph">Glossary</p>
            <p className="Privacy-policy-paragraph">Lawful Basis</p>
            <p className="Privacy-policy-paragraph">
              Under Article 6 of the EU General Data Protection Regulation
              (GDPR) we are required to tell you about the legal basis under
              which we collect and process your data.
            </p>
            <p className="Privacy-policy-paragraph">
              We will only collect and process your personal data in accordance
              with one of the below lawful basis:
            </p>
            <p className="Privacy-policy-paragraph">
              Legitimate Interest means the interest of our business in
              conducting and managing our business to enable us to give you the
              best service/product and the best and most secure experience. We
              make sure we consider and balance any potential impact on you
              (both positive and negative) and your rights before we process
              your personal data for our legitimate interests. We do not use
              your personal data for activities where our interests are
              overridden by the impact on you (unless we have your consent or
              are otherwise required or permitted to by law). You can obtain
              further information about how we assess our legitimate interests
              against any potential impact on you in respect of specific
              activities by contacting us. Our legitimate interests include:
            </p>
            <ul>
              <li className="Privacy-policy-paragraph">Improving our service</li>
              <li className="Privacy-policy-paragraph">
                {" "}
                Providing you with marketing information about our services
              </li>
              <li className="Privacy-policy-paragraph">
                Providing you with customer support and administering your
                account
              </li>
              <li className="Privacy-policy-paragraph">
                Providing you with information about related products and
                services offered by OneMoneyWay
              </li>
              <li className="Privacy-policy-paragraph">
                Providing you with information about related products and
                services offered by our commercial partners
              </li>
            </ul>
            <p className="Privacy-policy-paragraph">
              Performance of Contract means processing your data where it is
              necessary for the performance of a contract we have with you or to
              take steps at your request before entering into a contract, such
              as providing you with a quote.
            </p>
            <p className="Privacy-policy-paragraph">
              Comply with a legal obligation means processing your personal data
              where it is necessary for compliance with a legal obligation that
              we are subject to, such as in response to a request by law
              enforcement or when investigating a civil claim.
            </p>
            <p className="Privacy-policy-paragraph">
              Consent means processing your personal data where you have been
              given the option to explicitly consent to share your data with
              selected third parties for marketing purposes or to sign up for
              related products and services, and you have chosen to consent.
              This will be via a separate notice via onemoneyway.com. We will
              never assume that we have your consent unless you have explicitly
              opted in, and you can withdraw your consent at any time by
              contacting us.
            </p>
            <p className="privacy-policy-title font24">Updates to this Privacy Policy</p>
            <p className="Privacy-policy-paragraph">
              We may update this Privacy Policy from time to time to ensure that
              it remains accurate. Please check back from to time to time for
              updates.
            </p>
            <br></br>
            <p className="Privacy-policy-paragraph">This Privacy Policy was last updated on 12 december 2022</p>
          </div>
        </div>
      </div>
      {/* <RightSideBar expense_payments summaryBox /> */}
    </div>
  );
};

export default PrivacyPolicy;
