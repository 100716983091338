import React from "react";

import { SearchFilter, DownloadFilter, FilterLines, PrintFilter } from "../../../constants/Images";
import { CiViewColumn } from "react-icons/ci";



const renderIcon = (icon: string) => <img src={icon} alt="" />

export const customToolBarIcons: any = {
        SearchIcon: () => renderIcon(SearchFilter),
        PrintIcon: () => renderIcon(PrintFilter),
        DownloadIcon: () => renderIcon(DownloadFilter),
        ViewColumnIcon: () => <CiViewColumn style={{ color: 'black', fontSize: "larger" }} />,
        FilterIcon: () => renderIcon(FilterLines),
      }