import React from "react";
import "./index.css";
import { Card } from "react-bootstrap";
import { ModalConvertIcon,ModalTransferIcon } from "../../../../constants/Images";

const ModalBannerSection = (props: { statusText: string, title:string,icon:string }) => {
  return (
    <Card className="card-send-msg">
      <div className="verify-titlee">
        <div className="verify-titlee-icon">
          <img src={props?.icon} width="75" />
        </div>
        <div>
          <h3 style={{ color: "#FFFFFF" }}>{props?.title}</h3>
        </div>
      </div>
      <div className="receipt-status">{props?.statusText}</div>
    </Card>
  );
};

export default ModalBannerSection;
