import React, { useEffect, useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import BasicButton from "../../../../components/shared/Buttons/Basic";

type LimitsType = {
  "GBP": {
    maxBalance: string,
    maxLoads: string,
    maxDayATM: string,
    maxSingleATM: string,
    maxAnnualATM: string,
  },
  "EUR": {
    maxBalance: string,
    maxLoads: string,
    maxDayATM: string,
    maxSingleATM: string,
    maxAnnualATM: string,
  },
  "DKK": {
    maxBalance: string,
    maxLoads: string,
    maxDayATM: string,
    maxSingleATM: string,
    maxAnnualATM: string,
  }
};

const maxLimits: LimitsType = {
  "GBP": {
    maxBalance: "£5,000",
    maxLoads: "£75,000",
    maxDayATM: "£1,000",
    maxSingleATM: "£300",
    maxAnnualATM: "£75,000"
  },
  "EUR": {
    maxBalance: "€7,500",
    maxLoads: "€100,000",
    maxDayATM: "€1,000",
    maxSingleATM: "€400",
    maxAnnualATM: "€75,000"
  },
  "DKK": {
    maxBalance: "kr. 55.880",
    maxLoads: "kr. 745.115",
    maxDayATM: "kr. 7.450",
    maxSingleATM: "kr. 2.980",
    maxAnnualATM: "kr. 558.835"
  }
};

const ConfigureLimitsModal = (props: {
  showLimitsConfig: boolean,
  cardCurrency: string,
  handleClose: any,
}) => {
  const { showLimitsConfig, cardCurrency, handleClose } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const cardCurrencyAsKeyOfLimitsType = cardCurrency as keyof LimitsType;

  return (
    <Modal show={showLimitsConfig} onHide={handleClose} centered className="card-settings-modal-height">
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0, paddingBottom: 0 }} >
        <div>
          <Modal.Title>{t("configureLimitModal.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <p>
          {t("configureLimitModal.maximumBalanceAnyTime")} {maxLimits[cardCurrencyAsKeyOfLimitsType].maxBalance}
        </p>
        <p>
          {t("configureLimitModal.maximumLoadPerYear")} {maxLimits[cardCurrencyAsKeyOfLimitsType].maxLoads}
        </p>
        <p>
          {t("configureLimitModal.maximumATMWithdrawal")} {maxLimits[cardCurrencyAsKeyOfLimitsType].maxDayATM} {t("configureLimitModal.perDayTransactions")} ({t("configureLimitModal.max")} {maxLimits[cardCurrencyAsKeyOfLimitsType].maxSingleATM} {t("configureLimitModal.perTransaction")})
        </p>
        <p>
          {t("configureLimitModal.maximumAnnualATM")} {maxLimits[cardCurrencyAsKeyOfLimitsType].maxAnnualATM}
        </p>
        <p style={{ margin: 0, fontSize: "12px", color: "gray" }}>
          {t("configureLimitModal.increaseSpendingLimits")}
        </p>
      </Modal.Body>
      <ModalFooter style={{ borderTop: "0px", padding: "15px 30px" }}>
        <BasicButton
          type="button"
          onClick={handleClose}
          text= {t("configureLimitModal.okay")}
        />
      </ModalFooter>
    </Modal>
  )
};

export default ConfigureLimitsModal;

{/* {cardDetailsSelector.status === "loading"
          ? <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <Spinner animation={"border"} />
          </div>
          : <></>

        } */}

// <Form>
//   {/* DAILY LIMITS START HERE */}
//   <h5 style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px" }}>Daily</h5>
//   {/* ATM */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px" }}>ATM</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("24H", "ATM")}
//         onChange={(e) => handleInputChange("24H", "ATM", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('ATM', '24H', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* RETAIL */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px" }}>Retail</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("24H", "RETAIL")}
//         onChange={(e) => handleInputChange("24H", "RETAIL", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('RETAIL', '24H', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* ALL */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px", }}>All</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("24H", "ALL")} // Provide the corresponding state variable
//         onChange={(e) => handleInputChange("24H", "ALL", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('ALL', '24H', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* DAILY LIMITS END HERE */}

//   {/* WEEKLY LIMITS START HERE */}
//   <h5 style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px" }}>Weekly</h5>
//   {/* ATM */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px" }}>ATM</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("WEEKLY", "ATM")} // Provide the corresponding state variable
//         onChange={(e) => handleInputChange("WEEKLY", "ATM", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('ATM', 'WEEKLY', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* RETAIL */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px" }}>Retail</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("WEEKLY", "RETAIL")} // Provide the corresponding state variable
//         onChange={(e) => handleInputChange("WEEKLY", "RETAIL", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('RETAIL', 'WEEKLY', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* ALL */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px", }}>All</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("WEEKLY", "ALL")} // Provide the corresponding state variable
//         onChange={(e) => handleInputChange("WEEKLY", "ALL", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('ALL', 'WEEKLY', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* WEEKLY LIMITS END HERE */}

//   {/* MONTHLY LIMITS START HERE */}
//   <h5 style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px" }}>Monthly</h5>
//   {/* ATM */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px" }}>ATM</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("MONTHLY", "ATM")}
//         onChange={(e) => handleInputChange("MONTHLY", "ATM", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('ATM', 'MONTHLY', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* RETAIL */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px" }}>Retail</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("MONTHLY", "RETAIL")}
//         onChange={(e) => handleInputChange("MONTHLY", "RETAIL", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('RETAIL', 'MONTHLY', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* ALL */}
//   {/* <div style={{
//     display: 'flex',
//     alignItems: 'center'
//   }}>
//     <Col className="col-2">
//       <p style={{ margin: 0, paddingLeft: "0px", }}>All</p>
//     </Col>
//     <Col className="col-10" >
//       <InputComponent
//         type="number"
//         compulsoryValue
//         className="configure-limits-modal-input"
//         value={getValueByCategory("MONTHLY", "ALL")}
//         onChange={(e) => handleInputChange("MONTHLY", "ALL", Number(e.target.value))}
//       />
//       <small style={{ color: 'gray', fontSize: "smaller" }}>Max. allowed limit: {userCurrency} {getLimitValue('ALL', 'MONTHLY', userCurrency)}</small>
//     </Col>
//   </div> */}
//   {/* MONTHLY LIMITS END HERE */}

// </Form>