import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider } from '@mui/material';
import downArrow from "../../../assets/images/down-arrow.png";
import { AppDispatch, RootState } from "../../../redux/store";
import { Logout, GetLastLogin } from "../../../redux/toolkit/auth/operation";
import "./index.css";
import { socketActions } from "../../../redux/toolkit/auth/reducer";
import { apiHelper } from "../../../api helper";

type ProfileProps = {
  firstName: string;
  lastName: string;
  userID?: number;
  image: string;
};

const ProfileCard = (props: ProfileProps) => {
  const { t } = useTranslation();
  const [lastLogin, setLastLogin] = useState("");
  const [lastSync, setLastSync] = useState("");
  const token: any = localStorage.getItem("token");
  const roles: any = JSON.parse(localStorage.getItem('roles') || "[]");
  const navigate = useNavigate();
  // const userLogout: any = useSelector((state: RootState) => state.qrDetails);

  const getAllAdmin = async () => {
    try {
      const res = await apiHelper(
        `user/get-admins`,
        "get",
        {},
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (res?.data?.data?.users)
        setAdmins(res?.data?.data?.users)

    } catch (error) {
      console.log('Error Occured ', error)
    }
  }

  const loginAdminSession = async (email: string) => {
    try {
      const res = await apiHelper(
        `user/login-session-user`,
        "post",
        {
          email
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (res)
        window.location.reload()


    } catch (error) {
      console.log('Error Occured ', error)
    }
  }

  useEffect(() => {
    getAllAdmin()
  }, [])

  const userLogout: any = useSelector((state: RootState) => {
    if (state.qrDetails && Object.keys(state.qrDetails.data).length !== 0) {
      return state.qrDetails;
    } else if (state.otpVerifyDetails && Object.keys(state.otpVerifyDetails.data).length !== 0) {
      return state.otpVerifyDetails
    }
  });
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([])
  const dispatch = useDispatch<AppDispatch>();
  const handleButtonClick = () => {
    setLoading(true);
    dispatch(Logout(token)).then(() => setLoading(false));
  };

  const getLastLogin = () => {
    setLoading(true);
    dispatch(GetLastLogin(token)).then((el: any) => {
      if (el?.payload?.data?.lastSync)
        setLastSync(new Date(el?.payload?.data?.lastSync).toLocaleString())
      if (el?.payload?.data?.lastLogin)
        setLastLogin(new Date(el?.payload?.data?.lastLogin).toLocaleString())
      else
        setLastLogin(new Date(el?.payload?.data?.lastSync).toLocaleString())
      setLoading(false)
    });
  }

  useEffect(() => {
    getLastLogin();
  }, [])

  useEffect(() => {
    if (userLogout?.data === undefined || userLogout?.data === "") {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.clear();
      dispatch(socketActions.disconnect());
      navigate("/");
    }
  }, [userLogout]);

  const imagePath = props.image;
  return (
    <>
      <div className="dropdown">
        <button className="dropbtn">
          <div className="d-flex align-items-center">
            <img className="profile-ico" src={imagePath} alt="user-icon"></img>
            <div className="user-info">
              <p className="user-fname">{props.firstName}</p>
              <p className="user-lname">{props.lastName}</p>
            </div>
          </div>
          <img className="down-arrow" src={downArrow} alt="down-arrow"></img>
        </button>
        <div className="dropdown-content">
          <ul className="list-style-none p-0 m-0">
            <li>
              <div className="user-info" style={{
                padding: "10px"
              }}>
                <p>Synced: {lastSync}</p>
                <p>Last Login: {lastLogin}</p>
              </div>
            </li>
            <li>
              <button
                style={{ border: "none", padding: "10px", background: "none" }}
                onClick={() => navigate("/editregistration")}
              >
                {t("editProfile")}
              </button>
            </li>
            <li>
              <button
                style={{ border: "none", padding: "10px", background: "none" }}
                onClick={handleButtonClick}
              >
                {loading ? <Spinner animation={"border"} size="sm" /> : `${t("footer.logout")}`}
              </button>
            </li>
          </ul>
        </div>
      </div>

      {
        roles.includes('ACCOUNTANT') ? (
          <div className="dropdown">
            <button className="dropbtn">
              <div className="d-flex align-items-center" style={{ height: '7vh' }}>
                <div className="user-info">
                  <p className="user-fname">Login</p>
                </div>
              </div>
              <img className="down-arrow" src={downArrow} alt="down-arrow"></img>
            </button>
            <div className="dropdown-content">
              <ul className="list-style-none p-0 m-0">
                {/* Here Add Dropdown of admins */}
                <li>
                  <button
                    style={{ border: "none", padding: "10px", background: "none" }}>
                    {props?.firstName} {props?.lastName}
                  </button>
                </li>
                <Divider></Divider>
                {
                  admins.map((el: any) => {
                    return <>
                      <li>
                        <button
                          style={{ border: "none", padding: "10px", background: "none" }}
                          onClick={() => {
                            loginAdminSession(el?.email)
                          }
                          }
                        >
                          {el?.email}
                        </button>
                      </li>
                      <Divider></Divider>
                    </>
                  })
                }
              </ul>
            </div>
          </div>
        ) : null
      }
    </>

  );
};
export default ProfileCard;
