import React, { useEffect, useState } from "react";
import LeftSideBar from "../../components/shared/LeftSidebar";
import InputComponent from "../../components/shared/Input/Index";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "../../redux/store";
import { AddBeneficiary } from "../../redux/toolkit/beneficiaries/operation";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";
import { Form, Spinner, } from "react-bootstrap";
import * as iso from "iso-3166-1";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import RightSideBar from "../../components/shared/RightSidebar";
import Footer from "../../components/shared/Footer";
import "./index.css";
import { getCodes, getCountry } from "iso-3166-1-alpha-2";
import axios from "axios";
import BASE_URL from "../../utils/baseURL";
import Swal from "sweetalert2";
import COLORS from "../../utils/colors";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../components/shared/Buttons/Basic";
import AccountTypes from "./AccountTypeRadioButton";
// For material ui dropdown
import { DropDownArrowIconSolid, DropDownArrowIconWhite, DropDownInputSolid, DropDownInputWhite } from "../../components/shared/DropdownSelect";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ErrorImage } from "../../constants/Images";
import { showAlert } from "../../redux/toolkit/swal/reducer";



export const AddbeneficiaryForm = ({ isRecipientModal, handleCloseAddRecipient, getBeneficiaries, handleRecipientSelect }: any) => {
    const dispatch = useDispatch<AppDispatch>();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [account, setAccount] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");
    const [iban, setIBAN] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emptyEmailErrMsg, setEmailEmptyErrMsg] = useState("");
    const [accountError, setAccountError] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [bankCodeError, setBankcodeError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [cityError, setCityError] = useState("");
    const [postCodeError, setPostCodeError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [isPerson, setIsPerson] = useState(false);
    const [isPersonChanged, setIsPersonChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingIban, setLoadingIban] = useState(false);
    const [financialInst, setFinancialInst] = useState("local");
    const [finInsValue, setFinInsValue] = useState("");
    const [countryCode, setCountryCode] = useState("DK");
    const [countryCodeError, setCountryCodeError] = useState("");
    const userId: any = localStorage.getItem("userId");
    const accData: any = localStorage.getItem("AccountData");
    const ParsedData: any = JSON.parse(accData);
    const accId: any = localStorage.getItem("activeAccId");
    const accountId: any = localStorage.getItem("accountId");
    const [accUUID, setAccUUID] = useState(accountId);
    const [accountDetails, setAccountDetails] = useState<any>([]);
    const accountIdSelecto: any = useSelector(
        (state: RootState) => state.getAllAccounts.data
    );
    const countryCodes: string[] = getCodes();
    const countries = iso.all();
    const states = [
        'AL', 'AK', 'AS', 'AZ', 'AR', 'AA', 'AP', 'AE', 'CA', 'CO', 'CT', 'DE', 'DC',
        'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA',  'KS', 'KY', 'LA', 'ME', 
        'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MP', 'MT', 'NE', 'NV', 'NH', 'NJ', 
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 
        'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' 
    ]
    const { t } = useTranslation();
    let accountLocal = "";

    useEffect(() => {
        if (isPersonChanged) {
            name === "" && nameError !== ""
                ? setNameError(isPerson ? t("errorMsgs.emptyFullName") as string : t("errorMsgs.emptyCompanyName") as string)
                : setNameError('')
        }
    }, [isPerson]);

    useEffect(() => {
        dispatch(getAllAccounts(userId));
    }, [dispatch]);

    useEffect(() => {
        if (accountIdSelecto) {
            setAccountDetails(accountIdSelecto?.data?.data);
        }
    }, [accountIdSelecto, accountDetails]);

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setNameError("");
        setName(item);
    };

    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        const regex = regexStrConst.emailRegex
        if (!regex.test(item)) {
            setEmailError(errorMsgs.incorrectEmail)
            setEmailEmptyErrMsg('')
        }
        else {
            setEmailError("")
        }
        setEmail(item.toLowerCase().trim());
    };
    const onChangeFinInst = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setFinInsValue(item);
    };
    const onChangeAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setAccountError("");
        setAccount(item);
    };

    const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setAddressError("");
        setAddress(item);
    };

    const onChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setCityError("");
        setCity(item);
    };

    const onChangePostCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setPostCodeError("");
        setPostCode(item);
    };
    const validateIBANForLocalTransfer = () => {
        // if (accountNumber === "") setAccountNumberError(errorMsgs.emptyAccount);
        // if (bankCode === "") setBankcodeError("Please enter BankCode");
        if (countryCode === "") setCountryCodeError("Please Select Country");
        // return accountNumber === "" ||
        //   bankCode === "" ||
        //   countryCode === ""
        //   ? false : true
        return countryCode === ""
            ? false : true
    }
    const validate = () => {
        if (name === "") setNameError(isPerson ? t("errorMsgs.emptyFullName") as string : t("errorMsgs.emptyCompanyName") as string);
        if (email === "") setEmailEmptyErrMsg(errorMsgs.emptyEmail);
        if (account === "") setAccountError(errorMsgs.emptyAccount);
        if (address === "") setAddressError(errorMsgs.emptyAddress);
        if (city === "") setCityError(errorMsgs.emptyCity);
        // if (postCode === "") setPostCodeError(errorMsgs.emptyPostCode);
        if (country === "") setCountryError(errorMsgs.emptyCountry);
        if (financialInst === "local" && countryCode === "") setCountryCodeError("Please Select Country Code")

        console.log("EMAIL VALIDATION",)

        return name === "" ||
            email === "" ||
            account === "" ||
            address === "" ||
            city === "" ||
            // postCode === "" ||
            country === "" ||
            financialInst === "local" && countryCode === "" ||
            nameError ||
            accountError ||
            addressError ||
            cityError ||
            postCodeError ||
            countryError ||
            emailError ||
            emptyEmailErrMsg ||
            financialInst === "local" && countryCodeError
            // !regexStrConst.emailRegex.test(email)
            ? false
            : true;
    };
    const onChangeAccountNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setAccountNumberError("");
        setAccountNumber(item);
    };
    const onChangeBankCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.value;
        setBankcodeError("");
        setBankCode(item);
    };
    const handleIBANgenerate = async () => {
        console.log("Iban info")
        try {
            const info: any = await axios.get(`${BASE_URL}beneficiary/calculateiban?country=${countryCode}&bankcode=${finInsValue}&account=${account}`).then(res => {
                // setIBAN(res.data.iban);
                // alert(res.data.iban);
                // await setAccount(res.data.iban);
                console.log("IBAN Received", res.data.iban);
                if (res.data.error === undefined)
                    accountLocal = res.data.iban;
                else
                    accountLocal = "Invalid";
                // setAccount(res.data.iban);
                // setFinInsValue("");
                return accountLocal;
                // setLoadingIban(false);
            });
        }
        catch (e) {
            return "Not Found";
        }

        // console.log("Data Found", info.data)
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let accountibn: any;
        if (validate()) {
            setLoading(true);
            if (financialInst === "local") {
                accountibn = await handleIBANgenerate();
                accountibn = accountLocal;
            }
            console.log("validated");
            const payloadData = {
                name: name,
                email: email,
                financial_institution:
                    financialInst === "uk-sort-code"
                        ? `SC${finInsValue}`
                        : financialInst === "us-ach"
                            ? `FW${finInsValue}`
                            : financialInst === "local"
                                ? ``
                                : financialInst === "eu-iban"
                                    ? ``
                                    : finInsValue,
                account: financialInst === "local" ? accountibn : account,
                person: isPerson,
                uuid: accUUID,
                address: {
                    line_1: address,
                    city: city,
                    // postcode: postCode,
                    ...(postCode !== '' && {postcode: postCode}),
                    country: country,
                    ...(state !== '' && {county: state})
                },
                accID: accId,
                isCompany: false,
            };
            console.log("PayLOAD:::", payloadData);
            if (accountibn === "Invalid") {
                // Swal.fire({
                //     imageUrl: ErrorImage,
                //     // icon: "error",
                //     title: "Oopss",
                //     text: "Invalid Bank Code/Account number ",
                // });
                dispatch(showAlert({
                    type: 'error',
                    title: "Oopss",
                    text: `${t("sweetAlerts.invalidBankcode")}`,
                    imageUrl: ErrorImage,
                    imageAlt: 'Error Image'
                  }));
                setLoading(false);
                return;
            }

            dispatch(AddBeneficiary(payloadData)).then((val: any) => {
                setLoading(false)
                setCountryCode("DK")
                if (val.type === "benefiaciary/addBeneficiary/fulfilled") {
                    e.target.reset();
                }
                if (isRecipientModal && val.type === "benefiaciary/addBeneficiary/fulfilled") {
                    getBeneficiaries();
                    handleRecipientSelect(val.payload?.data?.data?.guid)
                    handleCloseAddRecipient();
                }
            });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFinancialInst(e.target.value);
    };
    const handleChangeCountryCode = (event: any) => {
        setCountryCodeError("");
        const selectedCode = event.target.value;
        setCountryCode(selectedCode);
        
    };
    const handleIsPerson = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsPersonChanged(true);
        const person = e.target.value;
        person === "person" ? setIsPerson(true) : setIsPerson(false);
    };

    const onChangeCountry = (val: any) => {
        setCountry(val)
        setCountryError("")

    }
    const onChangeState = (val: any) => {
        setState(val)
    }

    return (
        <form  onSubmit={handleSubmit}>
            <p className="formInputLabel">{t("addRecipient.form.account")}</p>
            {/* <DropDownSelect dropdownList={accountDetails} onChange={
                (e: any) => {
                    localStorage.setItem("AccountData", e.target.value);
                    const accountData = JSON.parse(e.target.value);
                    setAccUUID(accountData.accB4BUUID);
                }
                }/> */}

                {/* Material Ui DropDown */}
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    //   value={age}
                    onChange={(e: any) => {
                    //   localStorage.setItem("AccountData", e.target.value);
                      const accountData = JSON.parse(e.target.value);
                      setAccUUID(accountData.accB4BUUID);
                  }}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value={JSON.stringify(ParsedData)}>
                    {t("addRecipient.form.selectedAcc")}: {ParsedData?.accountName}
                </option>
                      {accountDetails &&
                              accountDetails.map((ele: any, index: number) => (
                                  !ele.inactive ? <option key={index} value={JSON.stringify(ele)} style={{color:'#000'}}>
                                      {ele.accountName}
                                  </option> : null
                              ))}
                  </NativeSelect>
                 </FormControl>
                {/* Material Ui DropDown */}


            {/* <Form.Select
                title="Select a account"
                // className="enter-num-inp"
                className="basicDropDownStyles"
                aria-label="Default select example"
                onChange={(e: any) => {
                    localStorage.setItem("AccountData", e.target.value);
                    const accountData = JSON.parse(e.target.value);
                    setAccUUID(accountData.accB4BUUID);
                }}
            >
                <option value={JSON.stringify(ParsedData)}>
                    {t("addRecipient.form.selectedAcc")}: {ParsedData?.accountName}
                </option>
                {accountDetails &&
                    accountDetails.map((ele: any, index: number) => (
                        !ele.inactive ? <option key={index} value={JSON.stringify(ele)}>
                            {ele.accountName}
                        </option> : null
                    ))}
            </Form.Select> */}
            {/* <div className="tooltip-container">
          <OverlayTrigger
            trigger="focus"
            placement="top"
            overlay={<Tooltip>Tooltip content</Tooltip>}
          >
            <input type="text" placeholder="Enter text" />
          </OverlayTrigger>
        </div> */}
            {/* {isPerson ?
              (
                <p className="ent-num-inp-text">{t("addRecipient.form.fullName")}</p>
              )
              :
              (
                <p className="ent-num-inp-text">{t("addRecipient.form.companyName")}</p>
              )
            } */}
            {isPerson ?
                 (
                    <p className="formInputLabel">{t("addRecipient.form.fullName")}</p>
                 )
                 :
                 (
                    <p className="formInputLabel">{t("addRecipient.form.companyName")}</p>
                 )
            }
            <InputTypeText
                placeholder={isPerson ? t("addRecipient.form.fullNameP") : t("addRecipient.form.companyNameP")}
                value={name}
                onBlur={() =>
                    name === ""
                        ? setNameError(isPerson ? t("errorMsgs.emptyFullName") as string : t("errorMsgs.emptyCompanyName") as string)
                        : setNameError('')
                }
                maxLength={50}
                onChange={onChangeName}
                errorMsg={nameError}
            />
            {isPerson ?
                (<span style={{ color: "#ffd457" }}>{t("addRecipient.form.fullNameWarning")}</span>) :
                (<span style={{ color: "#ffd457" }}>{t("addRecipient.form.companyNameWarning")}</span>)
            }
            <p className="formInputLabel">{t("addRecipient.form.email")}</p>
            <InputTypeText 
                placeholder={t("addRecipient.form.emailP")}
                value={email}
                onBlur={() =>
                    email === ""
                        // ? setEmailError(errorMsgs.emptyEmail)
                        // : !regexStrConst.emailRegex.test(email)
                        ? setEmailEmptyErrMsg(errorMsgs.emptyEmail)
                        : setEmailEmptyErrMsg("")
                }
                onChange={onChangeEmail}
                errorMsg={emailError ? `${t("errorMsgs.incorrectEmail")}` : emptyEmailErrMsg ? `${t("errorMsgs.emptyEmail")}` : ''}
            />
            <p className="formInputLabel">{t("addRecipient.form.type")}</p>
            <AccountTypes onSelectAccount={handleChange}/> 
            {/* <Form.Group controlId="kindOfStand">
                <Form.Check
                    type="radio"
                    aria-label="radio 0"
                    name="group1"
                    id="t00"
                >
                    <Form.Check.Input
                        defaultChecked
                        value="local"
                        onChange={handleChange}
                        type="radio"
                        name="group1"
                        isValid
                    />
                    <Form.Check.Label htmlFor="t00">
                        {t("addRecipient.form.local")}
                    </Form.Check.Label>
                </Form.Check>
                <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    name="group1"
                    id="t01"
                >
                    <Form.Check.Input
                        value="eu-iban"
                        onChange={handleChange}
                        type="radio"
                        name="group1"
                        isValid
                    />
                    <Form.Check.Label htmlFor="t01">
                        {t("addRecipient.form.sepa")}
                    </Form.Check.Label>
                </Form.Check>
                <Form.Check
                    type="radio"
                    aria-label="radio 2"
                    id="t23"
                    name="group1"
                >
                    <Form.Check.Input
                        value="uk-sort-code"
                        onChange={handleChange}
                        type="radio"
                        name="group1"
                        isValid
                    />
                    <Form.Check.Label htmlFor="t23">
                        {t("addRecipient.form.sortCodeAndAcc")}
                    </Form.Check.Label>
                </Form.Check>

                <Form.Check
                    type="radio"
                    aria-label="radio 3"
                    id="t03"
                    name="group1"
                >
                    <Form.Check.Input
                        value="us-ach"
                        onChange={handleChange}
                        type="radio"
                        name="group1"
                        isValid
                    />
                    <Form.Check.Label htmlFor="t03">
                        {t("addRecipient.form.ach")}
                    </Form.Check.Label>
                </Form.Check>
                <Form.Check
                    type="radio"
                    aria-label="radio 4"
                    id="t04"
                    name="group1"
                >
                    {" "}
                    <Form.Check.Input
                        value="other"
                        onChange={handleChange}
                        type="radio"
                        name="group1"
                        isValid
                    />
                    <Form.Check.Label htmlFor="t04">
                        {t("addRecipient.form.swift")}
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group> */}

            {financialInst === "local" ? (
                <>
                    <br></br>
                    {/* <div className="countryCodeDropdown">
                    <Form.Select
                        className="basicDropDownStyles my-1"
                        aria-label="Default select example"
                        onChange={handleChangeCountryCode}
                        defaultValue="DK"
                    >
                        <option className="dropdownOption" value="">Select Country Code</option>
                        {countryCodes.map((code) => (
                            <option className="dropdownOption" key={code} value={code}>
                                {`${getCountry(code)} (${code})`}
                            </option>
                        ))}
                    </Form.Select>
                    <p className="txtError">{countryCodeError}</p>
                    </div> */}

                    {/* Material Ui DropDown */}
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    //   value={age}
                    onChange={handleChangeCountryCode}
                    input={<DropDownInputSolid />}
                    IconComponent={DropDownArrowIconWhite}
                    defaultValue="DK"                    
                  > 
                  <option value="" className="dropdownOption" style={{ color: '#000' }}>{t("addRecipient.form.selectCountry")}</option>
                  {countryCodes?.map((code) => (
                    <option className="dropdownOption" key={code} value={code} style={{color:'#000'}}>
                        {`${getCountry(code)} (${code})`}
                    </option>
                  ))}
                  </NativeSelect>
                 </FormControl>
                 <p className="txtError">{countryCodeError}</p>
                {/* Material Ui DropDown */}
                </>
            ) : ("")}

            {financialInst !== "eu-iban" ? (
                <>
                    <p className="formInputLabel">
                        {financialInst === "uk-sort-code"
                            ? "Sort code"
                            : financialInst === "us-ach"
                                ? "Routing number"
                                : financialInst === "local"
                                    ? t("addRecipient.form.bankBrancCode")
                                    : "Swift code"}
                    </p>
                    <InputTypeText
                        placeholder={
                            financialInst === "uk-sort-code"
                                ? "Sort code"
                                : financialInst === "us-ach"
                                    ? "Routing number"
                                    : financialInst === "local"
                                        ? t("addRecipient.form.bankBrancCode")
                                        : "Swift code"
                        }
                        value={finInsValue}
                        onChange={onChangeFinInst}
                    />
                </>
            ) : (
                ""
            )}
            {/* <br /> */}
            <p className="formInputLabel">
                {financialInst === "eu-iban" ? "IBAN" : t("addRecipient.form.account")}
            </p>
            <InputTypeText
                placeholder={financialInst === "eu-iban" ? "IBAN" : t("addRecipient.form.account")}
                value={account}
                onBlur={() =>
                    account === ""
                        ? setAccountError(errorMsgs.emptyAccount)
                        : setAccountError("")
                }
                onChange={onChangeAccount}
                errorMsg={accountError && `${t("errorMsgs.emptyAccount")}`}
            />
            {/* <Tabs defaultActiveKey="second">
              <Tab eventKey="first" title="IBAN">
                <p className="ent-num-inp-text">
                  {financialInst === "eu-iban" ? "IBAN" : "Account"}
                </p>
                <InputComponent
                  placeholder="IBAN"
                  value={account}
                  onBlur={() =>
                    account === ""
                      ? setAccountError(errorMsgs.emptyAccount)
                      : setAccountError("")
                  }
                  onChange={onChangeAccount}
                  errorMsg={accountError}
                />
              </Tab>
              <Tab eventKey="second" title="Account Number">
                <br />
                <Form.Select
                  className="btn btn-light my-1"
                  aria-label="Default select example"
                  onChange={handleChangeCountryCode}
                >
                  <option value="">Select Country Code</option>
                  {countryCodes.map((code) => (
                    <option key={code} value={code}>
                      {`${getCountry(code)} (${code})`}
                    </option>
                  ))}
                </Form.Select>
                <br />
                <p className="ent-num-inp-text">
                  {financialInst === "eu-iban" ? "IBAN" : "Account"}
                </p>
                <InputComponent
                  placeholder="Account number"
                  value={accountNumber}
                  onBlur={() =>
                    account === ""
                      ? setAccountNumberError(errorMsgs.emptyAccount)
                      : setAccountNumberError("")
                  }
                  onChange={onChangeAccountNumber}
                />
                <p className="ent-num-inp-text">Bank Code / Branch Code</p>
                <InputComponent
                  placeholder="Bank/Branch Code"
                  value={bankCode}
                  onBlur={() =>
                    bankCode === ""
                      ? setBankcodeError("Please Enter Bank Code")
                      : setBankcodeError("")
                  }
                  onChange={onChangeBankCode}
                />
                <button style={{ width: '25%', fontSize: 10, fontWeight: 'bold', marginRight: 5 }} className="sendf-btn" onClick={(e: any) => handleIBANgenerate(e)}>
                  {loadingIban ? (
                    <Spinner animation={"border"} />
                  ) : "Generate IBAN"}
                </button>
                <p style={{ fontSize: 13 }}>IBAN: {iban} </p>
              </Tab>
            </Tabs> */}

            <p className="formInputLabel">{t("addRecipient.form.address")}</p>
            <InputTypeText
                placeholder={t("addRecipient.form.address")}
                type="text"
                value={address}
                onBlur={() =>
                    address === ""
                        ? setAddressError(errorMsgs.emptyAddress)
                        : setAddressError("")
                }
                maxLength={50}
                onChange={onChangeAddress}
                errorMsg={addressError && `${t("errorMsgs.emptyAddress")}`}
            />
            {
                financialInst === 'us-ach' ? <>
                    <p className="ent-num-inp-text">{t("addRecipient.form.state")}</p>
                    <Form.Select
                        title="Select Country"
                        className="enter-num-inp"
                        aria-label="Default select example"
                        // onChange={(e) => setCountry(e.target.value)}
                        onChange={(e) => onChangeState(e.target.value)}
                    >
                        <option value="">{t("addRecipient.form.selectState")}</option>
                        {states &&
                            states.map((ele, index: number) => (
                                <option value={ele} key={index}>
                                    {ele}
                                </option>
                            ))}
                    </Form.Select>
                </> : null
            }
            <p className="formInputLabel">{t("addRecipient.form.city")}</p>
            <InputTypeText
                placeholder="City"
                type="text"
                value={city}
                onBlur={() =>
                    city === ""
                        ? setCityError(errorMsgs.emptyCity)
                        : setCityError("")
                }
                maxLength={20}
                onChange={onChangeCity}
                errorMsg={cityError && `${t("errorMsgs.emptyCity")}`}
            />
            <p className="formInputLabel">{t("addRecipient.form.postCode")}</p>
            <InputTypeText
                placeholder={t("addRecipient.form.postCode")}
                type="text"
                value={postCode}
                // onBlur={() =>
                //     postCode === ""
                //         ? setPostCodeError(errorMsgs.emptyPostCode)
                //         : setPostCodeError("")
                // }
                maxLength={20}
                onChange={onChangePostCode}
                errorMsg={postCodeError}
            />
            <p className="formInputLabel">{t("addRecipient.form.country")}</p>
            {/* <Form.Select
                title="Select Country"
                // className="enter-num-inp"
                className="basicDropDownStyles"
                aria-label="Default select example"
                // onChange={(e) => setCountry(e.target.value)}
                onChange={(e) => onChangeCountry(e.target.value)}
            >
                <option value="">{t("addRecipient.form.selectCountry")}</option>
                {countries &&
                    countries.map((ele, index: number) => (
                        <option value={ele.numeric} key={index}>
                            {ele.country}
                        </option>
                    ))}
            </Form.Select> */}

            <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    //   value={age}
                    onChange={(e) => onChangeCountry(e.target.value)}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                >
                    <option value="" className="dropdownOption" style={{ color: '#000' }}>
                        {t("addRecipient.form.selectCountry")}
                    </option>
                    {countries && countries.map((ele, index: number) => (
                        <option value={ele.numeric} key={index}>
                            {ele.country}
                        </option>
                    ))}
                  </NativeSelect>
            </FormControl>
            <p className="txtError">{countryError && t("errorMsgs.emptyCountry")}</p>


            <Form.Group controlId="kindOfBeneficiary" className="companyOrPerson mt-3">
                <Form.Check
                    type="radio"
                    aria-label="radio 5"
                    name="group2"
                    id="t06"
                >
                    <Form.Check.Input
                        defaultChecked
                        value="company"
                        onChange={handleIsPerson}
                        type="radio"
                        name="group2"
                        isValid
                    />
                    <Form.Check.Label htmlFor="t06">
                        {t("addRecipient.form.company")}
                    </Form.Check.Label>
                </Form.Check>
                <Form.Check
                    type="radio"
                    aria-label="radio 6"
                    name="group2"
                    id="t05"
                >
                    <Form.Check.Input
                        value="person"
                        onChange={handleIsPerson}
                        type="radio"
                        name="group2"
                        isValid
                    />
                    <Form.Check.Label htmlFor="t05">
                        {t("addRecipient.form.person")}
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group>

            <div className="btn-send">
                {/* <button className="sendf-btn" style={{ backgroundColor: COLORS.pimary }} type="submit">
                    {loading ? (
                        <Spinner animation={"border"} />
                    ) : (
                        `${t("addRecipient.form.button")}`
                    )}
                </button> */}
                <BasicButton
                type="submit"
                text={t("addRecipient.form.button")}
                isLoading={loading}
                disable={loading}
                />
            </div>
        </form>
    );
};