import {
  ConvertResponse,
  ConvertPayload,
  ConvertPayResponse,
  ConvertPayPayload,
} from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import Swal from "sweetalert2";
import { ErrorImage, PendingProcessImage } from "../../../constants/Images";
import { showAlert } from "../swal/reducer";
import i18next from "i18next";

export const ConvertCurr = createAsyncThunk<ConvertResponse, ConvertPayload>(
  "convertData/Conversion",
  async (payload, {dispatch}) => {
    const dataBody = {
      payment_currency: payload.payment_currency,
      payment_amount: payload.payment_amount,
      beneficiary_currency: payload.beneficiary_currency,
      uuid: payload.uuid,
    };
    const token = localStorage.getItem("token");
    try {
      const data = await apiHelper(
        "fxQuotes/currencyConversion",
        "post",
        dataBody,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      return data;
    } catch (error: any) {
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: ErrorImage,
      //   title: "Oopss",
      //   text: error.message,
      //   customClass:{
      //     confirmButton:'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: error.message,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
      throw new Error();
    }
  }
);

export const ConvertTempCurr = createAsyncThunk<ConvertResponse, ConvertPayload>(
  "convertData/Conversion",
  async (payload, {dispatch}) => {
    const dataBody = {
      payment_currency: payload.payment_currency,
      payment_amount: payload.payment_amount,
      beneficiary_currency: payload.beneficiary_currency,
      uuid: payload.uuid,
    };
    const token = localStorage.getItem("token");
    try {
      const data = await apiHelper(
        "fxQuotes/tempCurrencyConversion",
        "post",
        dataBody,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      return data;
    } catch (error: any) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Oopss",
      //   text: error.message,
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: error.message,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
      throw new Error();
    }
  }
);

export const ConvertPay = createAsyncThunk<ConvertPayResponse,ConvertPayPayload>("convertPayData/ConversionPay", async (payload, {dispatch}) => {
  const dataBody = {
    beneficiary_uuid: payload.beneficiary_uuid,
    // description: payload.description,
    payment_reference: payload.payment_reference,
    fx_quote_uuid: payload.fx_quote_uuid,
    uuid: payload.uuid,
    payment_amount: payload.payment_amount,
    payment_currency: payload.payment_currency,
    beneficiary_currency: payload.beneficiary_currency,
    beneficiary_amount: payload.beneficiary_amount,
    payment_reason: payload.payment_reason,
    userID: payload.userID,
  };
  const token = localStorage.getItem("token");
  const auth = { Authorization: `Bearer ${token}`,}
  try {
    const data = await apiHelper("payment", "post", dataBody,auth );
    if (data.data.data.status !== "ERROR") {
      // Swal.fire({
      //   // icon: "info",
      //   imageUrl: PendingProcessImage,
      //   title: data.data.data.status,
      //   // confirmButtonColor: "#86B6EF",
      //   text: "Payment is being processed!",
      //   customClass:{
      //     confirmButton:'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'info',
        title: data.data.data.status,
        text: i18next.t("sweetAlerts.paymentProcessed")!,
        imageUrl: PendingProcessImage,
        imageAlt: 'Pending Image'
      }));
    } else {
      throw new Error();
    }
    return data;
  } catch (error: any) {
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   // text: "Something went wrong while sending",
    //   text: error,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: i18next.t("sweetAlerts.somethingWrongWhileSending")!,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
    
    throw new Error(error);
  }
});
