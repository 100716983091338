import React from "react";
import { t } from "i18next";
import { Modal, ModalBody } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const LegalInformationModal = (props: {
  showLegalInfo: boolean,
  handleClose: any,
}) => {
  const { showLegalInfo, handleClose } = props;
  const { t } = useTranslation();

  return (
    <Modal show={showLegalInfo} onHide={handleClose} centered className="card-settings-modal-height">
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>{t("legalInformationModal.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <ModalBody className="pt-2 pb-2">
        <p className="cards-legal-modal-text">
          {t("legalInformationModal.copyRightOneMoneyWay")}
        </p>
        <p className="cards-legal-modal-text">
          {t("legalInformationModal.informationParagraph1")}
        </p>
        <p className="cards-legal-modal-text">
        {t("legalInformationModal.informationParagraph2")}
        </p>
        <p className="cards-legal-modal-text">
        {t("legalInformationModal.informationParagraph3")}
          <br />
          <span style={{ fontWeight: "600" }}>Europe: +44 20 3475 5351 </span>
          <br />
          <span style={{ fontWeight: "600" }}>Americas: +1 604 200 0554</span>
        </p>
        {/* <p className="cards-legal-modal-text">
          The customer service can be contacted via the methods below:<br />
          Business days between <span style={{ fontWeight: "bold" }}>9am</span> to <span style={{ fontWeight: "bold" }}>5.30pm CET</span>:  <span style={{ fontWeight: "bold" }}>+4589876556</span><br />
          By e-mail: <span style={{ fontWeight: "bold" }}>Support@onemoneyway.com</span><br />
          On the user interface: <span style={{ fontWeight: "bold" }}>app.onemoneyway.com</span><br />
        </p> */}

        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
          <p style={{ textAlign: "center" }}>
            <a href="https://www.iubenda.com/privacy-policy/95230831/full-legal" target="_blank" rel="noopener noreferrer">{t("legalInformationModal.privacyPolicy")}</a>
          </p>
          <p style={{ textAlign: "center" }}>
            <a href="https://www.b4bpayments.com/prepaid/terms-and-conditions/" target="_blank" rel="noopener noreferrer">{t("legalInformationModal.termsConditions")}</a>
          </p>
        </div>
      </ModalBody>
    </Modal>
  )
};

export default LegalInformationModal;

