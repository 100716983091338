import React, { useEffect, useState } from "react";
import "../index.css";
import CardTemplate from "../../../assets/images/cardAssets/omwCardTemplate.svg"
import { FormattedCardOrder } from "../../../redux/toolkit/cards/interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CardOrderDetailsModal from "./CardOrderDetailsModal";
import { SelectedBusinessAccount } from "../interface";
import ActivateCardModal from "./ActivateCardModal";
import { t } from "i18next";

const PrepaidCardOrders = (props: {
  data: FormattedCardOrder,
}) => {
  const { data } = props;
  const [viewCardOrderDetails, setViewCardOrderDetails] = useState<boolean>(false);
  const [activateCard, setActivateCard] = useState<boolean>(false);
  const [selectedBusinessAcc, setSelectedBusinessAcc] = useState<SelectedBusinessAccount>({});
  const accountSelector: any = useSelector((state: RootState) => state.getAllAccounts.data);

  // function getCurrency(accB4BUUID: string): string | undefined {
  //   if (accountSelector.data.data) {
  //     const matchingAccount = accountSelector.data.data.find((acc: any) => acc.accB4BUUID === accB4BUUID);
  //     if (matchingAccount) {
  //       return matchingAccount.currency;
  //     }
  //   }
  //   // Return undefined if no matching account found
  //   return undefined;
  // }

  useEffect(() => {
    if (accountSelector?.data?.data) {
      const matchingAccount = accountSelector.data.data.find((acc: any) => acc.accB4BUUID === data.accB4BUUID);
      if (matchingAccount) {
        setSelectedBusinessAcc(matchingAccount)
      } else {
        setSelectedBusinessAcc({})
      }
    }
  }, [accountSelector]);

  // const handleCardClick = () => {
  //   onClick(data, data.cardId); // Call the onClick function with the cardId
  // };

  const handleCardActivateClick = () => {
    setViewCardOrderDetails(false);
    setActivateCard(true);
  };

  const handleClose = () => {
    setViewCardOrderDetails(false);
    setActivateCard(false);
  };

  return (
    <>
      <div className="cardContainer">
        <div className="cardGeneralInfo">
          <p className="availableBalanceText">{t("cardOrders.physicalCard")}</p>
          <p className="amountCurrencyText">- {selectedBusinessAcc.currency}</p>
          <p className="cardTypeText">{t("cardOrders.cardInactive")}</p>
        </div>
        <img
          onClick={() => setViewCardOrderDetails(true)}
          src={CardTemplate}
          className={`cardTemplateImage`}
        />
        <div className="cardGeneralInfo" style={{ cursor: "pointer" }} onClick={() => setViewCardOrderDetails(true)}>
          <p className="cardTypeText"><strong>{t("cardOrders.viewDetails")}</strong></p>
        </div>
      </div>

      {viewCardOrderDetails &&
        <CardOrderDetailsModal
          viewCardOrderDetails={viewCardOrderDetails}
          selectedBusinessAcc={selectedBusinessAcc}
          cardDetails={data.cardDetails}
          orderDetails={data.orderDetails}
          createdAt={data.createdAt}
          handleCardActivateClick={handleCardActivateClick}
          handleClose={handleClose}
        />
      }

      {activateCard &&
        <ActivateCardModal
          cardOrder={data}
          activateCard={activateCard}
          handleClose={handleClose}
        />
      }
    </>
  );
};

export default PrepaidCardOrders;
