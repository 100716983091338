import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash } from "react-icons/bi";
import EditableField from "./EditableField";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IconButton from "../../components/shared/Buttons/IconButton";
import BasicButton from "../../components/shared/Buttons/Basic";

const InvoiceItem = (props: any) => {
  const { t } = useTranslation();
  // const onItemizedItemEdit = onItemizedItemEdit;
  const rowDel = props.onRowDel;
  const itemTable = props.items.map(function (item: any) {
    return (
      <ItemRow
        creditFormat={props?.creditFormat}
        onItemizedItemEdit={props.onItemizedItemEdit}
        item={item}
        onDelEvent={rowDel}
        key={item.id}
        currency={props.currency}
      />
    );
  });
  return (
    <Row >
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
      <hr className="my-4" style={{color:'#8a8e92'}}/>
        <Row>
          <Col xs={8} sm={12} md={6} lg={6} xl={6} className="d-flex align-items-center">
            <th className="d-flex align-item-center fw-semibold">{t("invoice.form.itemsList.item")}</th>
          </Col>
          <Col xs={4} sm={12} md={3} lg={3} xl={2} className="ms-auto text-end" >
            <BasicButton text={t("invoice.form.itemsList.addItem")} onClick={props.onRowAdd}/>

           {/* <Button className="btn btn-primary fw-bold" style={{width:'200px'}} onClick={props.onRowAdd}>
             {t("invoice.form.itemsList.addItem")}
            </Button> */}
          </Col>
        </Row>
        <hr className="my-4" style={{color:'#8a8e92'}}/>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
         {itemTable}
      </Col>
     
     
    </Row>
  );
};
const ItemRow = (props: any) => {
  const { t } = useTranslation();

  const onDelEv = () => {
    props.onDelEvent(props.item);
  };
  return (

    <>
      <Row>
        <Col  sm={12} md={12} lg={12} >
          <Row 
          // className="d-flex justify-content-between"
          >
            <Col  sm={12} md={12} lg={12}>
            <span style={{fontWeight: 600}}>{t("invoice.form.itemsList.description")}</span>
            </Col>

            <Col xs={10} sm={11} md={11} lg={10} className="descriptionBox">
              <div className="mb-2 editableInputBox">
                <EditableField
                  onItemizedItemEdit={props.onItemizedItemEdit}
                  cellData={{
                    type: "text",
                    name: "description",
                    placeholder: "Item description",
                    value: props.item.description,
                    id: props.item.id,
                    maxLength:'30'
                  }}
                />
              </div>
              {/* <div className="">
              <BiTrash
               onClick={onDelEv.bind(this)}
               style={{ height: "33px", width: "33px", padding: "7.5px" }}
               className="text-white mt-1 btn btn-danger"
             />
              </div> */}
            </Col>

            <Col xs={2} sm={1} md={1} lg={1}>
              {/* <IconButton
              style={{background:'#F40F00'}}
              prefixIcon={<BiTrash style={{fontSize:'25px'}}/>}
              /> */}
            <BiTrash
               onClick={onDelEv.bind(this)}
               style={{ height: "33px", width: "33px", padding: "7.5px" }}
               className="text-white mt-1 btn btn-danger"
             />
            </Col>
          </Row>
        </Col>
        
      </Row>


      <Row>
          <Col xs={6} sm={12} md={6} lg={5}>
            <div className="d-flex flex-column">
              <div className="mb-2 editableInputBox">
                <span style={{fontWeight: 600}}>
                  {props?.creditFormat == "saleOfServices" ? "hrs" : t("invoice.form.itemsList.quantityLabel")}
                </span>
                <EditableField
                  onItemizedItemEdit={props.onItemizedItemEdit}
                  cellData={{
                    type: "number",
                    name: "quantity",
                    min: 1,
                    max:99,
                    step: "1",
                    value: props.item.quantity,
                    id: props.item.id,
                    // maxLength:'5'
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} sm={12} md={6}  lg={5}>
            <div className="d-flex flex-column">
              <div className="mb-2 editableInputBox">
              <span style={{fontWeight: 600}}>{t("invoice.form.itemsList.rateLabel")}</span>
               <EditableField
                 onItemizedItemEdit={props.onItemizedItemEdit}
                 cellData={{
                   leading: props.currency,
                   // type: "number",
                   name: "price",
                   min: 1,
                   step: "0.01",
                   presicion: 2,
                   textAlign: "text-end",
                   value: props.item.price,
                   id: props.item.id,
                   maxLength:'10'
                 }}
               />
              </div>
            </div>
          </Col>
          <Col md={12} sm={12} lg={12}>
          <hr className="my-4" style={{color:'#8a8e92'}}/>
          </Col>
          
      </Row>


    {/* <tr> */}
      {/* <td style={{ width: "100%" }}>
        <Row>
          <Col md={6} sm={12} lg={6}>
            <div className="d-flex flex-column">
              <div className="mb-2">
                <span className="fw-bold">
                  {props?.creditFormat == "saleOfServices" ? "hrs" : t("invoice.form.itemsList.quantityLabel")}
                </span>
                <EditableField
                  onItemizedItemEdit={props.onItemizedItemEdit}
                  cellData={{
                    type: "number",
                    name: "quantity",
                    min: 1,
                    max:99,
                    step: "1",
                    value: props.item.quantity,
                    id: props.item.id,
                    // maxLength:'5'
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} lg={6}>
            <div className="d-flex flex-column">
              <div className="mb-2">
              <span className="fw-bold">{t("invoice.form.itemsList.rateLabel")}</span>
               <EditableField
                 onItemizedItemEdit={props.onItemizedItemEdit}
                 cellData={{
                   leading: props.currency,
                   // type: "number",
                   name: "price",
                   min: 1,
                   step: "0.01",
                   presicion: 2,
                   textAlign: "text-end",
                   value: props.item.price,
                   id: props.item.id,
                   maxLength:'10'
                 }}
               />
              </div>
            </div>
          </Col>
          
        </Row>
        <div className="d-flex flex-column">
          <div className="mb-2">
            <span className="fw-bold">{t("invoice.form.itemsList.rateLabel")}</span>
            <EditableField
              onItemizedItemEdit={props.onItemizedItemEdit}
              cellData={{
                leading: props.currency,
                // type: "number",
                name: "price",
                min: 1,
                step: "0.01",
                presicion: 2,
                textAlign: "text-end",
                value: props.item.price,
                id: props.item.id,
                maxLength:'10'
              }}
            />
          </div>
        </div>
      </td> */}
      {/* <td style={{ minWidth: '70px' }}>
        <EditableField
          onItemizedItemEdit={props.onItemizedItemEdit}
          cellData={{
            type: "number",
            name: "quantity",
            min: 1,
            step: "1",
            value: props.item.quantity,
            id: props.item.id,
          }} />
      </td>
      <td style={{ minWidth: '130px' }}>
        <EditableField
          onItemizedItemEdit={props.onItemizedItemEdit}
          cellData={{
            leading: props.currency,
            type: "number",
            name: "price",
            min: 1,
            step: "0.01",
            presicion: 2,
            textAlign: "text-end",
            value: props.item.price,
            id: props.item.id,
          }} />
      </td> */}
      {/* <td className="text-center" style={{ minWidth: "50px" }}>
        <BiTrash
          onClick={onDelEv.bind(this)}
          style={{ height: "33px", width: "33px", padding: "7.5px" }}
          className="text-white mt-1 btn btn-danger"
        />
      </td> */}
    {/* </tr> */}
    </>
  );
};

export default InvoiceItem;
