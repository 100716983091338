import {
  BeneficiaryResponse,
  AddBeneficiaryPayload,
  GetBeneficiaryResponse,
  GetBenePayload,
  GetBeneficiaryPaginatedPayload,
} from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import Swal from "sweetalert2";
import { ErrorImage, SuccessImage ,UpdateSuccessImage} from "../../../constants/Images";
import i18next from "i18next";
import { showAlert } from "../swal/reducer";

export const AddBeneficiary = createAsyncThunk<
  BeneficiaryResponse,
  AddBeneficiaryPayload
>("benefiaciary/addBeneficiary", async (payload, {dispatch}) => {
  const dataBody = {
    name: payload.name,
    email: payload.email,
    financial_institution: payload.financial_institution,
    account: payload.account,
    person: payload.person,
    uuid: payload.uuid,
    address: {
      line_1: payload.address.line_1,
      city: payload.address.city,
      postcode: payload.address.postcode,
      country: payload.address.country,
      county: payload.address.county
    },
    accID: payload.accID,
    isCompany: payload.isCompany,
  };

  const token = localStorage.getItem("token");
  try {
    const data = await apiHelper("beneficiary", "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });
    // Swal.fire({
    //   // icon: "success",
    //   imageUrl: SuccessImage,
    //   title: "Recipient Added",
    //   text: "Recipient has been added successfully",
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.recipientAdded"),
      text: i18next.t("sweetAlerts.recipientAddedSuccess")!,
      imageUrl: SuccessImage,
      imageAlt: 'Success Image'
    }));
    return data;
  } catch (e: any) {
    console.log("NEW ERROR: ", e.message[5])
    // Swal.fire({
    //   imageUrl: ErrorImage,
    //   // icon: "error",
    //   title: "Oopss",
    //   text: e,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: e,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
    throw new Error();
  }
});

export const GetALlbeneficiaries = createAsyncThunk<
  GetBeneficiaryResponse,
  GetBenePayload
>("beneficiary/getAllBeneficiaries", async (accountId) => {
  let data: any;
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper(
      `beneficiary/getAccountBeneficiaries/${accountId}`,
      "get",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return data;
  } catch (err) {
    data = {};
  }

  return data;
});
export const GetALlbeneficiariesPaginated = createAsyncThunk<GetBeneficiaryResponse,GetBeneficiaryPaginatedPayload>(
  "beneficiary/getAllBeneficiaries/paginated", async (payload) => {
  let data: any;
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper(
      `beneficiary/getAccountBeneficiaries/paginated/${payload.accountId}?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
      "get",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return data.data;
  } catch (err) {
    data = {};
  }

  return data;
});

// Async action for deleting an item (API call)
export const deleteBeneficiaryAsync = createAsyncThunk<BeneficiaryResponse,any>(
  'list/deleteItem',
  async (accountId) => {
  const token = localStorage.getItem("token");
    try {
      // Make the API call to delete the item
      // For example:
      // await fetch(`/api/items/${accountId}`, {
      //   method: 'DELETE',
      // });
      await apiHelper(
        `beneficiary/deleteBeneficiary/${accountId}`,
        "delete",
        {},
        {
          Authorization: `Bearer ${token}`,
        }
      );
      return accountId;

    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred:', error);
      throw error;
    }
  }
);

export const UpdateBeneficiary = createAsyncThunk<
  any,
  AddBeneficiaryPayload
>("benefiaciary/updateBeneficiary", async (payload, {dispatch}) => {
  console.log("Updated Beneficiary OPERATIONS Payload:",payload);
  const dataBody = {
    name: payload.name,
    email: payload.email,
    // financial_institution: payload.financial_institution,
    // account: payload.account,
    // person: payload.person,
    // uuid: payload.uuid,
    // address: {
    //   line_1: payload.address.line_1,
    //   city: payload.address.city,
    //   postcode: payload.address.postcode,
    //   country: payload.address.country,
    // },
    // accID: payload.accID,
    // isCompany: payload.isCompany,
  };
  const token = localStorage.getItem("token");
  try {
    const data = await apiHelper(`beneficiary/update/${payload._id}`, "patch", dataBody, {
      Authorization: `Bearer ${token}`,
    });
    // Swal.fire({
    //   // icon: "success",
    //   imageUrl: UpdateSuccessImage,
    //   title: "Recipient Updated",
    //   text: "Recipient has been updated successfully",
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.recipientUpdated"),
      text: i18next.t("sweetAlerts.recipientUpdatedSuccess")!,
      imageUrl: UpdateSuccessImage,
      imageAlt: 'Success Image'
    }));
    return data;
  } catch (e: any) {
    console.log("NEW ERROR: ", e.message[5])
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   text: e,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: e,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
    throw new Error();
  }
});
