import { Middleware } from '@reduxjs/toolkit';
import { Socket, io } from 'socket.io-client';
import { socketActions } from '../toolkit/auth/reducer';
import BASE_URL from "../../utils/baseURL";
import { getAllCards, getAllCardsNotifications } from '../toolkit/cards/operation';
import { AppDispatch } from '../store';

const socketMiddleware: Middleware = store => {
  let socket: Socket;

  return next => action => {
    if (socketActions.startConnecting.match(action)) {
      next(action); // Pass the action to the reducers first

      const state = store.getState().socketReducer;
      const { jwtToken, userId } = state;
      if (!jwtToken || !userId) {
        console.error("Missing JWT token or user ID");
        return;
      }

      socket = io(BASE_URL, {
        withCredentials: true,
        auth: {
          authorization: `Bearer ${jwtToken}`,
        },
        query: {
          userId: userId,
        }
      });

      socket.on('connect', () => {
        store.dispatch(socketActions.connectionEstablished());
        console.log("Socket connected: ", socket.id);
      });

      socket.on("disconnect", () => {
        // socket.disconnect();
        // socket.removeAllListeners();
        store.dispatch(socketActions.disconnect());
        console.log("Socket disconnected: ", socket.id);
      });

      socket.on("card_notification", (data: any) => {
        console.log("card notification received", data);
        // store.dispatch(socketActions.cardNotificationReceived(data));
        (store.dispatch as AppDispatch)(getAllCards({ userId }));
        (store.dispatch as AppDispatch)(getAllCardsNotifications({ userId }));
      });

      socket.on('3DS_webhook', (data: any) => {
        store.dispatch(socketActions.threeDSWebhookReceived(data));
      });

      socket.on('3DS_response_received', () => {
        store.dispatch(socketActions.threeDSResponseReceived());
      });

      socket.on('Callback_response', (data: { status: string | number, message: string }) => {
        store.dispatch(socketActions.callbackResponseReceived(data));
        store.dispatch(socketActions.setLoader(false));
        store.dispatch(socketActions.setConfirmation(true));
      });
    } else if (socketActions.emitWebhookResponse.match(action)) {
      const { company_uuid, uuid, card_code, status } = action.payload;
      socket.emit("3DS_webhook_response", {
        "company_uuid": company_uuid,
        "uuid": uuid,
        "card_code": card_code,
        "status": status,
        "socketID": socket.id,
      });
      store.dispatch(socketActions.setLoader(true));

      next(action); // Pass the action to the reducers first
    } else if (socketActions.disconnect.match(action)) {
      if (socket) {
        socket.disconnect();
        socket.removeAllListeners();
      }
      next(action);
    } else {
      next(action);
    }
  };
};

export default socketMiddleware;