import React, { useEffect, useState } from "react";
import InputComponent from "../../components/shared/Input/Index";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootState, AppDispatch } from "../../redux/store";
import { ForgotPasswordOp } from "../../redux/toolkit/auth/operation";
import { errorMsgs, regexStrConst, LOGO_URL } from "../../constants/stringConstants";
import { Spinner } from "react-bootstrap";
import LanguageDropdown from "../../components/shared/TranslationDropdown";
import { OMWLogoWhite } from "../../constants/Images";

import "./index.css";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../components/shared/Buttons/Basic";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const forgotPassDetailss: any = useSelector((state: RootState) => state.forgotPassDetails);
  
  const validate = () => {
    if (email === "") setEmailError(errorMsgs.emptyEmail);
    return email === "" || emailError ? false : true;
  };

  const dispatch = useDispatch<AppDispatch>();
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const payload = {
        email: email,
      };
      dispatch(ForgotPasswordOp(payload)).then(() => setLoading(false));
    }
  };
  const isValidEmailOnBlur = (e: string) => {
    if (!e || !regexStrConst.emailRegex.test(e)) {
      setEmailError(errorMsgs.emptyEmail)
    }
    else {
      setEmailError('')
    }
  }

  useEffect(() => {
    if (
      forgotPassDetailss &&
      Object.keys(forgotPassDetailss.data).length !== 0
    ) {
      navigate("/forgotPassword", { state: forgotPassDetailss.data });
    }
  }, [forgotPassDetailss]);

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.emailRegex.test(item)) {
      setEmailError("");
    } else {
      setEmailError(errorMsgs.emptyEmail);
    }
    setEmail(item);
  };
  return (
    <div className="ForgotPassword p-2">
      <div className="onBoardingImage onBoardingBGImg">
        {/* <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              style={{ width: 210, height: 80,filter:"drop-shadow(0 0 0.4px)"}}
              src={LOGO_URL}
              alt="One Money Way"
            ></img>
          </div>
        </div> */}
        {/* <div className="left-title-box">
          <h2 className="left-title">{t("login.title.payment")}</h2>
          <h2 className="left-title-second">{t("login.title.platform")}</h2>
          <h2 className="left-title-third">{t("login.title.SMEs")}</h2>
        </div>
        <div className="welcome-box">
          <p className="welcome">{t("login.title.welcome")}</p>
          <p>{t("login.welcomeDesc")}</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div> */}
        <img className="omwLogo" src={OMWLogoWhite} />
      </div>
      <div className="onBoardingForm">
      <div className="top-banner">
        <img src={OMWLogoWhite}/>
      </div>
        <div className="lng-dropdown">
          <LanguageDropdown />
        </div>
        {/* <div className="point-box">
          <p className="left-point"></p>
          <p className="middle-point"></p>
          <p className="right-point"></p>
        </div> */}
        <h2 className="onBoarding-title mt-4 mt-sm-0">{t("forgotPassword.title.heading")}</h2>
        <p className="onBoarding-text">{t("forgotPassword.title.description")}</p>
        <form className="forgot-form">
          <p className="inputLabel">{t("registration.email")}</p>
          {/* <InputComponent
            className="forgot_email-inp"
            placeholder={t("forgotPassword.emailP")}
            value={email}
            onBlur={() =>
              email === ""
                ? setEmailError(errorMsgs.emptyEmail)
                : setEmailError("")
            }
            onChange={onChangeEmail}
            errorMsg={emailError}
          /> */}
          <InputTypeText 
            className="inputText"
            // placeholder={t("forgotPassword.emailP")}
            value={email}
            onBlur={(e) => isValidEmailOnBlur(e.target.value)}
            onChange={onChangeEmail}
            // errorMsg={emailError}
            errorMsg={emailError && `${t("errorMsgs.emptyEmail")}`}
          />
          <BasicButton
            text={t("forgotPassword.submit")}
            style={{marginTop:'20px'}}
            isLoading={loading}
            onClick={handleButtonClick}
            disabled={loading}
          />
          {/* <button className="btnSubmit btn mt-4" onClick={handleButtonClick}>
            {loading ? (
              <Spinner animation={"border"} />
            ) : (
              `${t("forgotPassword.submit")}`
            )}
          </button> */}
          {/* <button
            className="forgot_backtolog-btn btn btn-primary"
            onClick={() => navigate("/")}
          >
            {t("forgotPassword.backToLogin")}
          </button> */}
          <div className="backToLogin"  >
              <span onClick={() => navigate("/")}>
              {t("forgotPassword.backToLogin")}
              </span>
          </div>
        </form>
        </div>
      </div>
  );
};

export default ForgotPassword;
