import { createSlice } from "@reduxjs/toolkit";
import { Logout } from "../auth/operation";
import { InvoiceNumberResponse, OTPPaymentResponse, QrPaymentResponse } from "./interface";
import { GetInvoiceList, OTPVerificationPayment, QrVerificationPayment,GetInvoiceListPaginated } from "./operation";
import { resetStatus } from "../../actions";

const initialState = {
  data: {},
  status: ""
} as QrPaymentResponse;
const otpinitialState = {
  data: {},
  status: ""
} as OTPPaymentResponse;
const invoiceinitialState = {
  data: {},
} as InvoiceNumberResponse;



const qrPaymentSlice = createSlice({
  name: "verfypayment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(QrVerificationPayment.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(QrVerificationPayment.fulfilled, (state: any, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(QrVerificationPayment.rejected, (state: any) => {
      state.status = "failed";
    });
    builder.addCase(resetStatus, (state) => {
      state.status = ''; // Reset status to an empty string
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});
const otpPaymentSlice = createSlice({
  name: "otpverfypayment",
  initialState: otpinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(OTPVerificationPayment.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(OTPVerificationPayment.fulfilled, (state: any, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(OTPVerificationPayment.rejected, (state: any) => {
      state.status = "failed";
    });
    builder.addCase(resetStatus, (state) => {
      state.status = ''; // Reset status to an empty string
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});
const invoiceSlice = createSlice({
  name: "invoice",
  initialState: invoiceinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetInvoiceList.fulfilled, (state: any, action) => {
      console.log("Invoice Fulfilled", action.payload);
      state.data = action.payload;
    });
    builder.addCase(GetInvoiceListPaginated.fulfilled, (state: any, action) => {
      console.log("Invoice Fulfilled=>", action.payload);
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

export default {
  qrPaymentSlice,
  otpPaymentSlice,
  invoiceSlice
}
