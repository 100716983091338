import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "../index.css"

const AccountTypes = (props: any) => {
  const { t } = useTranslation();

  return (
    <Form.Group controlId="kindOfStand" className="accountType">
      <Form.Check type="radio" aria-label="radio 0" name="group1" id="t00">
        <Form.Check.Input
          defaultChecked
          value="local"
          onChange={props?.onSelectAccount}
          type="radio"
          name="group1"
          isValid
        />
        <Form.Check.Label htmlFor="t00">
          {t("addRecipient.form.local")}
        </Form.Check.Label>
      </Form.Check>
      <Form.Check type="radio" aria-label="radio 1" name="group1" id="t01">
        <Form.Check.Input
          value="eu-iban"
          onChange={props?.onSelectAccount}
          type="radio"
          name="group1"
          isValid
        />
        <Form.Check.Label htmlFor="t01">
          {t("addRecipient.form.sepa")}
        </Form.Check.Label>
      </Form.Check>
      <Form.Check type="radio" aria-label="radio 2" id="t23" name="group1">
        <Form.Check.Input
          value="uk-sort-code"
          onChange={props?.onSelectAccount}
          type="radio"
          name="group1"
          isValid
        />
        <Form.Check.Label htmlFor="t23">
          {t("addRecipient.form.sortCodeAndAcc")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check type="radio" aria-label="radio 3" id="t03" name="group1">
        <Form.Check.Input
          value="us-ach"
          onChange={props?.onSelectAccount}
          type="radio"
          name="group1"
          isValid
        />
        <Form.Check.Label htmlFor="t03">
          {t("addRecipient.form.ach")}
        </Form.Check.Label>
      </Form.Check>
      <Form.Check type="radio" aria-label="radio 4" id="t04" name="group1">
        {" "}
        <Form.Check.Input
          value="other"
          onChange={props?.onSelectAccount}
          type="radio"
          name="group1"
          isValid
        />
        <Form.Check.Label htmlFor="t04">
          {t("addRecipient.form.swift")}
        </Form.Check.Label>
      </Form.Check>
    </Form.Group>
  );
};
export default AccountTypes;
