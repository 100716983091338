import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import "./index.css";
import { Table } from "react-bootstrap";

const Footer = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="footer">
      <div className="footer-text">
        <a
          href={"https://www.b4bpayments.com/prepaid/terms-and-conditions/"}
          className="footer-a"
        >
          {t("footer.terms")}
        </a>
        <span className="privacy-policy-vr">|</span>
        <span
          className="privacy-policy-footer-text"
          onClick={() => navigate("/privacyPolicy")}
        >
           {t("footer.policy")} 
        </span>
        <span className="privacy-policy-vr">|</span>
        <div>
          <Typography
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <span className="pop-over-text"> {t("footer.fee.title")} </span>
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 3 }}>
              <div className="main-summarybox-table">
                <div className="transaction-fees">
                  <span className="transaction-text">
                  {t("footer.fee.description")}
                  </span>
                </div>
                <Table className="summary-box-tablee" responsive size="sm">
                  <thead>
                    <tr>
                      <th className="fees-table-summary">{t("footer.fee.item")}</th>
                      <th className="fees-table-summary">{t("footer.fee.inbound")} (£/€)</th>
                      <th className="fees-table-summary">{t("footer.fee.outbound")} (£/€)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fees-table-summary">{t("footer.fee.sepaAndSwift")}</td>
                      <td className="fees-table-summary">1€ + 0.1 %</td>
                      <td className="fees-table-summary">1€</td>
                    </tr>
                    <tr>
                      <td className="fees-table-summary">{t("footer.fee.fastPayments")}</td>
                      <td className="fees-table-summary">1€ + 0.1 %</td>
                      <td className="fees-table-summary">1€</td>
                    </tr>
                    <tr>
                      <td className="fees-table-summary">{t("footer.fee.dkk")}</td>
                      <td className="fees-table-summary">1€ + 0.1 %</td>
                      <td className="fees-table-summary">1€</td>
                    </tr>
                    <tr>
                      <td className="fees-table-summary">{t("footer.fee.sha")}</td>
                      <td className="fees-table-summary">15€</td>
                      <td className="fees-table-summary">15€</td>
                    </tr>

                    <tr>
                      <td className="fees-table-summary">{t("footer.fee.our")}</td>
                      <td className="fees-table-summary">15€</td>
                      <td className="fees-table-summary">15€</td>
                    </tr>
                  </tbody>
                </Table>
                <div className="transaction-fees">
                  <span className="transaction-text">{t("footer.fee.fxFee")}</span>
                </div>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th className="fees-table-summary"></th>
                      <th className="fees-table-summary">{t("footer.fee.fxMargin")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fees-table-summary">{t("footer.fee.category")} 1,2,3</td>
                      <td className="fees-table-summary">2%</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Typography>
          </Popover>
        </div>
        <p className="footer-p"> &copy; {t("footer.copyRight")}.</p>
      </div>
    </div>
  );
};

export default Footer;
