import { createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { apiHelper } from "../../../api helper";
import { OTPPaymentResponse, QrPaymentPayload, QrPaymentResponse, OTPPaymentPayload, PayexInvoiceResponse, PayexInvoicePayload, InvoiceNumberResponse, InvoiceNumberPayload, GetInvoicePayload,GetPaginatedInvoicePayload } from "./interface";
import { PathStrConst } from "../../../constants";
import { showAlert } from "../swal/reducer";
import i18next from "i18next";

import { ErrorImage, SuccessImage } from "../../../constants/Images";


export const QrVerificationPayment = createAsyncThunk<
  QrPaymentResponse,
  QrPaymentPayload
>("qrDetails/getQrDetailsPayments", async (payload,{ dispatch }) => {
  const dataBody = {
    email: payload.email,
    phoneNo: payload.phoneNo,
    token: payload.token,
    accB4BUUID:payload?.accB4BUUID,
  };
  let data: any = {};
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper("payment/verify-payment-qr", "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });

    return data.data;
  } catch (error: any) {
    dispatch(showAlert({
      type: 'error',
      title: 'Oopss',
      text: error.message,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
  }
  return data;
});

export const OTPVerificationPayment = createAsyncThunk<
  OTPPaymentResponse,
  OTPPaymentPayload
>("otpDetails/getOtpDetailsPayments", async (payload, {dispatch}) => {
  const dataBody = {
    phoneNo: payload.phoneNo,
    otp: payload.otp,
    accB4BUUID:payload.accB4BUUID
  };
  let data: any = {};
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper("payment/verify-payment-otp", "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });

    return data.data;
  } catch (error: any) {
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   text: error,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: 'Oopss',
      text: error.message,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
  }
  return data;
});

export const InvoiceNumber = createAsyncThunk<
  InvoiceNumberResponse,
  InvoiceNumberPayload
>("otpDetails/getOtpDetailsPayments", async (payload) => {

  let data: any = {};
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper(`payment/payex-invoice-number/${payload.accountId}`, "get", {
      Authorization: `Bearer ${token}`,
    });

    return data.data;
  } catch (error: any) {
    // Swal.fire({
    //   icon: "error",
    //   title: "Oopss",
    //   confirmButtonColor: "#86B6EF",
    //   text: error,
    // });
  }
  return data;
});

export const PayexInvoice = createAsyncThunk<
  PayexInvoiceResponse,
  PayexInvoicePayload
>("otpDetails/getOtpDetailsPayments", async (payload, {dispatch}) => {
  const dataBody = payload;
  let data: any = {};
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper("payment/payex-invoice", "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });
    // Swal.fire({
    //   // icon: "success",
    //   imageUrl: SuccessImage,
    //   title: "Invoice Created!!",
    //   text: "Invoice Created successfully",
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    //   // confirmButtonColor: '#0B5ED7'
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.invoiceCreated"),
      text: i18next.t("sweetAlerts.invoiceSuccess")!,
      imageUrl: SuccessImage,
      imageAlt: 'Success Image'
    }));
    console.log('API data is', data)
    return data.data;
  } catch (error: any) {
    if (error == 'Error: User address details are missing.') {
      // Swal.fire({
      //   title: "Address details missing",
      //   icon: "info",
      //   html: `
      //     Please update
      //     <a href="${PathStrConst.editregistration}">profile</a>
      //     before creating invoice.
      //   `,
      //   confirmButtonColor: "#86B6EF",
      // });
      dispatch(showAlert({
        type: 'info',
        title: i18next.t("sweetAlerts.addressMissing"),
        icon: "info",
        html: `
          ${i18next.t("sweetAlerts.pleaseUpdate")}
          <a href="${PathStrConst.editregistration}">profile</a>
          ${i18next.t("sweetAlerts.beforeCreating")}
        `,
      }));

    } else {
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: ErrorImage,
      //   title: "Oopss",
      //   // confirmButtonColor: "#86B6EF",
      //   text: error,
      //   customClass:{
      //     confirmButton:'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: 'Oopss',
        text: error.message,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
    }

  }
  return data.data;
});

export const GetInvoiceList = createAsyncThunk<
  InvoiceNumberResponse,
  GetInvoicePayload
>("invoice/getInvoiceList", async (accountId) => {
  let data: any;
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper(
      `payment/getInvoiceList/${accountId}`,
      "get",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return data;
  } catch (err) {
    data = {};
  }

  return data;
});
export const GetInvoiceListPaginated = createAsyncThunk<
  InvoiceNumberResponse,
  GetPaginatedInvoicePayload
>("invoice/getInvoiceList/paginated", async (payload) => {
  let data: any;
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper(
      `payment/getInvoiceList/paginated/${payload.accountId}?&page=${payload?.page}&limit=${payload?.limit}&search=${payload?.search}`,
      "get",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return data.data;
  } catch (err) {
    data = {};
  }

  return data;
});

export const SendInvoiceEmail = createAsyncThunk<
  any,
  any
>("otpDetails/getOtpDetailsPayments", async (payload, {dispatch}) => {
  const dataBody = payload;
  let data: any = {};
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper("payment/send-invoice-email", "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });
    // Swal.fire({
    //   // icon: "success",
    //   imageUrl: SuccessImage,
    //   title: "Invoice Sent!!",
    //   text: "Invoice Sent successfully",
    //   // confirmButtonColor: '#0B5ED7'
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.invoiceSent"),
      text: i18next.t("sweetAlerts.invoiceSendSuccess")!,
      imageUrl: SuccessImage,
      imageAlt: 'Success Image'
    }));
    console.log('API data is', data)
    return data.data;
  } catch (error: any) {
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   text: error,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: 'Oopss',
      text: error.message,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
  }
  return data.data;
});

export const SendGeneratedInvoiceEmail = createAsyncThunk<
  any,
  any
>("otpDetails/getOtpDetailsPayments", async (payload, {dispatch}) => {
  const dataBody = payload;
  let data: any = {};
  const token = localStorage.getItem("token");
  try {
    data = await apiHelper("payment/send-generated-invoice-email", "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });
    // Swal.fire({
    //   // icon: "success",
    //   imageUrl: SuccessImage,
    //   title: "Invoice Sent!!",
    //   text: "Invoice Sent successfully",
    //   // confirmButtonColor: '#0B5ED7'
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.invoiceSent"),
      text: i18next.t("sweetAlerts.invoiceSendSuccess")!,
      imageUrl: SuccessImage,
      imageAlt: 'Success Image'
    }));
    console.log('API data is', data)
    return data.data;
  } catch (error: any) {
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   text: error,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: 'Oopss',
      text: error.message,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
  }
  return data.data;
});
