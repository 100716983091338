import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from "react-i18next";
import "./index.css"

import { useDispatch, useSelector } from "react-redux";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import { cardPinControlToken } from "../../../../redux/toolkit/cards/operation";
import { AppDispatch } from "../../../../redux/store";

const ShowPinModal = (props: { showPin: boolean, handleClose: () => void, selectedCardId: string, selectedCardAccountId: string }) => {
  const { showPin, handleClose, selectedCardId, selectedCardAccountId } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cardPin, setCardPin] = useState<string>("");
  const [pinDigitsArray, setPinDigitsArray] = useState<Array<string>>([]);

  const pinControlTokenSelector = useSelector(cardSelectors.pinControlTokenSelect);

  // gets the card PIN from B4B with card ID and company UUID (uuid field is card company UUID - B4B account UUID)
  useEffect(() => {
    dispatch(cardPinControlToken({
      userId: localStorage.getItem('userId')!,
      card_code: selectedCardId,
      //company UUID (B4B account UUID) for the cards
      uuid: selectedCardAccountId
    }))
      .unwrap()
      .then((resp: any) => {
        if (resp.data.pin) {
          setCardPin(resp.data.pin);
          setPinDigitsArray(resp.data.pin.split(""))
        }
      })
      .catch((err) => {
        console.log("ERROR fetching card pin:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Modal show={showPin} centered onHide={() => {
      setCardPin('');
      setPinDigitsArray([]);
      return handleClose();
    }}>
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>{t('cardsSettings.yourCardPIN')}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={() => {
            setCardPin('');
            setPinDigitsArray([]);
            handleClose();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <ModalBody className="pt-0 pb-3 px-3">
        {isLoading ? (
          <div className="loader">
            <Spinner animation={"border"} size="sm" />
          </div>
        ) : (
          <div className="pinContainer">
            {pinDigitsArray.map((digit, index) => (
              <div key={index} className="pinFrame">
                <p className="pinDigit">
                  {digit}
                </p>
              </div>
            ))}
          </div>
        )}
      </ModalBody>
    </Modal >
  )
};

export default ShowPinModal;
