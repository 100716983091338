import storage from "redux-persist/lib/storage";

export const convertConfig = {
  key: "convertDetailsNew",
  storage,
  whitelist: ["convertDtails"],
};

export const convertPayConfig = {
  key: "convertPayDetailsNew",
  storage,
  whitelist: ["convertPayDtails"],
};
