import { GetAllAccountsResponse, GetAllAccountPayload, GetAccountStatementPayload, GetAccountStatementResponse, GetAccountStatementPdfPayload } from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";

export const getAllAccounts = createAsyncThunk<
  GetAllAccountsResponse,
  GetAllAccountPayload
>("accounts/allaccounts", async (userId) => {
  const token = localStorage.getItem("token");
  const data = await apiHelper(
    `activeUser?userID=${userId}`,
    "get",
    {},
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  );
  return data;
});

export const getFilterdAccount = createAsyncThunk<
  GetAllAccountsResponse,
  GetAllAccountPayload
>("accounts/filteredAccount", async (filterId) => {
  const data = await apiHelper(`activeUser?accB4BUUID=${filterId}`, "get");
  return data;
});

export const getAccountStatement = createAsyncThunk<GetAccountStatementResponse,GetAccountStatementPayload>(
  "payment/statement", 
  async (payload) => {
    const token = localStorage.getItem("token");
  const data = await apiHelper<GetAccountStatementResponse>(
    `payment/statement?start=${payload.startDate}&end=${payload.endDate}&companyId=${payload.accB4BUUID}&lang=${payload.language}`, 
    "get",
    {},
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    );
  // return data;
  return data.data;
});
export const getAccountStatementPagenated  = createAsyncThunk<GetAccountStatementResponse,GetAccountStatementPayload>(
  "payment/statement", 
  async (payload) => {
    const token = localStorage.getItem("token");
  const data = await apiHelper<GetAccountStatementResponse>(
    `payment/statement/paginated?start=${payload.startDate}&end=${payload.endDate}&companyId=${payload.accB4BUUID}&lang=${payload.language}&page=${payload.page}&limit=${payload.pageLimit}&search=${payload.search}`, 
    "get",
    {},
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    );
  // return data;
  return data.data;
});
export const getAccountStatementPdf  = createAsyncThunk<GetAccountStatementResponse,GetAccountStatementPdfPayload>(
  "payment/statement/pdf", 
  async (payload) => {
    const token = localStorage.getItem("token");
  const data = await apiHelper<GetAccountStatementResponse>(
    `payment/statement/pdf?start=${payload.startDate}&end=${payload.endDate}&companyId=${payload.accB4BUUID}&lang=${payload.language}&search=${payload.search}`, 
    "get",
    {},
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    );
  // return data;
  return data.data;
});


