import storage from "redux-persist/lib/storage";

export const UpdateProfileDetailsConfig = {
  key: "UpdateProfileDetailsNew",
  storage,
  whitelist: ["UpdatedProfileDtails"],
};

export const loginDetailsConfig = {
  key: "loginDetailsNew",
  storage,
  whitelist: ["loginDetails"],
};
export const logoutDetailsConfig = {
  key: "logoutDetails",
  storage,
  whitelist: ["logoutDetails"],
};

export const lastLoginDetailsConfig = {
  key: "lastLoginDetails",
  storage,
  whitelist: ["lastLoginDetails"],
};

export const qrDetailsConfig = {
  key: "qrDetailsNew",
  storage,
  version: 1
};
export const selectedMerchantconfig= {
  key: "selectedMerchantNew",
  storage,
  version: 1
};
export const otpVerifyDetailsConfig = {
  key: "otpVerifyDetailsNew",
  storage,
  version: 1
};

export const forgotPassDetailsConfig = {
  key: "forgotPassDetailsNew",
  storage,
  whitelist: ["forgotPassDetails"],
};

export const resetPassDetailsConfig = {
  key: "resetPassDetailsNew",
  storage,
  whitelist: ["resetPassDetails"],
};
