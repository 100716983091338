// SWALWrapper.tsx
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { hideAlert } from '../../../redux/toolkit/swal/reducer';

const SWALWrapper: React.FC = () => {
  const alert = useAppSelector((state) => state.alert);
  // console.log(alert,"==========> SWAL Wrapper")
  const dispatch = useAppDispatch();

  useEffect(() => {
    // console.log('Alert state:', alert); 
    if (alert.show) {
      Swal.fire({
        title: alert.title,
        text: alert.text,
        icon: alert.icon,
        html: alert.html,
        imageUrl: alert.imageUrl,
        imageAlt: alert.imageAlt,
        customClass: {
          confirmButton: 'modalBtnOK',
        },
      }).then(() => {
        dispatch(hideAlert());
      }).catch((err) => {
        console.error('SweetAlert2 error:', err);
      });
    }
  }, [alert, dispatch]);

  return null;
};

export default SWALWrapper;
