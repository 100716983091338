import React,{useState} from "react";
import { BsEye,BsEyeSlash } from "react-icons/bs";


const InputTypeSecure = (props:any) => {
    const [isPassShow, setIsPassShow] = useState(true)


  return (
    <>
      <div className="inputPassDiv">
        <input
          type={`${isPassShow ? "password" : "text"}`}
          className="inputTextBox inputPass"
          // placeholder={t("enterPass")}
          value={props?.value}
          onBlur={props?.onBlur
            // () =>
            // password === ""
            //   ? setPassError(errorMsgs.emptyPassword)
            //   : setPassError("")
          }
          onChange={props?.onChange}
        />
        <span className="viewPassIcon">
          {isPassShow ? (
            <BsEyeSlash
              className="showPassIcon"
              onClick={() => setIsPassShow(false)}
            />
          ) : (
            <BsEye className="showPassIcon" onClick={() => setIsPassShow(true)} />
          )}
        </span>
      </div>
      <span style={{ color: "red" }}>{props?.errorMsg}</span>
    </>
  );
};

export default InputTypeSecure;
