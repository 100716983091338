import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { RegionBlocking } from "../../../../redux/toolkit/cards/interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { getCardDetails } from "../../../../redux/toolkit/cards/operation";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import Swal from "sweetalert2";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { useTranslation } from "react-i18next";

const InternationalPaymentsModal = (props: {
  showIntPayConfig: boolean,
  selectedCardId: string,
  selectedCardType: string,
  handleClose: any,
}) => {
  const { showIntPayConfig, handleClose, selectedCardId, selectedCardType } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [regionBlocks, setRegionBlocks] = useState<RegionBlocking>({
    africa: false,
    asia: false,
    europe: false,
    home: false,
    northAmerica: false,
    southAmerica: false,
    oceania: false,
  });

  const cardDetailsSelector = useSelector(cardSelectors.cardDetailsSelect);
  const updateCardSelector = useSelector(cardSelectors.updateCardSelect);

  // This useEffect sets the current applied blocks to show in UI
  // Gets specifically for the card that was selected
  useEffect(() => {
    dispatch(getCardDetails({ userId: localStorage.getItem('userId')!, cardId: selectedCardId }))
      .unwrap()
      .then((resp) => {
        return setRegionBlocks(resp.data.regionAndEcommBlocking);
      })
      .catch((err) => { console.log("Error while getting card details: ", err) })
  }, [])

  // updates the state which contains all the region blocks
  const handleSwitchChange = (region: string) => (event: any) => {
    setRegionBlocks((prev) => ({
      ...prev,
      [region]: event.target.checked,
    }));
  };

  // dispatch new user selected blocks to BE and update Enfuce
  const handleSave = () => {
    // const internationalPaymentsPayload: UpdateCardDataPayload = {
    //   userId: localStorage.getItem("userId")!,
    //   cardId: selectedCardId,
    //   regionAndEcommBlocking: regionBlocks,
    // }
    // if (selectedCardType === "Physical Card") {
    //   dispatch(updatePrepaidCard(internationalPaymentsPayload))
    //     .unwrap()
    //     .then((resp) => {
    //       dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
    //       dispatch(getAllCardAccounts({ userId: localStorage.getItem('userId')! }))
    //       // handleClose();
    //       Swal.fire({
    //         icon: "success",
    //         title: "Success",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Your card status has been updated!",
    //       }).then(() => { handleClose(); });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Swal.fire({
    //         icon: "error",
    //         title: "Error",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Error while updating card, please try again!",
    //       }).then(() => { handleClose(); });
    //     })
    // } else {
    //   dispatch(updateVirtualCard(internationalPaymentsPayload))
    //     .unwrap()
    //     .then((resp) => {
    //       dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
    //       dispatch(getAllCardAccounts({ userId: localStorage.getItem('userId')! }))
    //       Swal.fire({
    //         icon: "success",
    //         title: "Success",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Your card status has been updated!",
    //       }).then(() => { handleClose(); });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Swal.fire({
    //         icon: "error",
    //         title: "Error",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Error while updating card, please try again!",
    //       }).then(() => { handleClose(); });
    //     })
    // }
  };

  return (
    <Modal
      show={showIntPayConfig}
      centered
      onHide={handleClose}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>{t("internationalPaymentModal.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <ModalBody className="pt-2 pb-2">
        {cardDetailsSelector.status === "loading"
          ? <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <Spinner animation={"border"} />
          </div>
          : <Form>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-bold">Africa</span>
              <div>
                <Form.Check
                  type="switch"
                  id="africa-switch"
                  onChange={handleSwitchChange("africa")}
                  checked={regionBlocks.africa}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-bold">Asia</span>
              <div>
                <Form.Check
                  type="switch"
                  id="asia-switch"
                  onChange={handleSwitchChange("asia")}
                  checked={regionBlocks.asia}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-bold">Europe</span>
              <div>
                <Form.Check
                  type="switch"
                  id="europe-switch"
                  onChange={handleSwitchChange("europe")}
                  checked={regionBlocks.europe}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-bold">Home</span>
              <div>
                <Form.Check
                  type="switch"
                  id="home-switch"
                  onChange={handleSwitchChange("home")}
                  checked={regionBlocks.home}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-bold fw-bold">North America</span>
              <div>
                <Form.Check
                  type="switch"
                  id="northAmerica-switch"
                  onChange={handleSwitchChange("northAmerica")}
                  checked={regionBlocks.northAmerica}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-bold">South America</span>
              <div>
                <Form.Check
                  type="switch"
                  id="southAmerica-switch"
                  onChange={handleSwitchChange("southAmerica")}
                  checked={regionBlocks.southAmerica}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-bold">Oceania</span>
              <div>
                <Form.Check
                  type="switch"
                  id="ocenia-switch"
                  onChange={handleSwitchChange("oceania")}
                  checked={regionBlocks.oceania}
                />
              </div>
            </div>
          </Form>
        }

      </ModalBody>
      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="submit"
          isLoading={updateCardSelector.status === "loading"}
          onClick={handleSave}
          text= {t("internationalPaymentModal.save")}
        />
      </ModalFooter>
    </Modal>
  )
};

export default InternationalPaymentsModal;