import { Skeleton } from "@mui/material";
import React from "react";
import { LOGO_URL } from '../../../constants/stringConstants'
import { OMWLogoBlack } from '../../../constants/Images'

const SkeletonCard = () => {
  return (
    <div className="col-12 col-xl-4 pb-2 pb-xl-0">
      <div className="card-general-mainn">
        <div className="image-icons-bank">
          <img src={OMWLogoBlack} alt="avatar" className="image-logo-card" />
          <Skeleton
            className="card-currency"
            variant="rectangular"
            width={40}
            height={30}
          />
        </div>
        <div className="text-content-card-general p-2">
          <Skeleton variant="rectangular" width="100%" height={40} />
          <span className="iban-number-textt">IBAN No:</span>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <div style={{ marginTop: 30 }}>
            <Skeleton variant="rectangular" width={30} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
