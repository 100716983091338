import React from "react";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import '../index.css'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  tableContainer: {
    width: '100%',
  },
  headerRow: {
    fontFamily:'Montserrat',
    backgroundColor: 'gray',
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px !important',
      marginBottom:'0px',
      
    },
  },
  subHeaderRow: {
    backgroundColor: 'lightgray',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  dataRow: {
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  tableCell: {
    fontFamily:'Montserrat !important',
    padding: '8px',
    [theme.breakpoints.down('xs')]: {
      padding: '4px !important',
      fontSize: "9px !important"
    },
  },
  categoryHeader: {
    fontSize: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));

type BusinessAccountsFeeData = {
  Transaction: string;
  EUR: number | string;
  DKK: number | string;
  SEK: number | string;
  NOK: number | string;
  USD: number | string;
  GBP: number | string;
};

type ClientCategory = {
  category: string;
  data: BusinessAccountsFeeData[];
};

const businessAccountFeeData: ClientCategory[] = [
  {
    category: "Business & Enterprise",
    data: [
      { Transaction: "SEPA IN & OUT + 0.1% on all incoming", EUR: 1, DKK: 7.5, SEK: 10, NOK: 10, USD: 1, GBP: 0.9 },
      { Transaction: "FASTER PAYMENTS + 0.1% on all incoming", EUR: 1, DKK: 7.5, SEK: 10, NOK: 10, USD: 1, GBP: 0.9 },
      { Transaction: "SWIFT (SHA) IN & OUT + 0.1% on all incoming", EUR: 15, DKK: 110, SEK: 165, NOK: 165, USD: 15, GBP: 13 },
      { Transaction: "DKK IN & OUT + 0.1% on all incoming", EUR: 1, DKK: 7.5, SEK: 10, NOK: 10, USD: 1, GBP: 0.9 },
      { Transaction: "FX FEE", EUR: "3%", DKK: "3%", SEK: "3%", NOK: "3%", USD: "3%", GBP: "3%" },
    ]
  },
  {
    category: "Starter",
    data: [
      { Transaction: "SEPA IN & OUT + 0.3% on all incoming", EUR: 1, DKK: 7.5, SEK: 10, NOK: 10, USD: 1, GBP: 0.9 },
      { Transaction: "FASTER PAYMENTS + 0.3% on all incoming", EUR: 1, DKK: 7.5, SEK: 10, NOK: 10, USD: 1, GBP: 0.9 },
      { Transaction: "SWIFT (SHA) IN & OUT + 0.3% on all incoming", EUR: 15, DKK: 110, SEK: 165, NOK: 165, USD: 15, GBP: 13 },
      { Transaction: "DKK IN & OUT + 0.3% on all incoming", EUR: 1, DKK: 7.5, SEK: 10, NOK: 10, USD: 1, GBP: 0.9 },
      { Transaction: "FX FEE", EUR: "3%", DKK: "3%", SEK: "3%", NOK: "3%", USD: "3%", GBP: "3%" },
    ]
  },
  {
    category: "Micro",
    data: [
      { Transaction: "SEPA IN & OUT + 0.45% on all in and outgoing", EUR: 2, DKK: 15, SEK: 20, NOK: 20, USD: 2, GBP: 1.8 },
      { Transaction: "FASTER PAYMENTS + 0.45% on all in and outgoing", EUR: 2, DKK: 15, SEK: 20, NOK: 20, USD: 2, GBP: 1.8 },
      { Transaction: "SWIFT (SHA) IN & OUT + 0.45% on all in and outgoing", EUR: 15, DKK: 110, SEK: 165, NOK: 165, USD: 15, GBP: 13 },
      { Transaction: "DKK IN & OUT + 0.45% on all in and outgoing", EUR: 2, DKK: 15, SEK: 20, NOK: 20, USD: 2, GBP: 1.8 },
      { Transaction: "FX FEE", EUR: "3%", DKK: "3%", SEK: "3%", NOK: "3%", USD: "3%", GBP: "3%" },
    ]
  }
];

type CardFeeData = {
  Transaction: string;
  DKK: string;
  EUR: string;
  GBP: string;
}

const cardsFeeData: CardFeeData[] = [
  { Transaction: "Price per month", DKK: "FREE", EUR: "FREE", GBP: "FREE" },
  { Transaction: "Setup and delivery", DKK: "FREE", EUR: "FREE", GBP: "FREE" },
  { Transaction: "Load fee ** Micro + Starter", DKK: "2%", EUR: "2%", GBP: "2%" },
  { Transaction: "Load fee ** Business + Enterprise", DKK: "1%", EUR: "1%", GBP: "1%" },
  { Transaction: "ATM fee *", DKK: "7 DKK", EUR: "1 EUR", GBP: "0.75 GBP" },
  { Transaction: "Point of sales fee", DKK: "FREE", EUR: "FREE", GBP: "FREE" },
  { Transaction: "FX fee", DKK: "2.75%", EUR: "2.75%", GBP: "2.75%" },
  { Transaction: "Lost or Stolen", DKK: "67.50 DKK", EUR: "9 EUR", GBP: "7.5 GBP" }
];

const OMWFees = () => {
  return (
    <div className="OMWFeesBox m-1 m-sm-3 p-2 p-sm-4">
      <p className="OMWFeesheadings">OneMoneyWay Schedule of Charges</p>
      <h4 className="fee-table-heading">Business Accounts</h4>
      {businessAccountFeeData.map((categoryData, index) => (
        <BusinessAccountsFeeTable
          key={index}
          category={categoryData.category}
          data={categoryData.data}
        />
      ))}
      <h4 className="fee-table-heading mt-3">Business Cards</h4>
      <CardFeeTable data={cardsFeeData} />
      <p className="mt-2" style={{ fontSize: "12px", color: "gray" }}>* For cards with ATM functionality activated.</p>
      <p className="mt-2" style={{ fontSize: "12px", color: "gray" }}>** The load fee can also be based on the monthly card loading amount. For clients with monthly card loads below 10,000 EUR, the loading fee can be 2%. For clients with monthly card loads above 10,000 EUR, the loading fee can be 1%.</p>
    </div>
  );
};

export default OMWFees;

// BusinessAccountsFeeTable component to render the table with business accounts fee information
const BusinessAccountsFeeTable: React.FC<ClientCategory> = ({ category, data }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell colSpan={7} align="center" className={classes.categoryHeader}>
              <h5 className={classes.headerRow}>{category}</h5>
            </TableCell>
          </TableRow>
          <TableRow className={classes.subHeaderRow}>
            <TableCell className={classes.tableCell}>Transaction</TableCell>
            <TableCell className={classes.tableCell} align="center">EUR</TableCell>
            <TableCell className={classes.tableCell} align="center">DKK</TableCell>
            <TableCell className={classes.tableCell} align="center">SEK</TableCell>
            <TableCell className={classes.tableCell} align="center">NOK</TableCell>
            <TableCell className={classes.tableCell} align="center">USD</TableCell>
            <TableCell className={classes.tableCell} align="center">GBP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} className={classes.dataRow}>
              <TableCell component="th" scope="row" className={classes.tableCell} >
                {row.Transaction}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>{row.EUR}</TableCell>
              <TableCell align="center" className={classes.tableCell}>{row.DKK}</TableCell>
              <TableCell align="center" className={classes.tableCell}>{row.SEK}</TableCell>
              <TableCell align="center" className={classes.tableCell}>{row.NOK}</TableCell>
              <TableCell align="center" className={classes.tableCell}>{row.USD}</TableCell>
              <TableCell align="center" className={classes.tableCell}>{row.GBP}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// CardFeeTable component to render the table cards related fee
const CardFeeTable: React.FC<{ data: CardFeeData[] }> = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.headerRow}>
          <TableRow className={classes.subHeaderRow}>
            <TableCell></TableCell>
            <TableCell className={classes.tableCell} align="center">DKK</TableCell>
            <TableCell className={classes.tableCell} align="center">EUR</TableCell>
            <TableCell className={classes.tableCell} align="center">GBP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} className={classes.dataRow}>
              <TableCell component="th" scope="row" className={classes.tableCell}>
                {row.Transaction}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">{row.DKK}</TableCell>
              <TableCell className={classes.tableCell} align="center">{row.EUR}</TableCell>
              <TableCell className={classes.tableCell} align="center">{row.GBP}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};