import { createSlice } from "@reduxjs/toolkit";
import { Logout } from "../auth/operation";
import { CartPaymentsResponse } from "./interface";
import { getMonthlyRevenue } from "./operation";

const initialState = {
  data: {},
} as CartPaymentsResponse;

const cardPaymentsSlice = createSlice({
  name: "cardPayments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMonthlyRevenue.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(getMonthlyRevenue.fulfilled, (state: any, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(getMonthlyRevenue.rejected, (state: any) => {
      state.status = "failed";
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

export default {
  cardPaymentsSlice
}
