import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SmsIcon from '@mui/icons-material/Sms';
import CallIcon from '@mui/icons-material/Call';
import OtpInput from "../OTP";
import InputTypeText from "../Input/Basic/InputTypeText";
import BasicButton from "../../shared/Buttons/Basic";
import IconButton from "../../shared/Buttons/IconButton";
import Accordion from 'react-bootstrap/Accordion';
import "./index.css";

import { sendCallOtpSelec, sendSmsOtpSelec } from "../../../redux/toolkit/auth/selector";
import { verifyOTPSelec, verifyQRSelec } from "../../../redux/toolkit/verifyPayment/selector";
import { SendOTP, SendOTPVoice } from "../../../redux/toolkit/auth/operation";
import { OTPResponse } from "../../../redux/toolkit/auth/interface";
import { AppDispatch } from "../../../redux/store";
import { OTPPaymentPayload, OTPPaymentResponse, QrPaymentPayload, QrPaymentResponse } from "../../../redux/toolkit/verifyPayment/interface";
import { OTPVerificationPayment, QrVerificationPayment } from "../../../redux/toolkit/verifyPayment/operation";
import { PaymentVerifictionIcon } from "../../../constants/Images";

const StrongCustomerAuthentication = (props: { setAuthCompleted: React.Dispatch<React.SetStateAction<boolean>>, showImage: boolean }) => {
  const { setAuthCompleted, showImage } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [code, setCode] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>(false);
  const [otpBtnText, setOtpBtnText] = useState<string>("Send");

  const userPhoneNo: string = localStorage.getItem("userPhone")!;
  const userEmail: string = localStorage.getItem("userEmail")!;

  const sendSmsOTPSelector = useSelector(sendSmsOtpSelec);
  const sendCallOTPSelector = useSelector(sendCallOtpSelec);
  const verifyOTPSelector = useSelector(verifyOTPSelec);
  const verifyQRSelector = useSelector(verifyQRSelec);

  const sendOtpSms = (e: React.MouseEvent<HTMLElement>) => {
    const payload = {
      phoneNo: userPhoneNo,
    };
    dispatch(SendOTP(payload))
      .unwrap()
      .then((resp: OTPResponse) => {
        console.log("SMS OTP sent");
        setOtpBtnText("Resend");
      })
      .catch((err) => {
        console.log("ERROR sending user OTP:", err);
      });
  };

  const sendOtpCall = (e: React.MouseEvent<HTMLElement>) => {
    const payload = {
      phoneNo: userPhoneNo,
    };
    dispatch(SendOTPVoice(payload))
      .unwrap()
      .then((resp: OTPResponse) => {
        console.log("Calling user for OTP");
        setOtpBtnText("Resend");
      })
      .catch((err) => {
        console.log("ERROR calling user for OTP:", err);
      });
  };

  const verifyOTPHelper = (e: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>, method: "app" | "sms") => {
    let payload: OTPPaymentPayload | QrPaymentPayload
    switch (method) {
      case "app":
        e.preventDefault();
        payload = {
          phoneNo: userPhoneNo,
          email: userEmail,
          token: code,
        };
        // console.log("User chose app", payload);
        dispatch(QrVerificationPayment(payload))
          .unwrap()
          .then((resp: QrPaymentResponse) => {
            if (resp.data) {
              setAuthCompleted(true);
            }
          })
          .catch((err) => {
            console.log("ERROR verifying user QR:", err);
            setAuthCompleted(false);
          });
        break;
      case "sms":
        payload = {
          phoneNo: userPhoneNo,
          otp: code,
        };
        dispatch(OTPVerificationPayment(payload))
          .unwrap()
          .then((resp: OTPPaymentResponse) => {
            if (resp.data) {
              setAuthCompleted(true);
            }
          })
          .catch((err) => {
            console.log("ERROR verifying user SMS OTP:", err);
            setAuthCompleted(false);
          });
        break;
      default:
        break;
    }
  };

  const onSmsOTPChange = (value: string) => setCode(value);

  return (
    <>
      {showImage &&
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="mb-2">
            <img src={PaymentVerifictionIcon} width='100' height='100' />
          </div>
          <div className="paymentVerifyText mb-3">
            <h2 className="ver-right-title m-0">{t("cardsSettings.userAuthentication")}</h2>
          </div>
        </div>}
      {/* ============== New Accordion for SCA Verification ============== */}
      <Accordion>
        <Accordion.Item eventKey="0" className="mb-1 p-0 accordionTab">
          <Accordion.Header>{t("verifyNew.2faDescription")}</Accordion.Header>
          <Accordion.Body>
            <form
              className="verification-form"
              onSubmit={(e) => verifyOTPHelper(e, "app")}
            >
              <InputTypeText
                placeholder={t("verifyNew.enterCode")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
              />
              <BasicButton
                type="submit"
                text={verifyQRSelector.status === "loading"
                  ? t("verifyNew.verifying")
                  : verifyQRSelector.status === "succeeded" && verifyQRSelector.data.message
                    ? t("verifyNew.verified")
                    : t("verifyNew.verify")
                }
                isLoading={verifyQRSelector.status === "loading"}
                disabled={code.length < 6
                  ? true
                  : verifyQRSelector.status === "succeeded" && verifyQRSelector.data.message
                    ? true
                    : false
                }
              />
            </form>
          </Accordion.Body>
        </Accordion.Item>
        <div style={{ margin: 0, padding: 0 }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#666" }}>
            OR
          </div>
        </div>
        <Accordion.Item eventKey="1" className="mt-1 p-0 accordionTab">
          <Accordion.Header>{t("verify.otp.description")}</Accordion.Header>
          <Accordion.Body>
            <div className="mr-xl-1">
              <p>{t("verifyNew.otpSmsDescription")}</p>
            </div>

            <div className="mr-xl-1">
              <IconButton
                // hidden={sendSmsOTPSelector.status === "succeeded"}
                text={sendSmsOTPSelector.status === "loading" ? <> {t("verifyNew.sending")} <Spinner animation={"border"} /> </> 
                :  (`${otpBtnText === 'Send' ? t("verifyNew.send") : t("verifyNew.resend")} ${userPhoneNo}`)
                // (t("verifyNew.Sendto") + ` ${userPhoneNo}`)
              }
                isLoading={sendSmsOTPSelector.status === "loading" ? true : false}
                disabled={sendSmsOTPSelector.status === "loading" ? true : false}
                onClick={sendOtpSms}
                suffixIcon={<SmsIcon />}
              />
            </div>

            {sendSmsOTPSelector.status === "succeeded" &&
              <>
                <div style={{ alignContent: "center", width: "100%", padding: 10 }}>
                  <OtpInput value={code} valueLength={6} onChange={onSmsOTPChange} onBlur={() => { return true }} />
                  <div className="mr-xl-1">
                    <BasicButton
                      type="button"
                      text={verifyOTPSelector.status === "loading"
                        ? t("verifyNew.verifying")
                        : verifyOTPSelector.status === "succeeded" && verifyOTPSelector.data.message
                          ? t("verifyNew.verified")
                          : t("verifyNew.verify")
                      }
                      className="mt-2"
                      disabled={code.length < 6
                        ? true
                        : verifyOTPSelector.status === "succeeded" && verifyOTPSelector.data.message
                          ? true
                          : false
                      }
                      onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => verifyOTPHelper(e, "sms")}
                      isLoading={verifyOTPSelector.status === "loading" ? true : false}
                    />
                  </div>
                </div>
                <div className="mr-xl-1">
                  <div onClick={sendOtpCall} style={{ fontSize: 13 }}>
                    {t("verifyNew.resendP1")}
                    {sendCallOTPSelector.status === 'loading' ? (
                      <Spinner
                        style={{ width: 15, height: 15 }}
                        animation={"border"}
                      />
                    ) : (
                      <CallIcon
                        color="primary"
                        style={{ cursor: "pointer" }}
                      />
                    )}{" "}
                    {t("verifyNew.resendP2")}
                  </div>
                </div>
              </>
            }
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
};

export default StrongCustomerAuthentication;