import React from "react";
import "../index.css";
import { Spinner } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ContainerButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: "100%",
  border: "none",
  borderRadius: "10px !important",
  fontSize: "18px !important",
  padding: "8px !important",
  backgroundColor: "#006FF4 !important",
  textTransform: 'none',
  cursor: "pointer !important",
  color: "#FFFFFF !important",
  fontFamily: "Montserrat, sans-serif !important",
  fontWeight: '600 !important',
  // height:'40px',
  "&:hover": {
    backgroundColor: "#006FF4 !important",
  },
  "&:disabled": {
    backgroundColor: "#A7CAED !important",
  },
  '@media screen and (max-width: 450px)': {
    fontSize: "14px !important", // Adjust font size for screens with max-width of 768px
  },
}));
const OutlinedButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: "100%",
  border: "2px solid #006FF4",
  borderRadius: "10px !important",
  fontSize: "18px !important",
  padding: "7px !important",
  backgroundColor: "#FFFFFF !important",
  color: "#006FF4 !important",
  textTransform: 'none',
  cursor: "pointer",
  fontFamily: "Montserrat, sans-serif !important",
  fontWeight: '600',
  "&:hover": {
    backgroundColor: "#FFFFFF !important",
    border: "2px solid #006FF4 !important",
  },
  '@media screen and (max-width: 450px)': {
    fontSize: "14px !important", // Adjust font size for screens with max-width of 768px
  },
}));

const BasicButton = (props: any) => {
  // return <button className={`basicButton ${props?.styleClass || ""}`} {...props}>
  //   {props?.isLoading ? <Spinner animation={"border"} />: props?.text}
  //   {props?.isLoading ? <CircularProgress color="inherit" size={30}/>: props?.text}
  //   </button>;

  return props?.variant ? (
    <OutlinedButton variant="outlined" {...props}>
      {props?.isLoading ? (
        <CircularProgress color="inherit" size={28} />
      ) : (
        props?.text
      )}
    </OutlinedButton>
  ) : (
    <ContainerButton variant="contained" {...props}>
      {props?.isLoading ? (
        <CircularProgress color="inherit" size={28} />
      ) : (
        props?.text
      )}
    </ContainerButton>
  );
};

export default BasicButton;
