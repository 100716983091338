import React, { useState,useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch } from "../../redux/store";
import "./index.css";
import { useDispatch } from "react-redux";
import html2pdf from "html2pdf.js/dist/html2pdf.js";
import Logo from "../../assets/images/omw-logo.png";
import CurrencyFlag from "react-currency-flags";
import ModalHeader from "../../components/shared/Modal/ModalHeader";
import { getAccountStatementPdf } from "../../redux/toolkit/getAllUserAccount/operation";
import { format } from "date-fns";




const ReactToPrintModal = (props: any) => {
  // console.log("ReactToPrintModal: =>", props);
  const { t } = useTranslation();
  const statementModalRef = useRef(null);
  // localStorage
  const accData: any = localStorage.getItem("AccountData");
  const ParsedData: any = JSON.parse(accData);
  const userId: any = localStorage.getItem("userId");
  const language: any = localStorage.getItem("lng");

  // hooks
  const [isloading, setIsloading] = useState(false);
  const [isSentloading, setIsSentloading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalPdfBytes, setModalPdfBytes] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  
  useEffect(() => {
    if(props?.showModal) 
      getData()
  },[props?.showModal]);

  const toggleDisplayModal = () => {
    setDisplayModal(!displayModal);
    props?.closeModal();
  }

  const getData = async()=>{
    const payload = {
      startDate:props?.pdfData?.startDate,
      endDate:props?.pdfData?.endDate,
      accB4BUUID: props?.pdfData?.accUUID,
      language,
      search:props?.pdfData?.search ? props?.pdfData?.search: ""
    };
    const res: any = await dispatch(getAccountStatementPdf(payload));
    // console.log("=>",res)
    if (res.payload){
      const data = JSON.parse(JSON.stringify((res as any).payload?.data));
      const pdf = JSON.parse(JSON.stringify((res as any).payload?.pdfBytes));
      setModalPdfBytes(pdf)
      const tableData = data?.map((d: any, index: number) => {
        return {
          date: format(new Date(d.created_at), "yyyy-MM-dd"),
            reference: (`${d.details ? d.details + ',' : ''}
            ${d.posting_key == 'cr' && d?.payer_name ? d?.payer_name + ',' : ''} 
            ${d.posting_key == 'cr' && d?.payer_account ? d?.payer_account + ',' : ''} 
            ${d.posting_key == 'cr' && d?.payer_scheme ? d?.payer_scheme + ',' : ''}
            ${d.posting_key == 'cr' && d?.reference?.includes('Payment Reversal') ? d?.reference + ',' : ''} 
            ${d.posting_key == 'dr' && d?.reference ? d?.reference + ',' : ''} 
            ${d.posting_key == 'dr' && d?.offsetting_transaction_entity ? d?.offsetting_transaction_entity + ',' : ''} 
            `).replace(/(\r\n|\n|\r|\s+)/gm, " ").trim(),
            postingKey: d.posting_key,
            amount: d.amount,
            newBalance: d.new_balance,
            currency: d.currency,
            payerName: d.payer_name || "", //for 'cr'
            payerAccount: d.payer_account || "", //for 'cr'
            payerScheme: d.payer_scheme || "", //for 'cr'
            offsettingType: d.offsetting_transaction_type || "", //for 'dr'
            offsettingEentity: d.offsetting_transaction_entity || "", //for 'dr'
        };
      });
      setModalData(tableData)
      setDisplayModal(true);      
    }

    }

  


  let bnkCode = "";


  if (props?.info?.accountInfo?.IBAN?.slice(0, 2) === "DK") {
    bnkCode = props?.info?.accountInfo?.IBAN.slice(4, 8);
  }

  const dispatch = useDispatch<AppDispatch>();



  const createStatementPDF = () => {
    setIsloading(true);
    const element = document.getElementById("statementCapture"); // Replace 'html-content' with the id of your HTML element
    const fileName = `${props?.pdfData?.accountData?.accountName}_${new Date().toISOString().slice(0, 10)}.pdf`;
    const opt = {
      filename: fileName,
      margin: [0, 0, 0, 0],
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 96, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
    setIsloading(false);
  };

  const downloadStatementPDFBytes = () => {
    try {
      // const pdfBytes = props?.pdfData?.pdfBytes;

      if (modalPdfBytes) {
        setIsloading(true);
        const uint8Array = Uint8Array.from(atob(modalPdfBytes), (c) => c.charCodeAt(0));
        const blob = new Blob([uint8Array], { type: 'application/pdf' });

        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'receipt.pdf';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.log('Error Occured ', error)
    }
    setIsloading(false);
  }


  const sendStatement = () => {
    alert('write function')
  };

  return (
    <div>
      {/* <Modal show={props?.showModal} onHide={props?.closeModal} size="xl" centered  dialogClassName="my-modal"> */}
      <Modal show={displayModal} onHide={toggleDisplayModal} size="xl" centered  dialogClassName="my-modal">
        {/* <div className="modal-header-cont">
          <Modal.Header className="" style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
            <IconButton style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }} onClick={props.closeModal}>
              <CloseIcon style={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Modal.Header>
        </div> */}
        {/* <ModalHeader onClose={props.closeModal} modalBodyRef={statementModalRef}/> */}
        <ModalHeader onClose={toggleDisplayModal} modalBodyRef={statementModalRef}/>
        <Modal.Body>
          <div id="statementCapture" className="modal-body resClass" ref={statementModalRef}>
            <div className="d-flex flex-row justify-content-between align-items-start w-100">
              {/* bg-light */}
              <div className="w-100">
                {/* <h4 className="fw-bold my-2">{props?.info?.billedTo}</h4>
              <h6 className="fw-bold text-secondary mb-1">Invoice #: {props?.info?.invoiceNumber}</h6> */}
              </div>
              {/* <div className="text-end ms-2">
                <div className="general_logo-box">
                  <img style={{ width: 220, marginRight: '50px' }} src={Logo} alt="logo" />
                </div>
              </div> */}
            </div>
            <div style={{ overflowX: 'clip' }}>
              <Row className="mb-4 account-detail-row">
                <Col md={12}>
                  <div className="text modal-title">{t("statement.pdf.accountStatement")}</div>

                  <div className="text"> <span style={{fontWeight: 600}}> {t("statement.pdf.companyName")} : </span>{props?.pdfData?.company?.companyName}</div>
                  <div className="text"><span style={{fontWeight: 600}}>{t("statement.pdf.address")}</span>: {props?.pdfData?.company?.companyAddress}</div>
                  <div className="text">
                    <span style={{fontWeight: 600}}>{t("statement.pdf.regNo")}</span>: {props?.pdfData?.regNo}
                  </div>
                  <div className="text">
                    <span style={{fontWeight: 600}}>{t("statement.pdf.accountType")}</span>: Current
                  </div>
                  <div className="text">
                    <span style={{fontWeight: 600}}>{t("statement.pdf.currency")}</span>: {props?.pdfData?.accountData?.currency}
                  </div>
                  <div className="text">
                    <span style={{fontWeight: 600}}>{t("statement.pdf.iban")}</span>: {props?.pdfData?.accountData?.IBAN}
                  </div>
                  <div><span style={{fontWeight: 600}}>{t("statement.pdf.swift")}</span>: {props?.pdfData?.accountData?.swiftCode}</div>
                  {
                    props?.pdfData?.accountData?.IBAN?.slice(0, 2) === "DK" && props?.pdfData?.accountData?.currency === 'DKK'  ? (
                      <>
                      <div><span style={{fontWeight: 600}}>{t("statement.pdf.bankCode")}</span>: {props?.pdfData?.accountData?.IBAN.slice(4, 8)}</div>
                      <div><span style={{fontWeight: 600}}>{t("statement.pdf.accountNumber")}</span>: {props?.pdfData?.accountData?.accountNumber || props?.pdfData?.accountData?.IBAN.slice(-10)}</div>
                      </>
                    ) : null
                  }
                </Col>

              </Row>
              <Row className="mt-2">
                <div className="d-flex flex-row justify-content-between align-items-center align-items-sm-start w-100">
                  <div className="w-100">
                    <div style={{fontWeight: 600}} className="history">{t("statement.pdf.history")}</div>
                  </div>
                  <div className="text-end w-100 period">

                    <div style={{fontWeight: 600}}>
                      {t("statement.pdf.period")}:
                      <span style={{ color: '#ADB6C0', fontWeight: 500 }}> {props?.pdfData?.startDate} - {props?.pdfData?.endDate}</span>

                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <Col style={{ overflowX: 'scroll' }}>
                  <Table className="mb-0 mt-3">
                    <thead >
                      <tr>
                        {/* CreatedAt */}
                        <th style={{ backgroundColor: '#006FF4', color: '#ffffff', borderRadius: '8px 0px 0px 8px' }} className="table-heading tbl-date">{t("statement.table.date")}</th>
                        {/* reference */}
                        <th style={{ backgroundColor: '#006FF4', color: '#ffffff' }} className="table-heading tbl-ref">{t("statement.table.reference")}</th>
                        {/* posting_key=”cr" */}
                        <th style={{ backgroundColor: '#006FF4', color: '#ffffff',whiteSpace:'nowrap' }} className="table-heading tbl-paid text-center">{t("statement.table.paidIn")}</th>
                        {/* posting_key=”dr” */}
                        <th style={{ backgroundColor: '#006FF4', color: '#ffffff', whiteSpace:'nowrap' }} className="table-heading tbl-paid text-center">{t("statement.table.paidOut")}</th>
                        {/* new_balance */}
                        <th style={{ backgroundColor: '#006FF4', color: '#ffffff' }} className="table-heading text-center">{t("statement.table.balance")}</th>
                        {/* currency */}
                        <th style={{ backgroundColor: '#006FF4', color: '#ffffff', borderRadius: '0px 8px 8px 0px' }} className="table-heading text-center" >{t("statement.table.currency")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {props.pdfData.tableData.map((ele: any, index: any) => ( */}
                      {modalData?.map((ele: any, index: any) => (
                        <tr key={index}>
                          <td className="table-data tbl-data-padding" style={{ width: "115px" }}>{ele.date}</td>
                          <td className="tbl-data-padding">
                            <p className="table-data m-0">{ele.reference && ele?.reference.replace(/,+$/g, '')}</p>
                            {/* {(ele.postingKey == 'cr' && ele?.payerName) && <p className="table-data m-0">{ele.payerName + ","} </p>}
                            {(ele.postingKey == 'cr' && ele?.payerAccount) && <p className="table-data m-0">{ele.payerAccount + ","} </p>}
                            {(ele.postingKey == 'cr' && ele?.payerScheme) && <p className="table-data m-0">{ele.payerScheme + ","} </p>}
                            {(ele.postingKey == 'dr' && ele?.offsettingType) && <p className="table-data m-0">{ele.offsettingType + ","} </p>}
                            {(ele.postingKey == 'dr' && ele?.offsettingEentity) && <p className="table-data m-0">{ele.offsettingEentity + ","} </p>} */}
                          </td>
                          <td className="table-data text-center tbl-data-padding paidIn-paidOut-" style={ele.postingKey == 'cr' ? { display:'flex', color: '#05D401',fontWeight: 600 } : { width: "120px", color: '#05D401',fontWeight: 600 }}>
                            {ele.postingKey == 'cr' && <img className="statement-paidIn-paidOut-icon" src={props.pdfData.paidInIocn} style={{ marginRight: "5px" }} />}
                            {ele.postingKey == 'cr' && ele.amount}
                          </td>
                          <td className="table-data text-center tbl-data-padding" style={ele.postingKey == 'dr' ? {display: "flex", color: '#F40F00',fontWeight: 600} :   { width: "120px", color: '#F40F00',fontWeight: 600 }}>
                            {ele.postingKey == 'dr' && <img className="statement-paidIn-paidOut-icon" src={props.pdfData.paidOutIcon} style={{ marginRight: "5px",transform: "rotate(180deg)" }} />}
                            {ele.postingKey == 'dr' && '-' + ele.amount}
                          </td>
                          <td className="table-data text-center tbl-data-padding" style={{ width: "100px" }}>{ele.newBalance}</td>
                          <td className="table-data text-center tbl-data-padding" >
                            <div  className="countryFlag" style={{display:"flex", alignItems:'center',gap:"2px"}}>
                              <CurrencyFlag currency={ele?.currency} /> {ele?.currency}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Col md={12} className="mt-4" style={{ width: "100%", justifyContent: 'center', textAlign: 'center' }}>
                <div style={{fontWeight: 600}} className="pb-2 table-data-created">{t("statement.pdf.dateCreated") + ": " + new Date().toLocaleDateString()}</div>
                <div className="statement-copyright" >{t("statement.pdf.desc")}</div>
                <div className="statement-copyright" >{t("statement.pdf.copyright")}</div>
              </Col>
            </div>
          </div>
        </Modal.Body>
        <div className="modalFooter pb-4 px-4 d-flex justify-content-center">
          <Row className="btn-generate-pdf-row">
            {/* Generate button */}
            {/* {!props?.isOpenEditPreview ? (
              <Button variant="primary" type="submit"
                disabled={props.generateInvoiceLoading ? true : false}
                onClick={props.onGenerateInvoice}
                className="d-block w-100">{props.generateInvoiceLoading ?
                  (<Spinner animation={"border"} />)
                  : props?.pdfData?.accountData?.currency === "SEK" ?
                    t("invoice.preview.sendPayex")
                    : t("invoice.preview.generate")}
              </Button>) : null} */}

            {/* Generate PDF button */}
            <Button variant="primary" type="button"
              disabled={props?.generateInvoiceLoading ? true : false}
              style={{ borderRadius: '10px',fontWeight: 600 }}
              onClick={props?.isFile ? downloadStatementPDFBytes : createStatementPDF}
              className="d-block w-100 mt-2">{isloading ?
                (<Spinner animation={"border"} />)
                : t("statement.pdf.generatePDF")}
            </Button>


            {/* sen pdf button */}
            {/* <Button variant="primary" type="button"
              disabled={props.generateInvoiceLoading ? true : false}
              onClick={sendStatement}
              className="d-block w-100 mt-2">{isSentloading ?
                (<Spinner animation={"border"} />)
                : t("invoice.preview.sendPDF")}
            </Button> */}
          </Row>
        </div>
      </Modal>
      {/* <hr className="mt-4 mb-3" /> */}
    </div>
  );
};

export default ReactToPrintModal;
