import React, { useEffect, useState } from "react";
// import Arrow2 from "../../../assets/images/arrow-send.png";
// import Convert from "../../../assets/images/convert-founds.png";
// import BankCard from "../BankCard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getAllAccounts } from "../../../redux/toolkit/getAllUserAccount/operation";
import { useDispatch } from "react-redux";
import { getCardAccountBalance } from "../../../redux/toolkit/accountBalances/operation";
import "./index.css";
import InterestAccountCard from "../InterestAccountCard";

const InterestAccountPageCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const BalanceSelector: any = useSelector(
    (state: RootState) => state.getAllAccountBalance.data
  );
  const [show, setShow] = useState(false);
  const [cardDetails, setCardDetails] = useState<[]>([]);
  const [balance, setBalance] = useState("1565");
  const [flag, setFlag] = useState(false);
  const [bankCode, setBankCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [accountId, setAccountId] = useState<any>(
    localStorage.getItem("accountId")
  );
  const accountSelector: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );
  const accountData: any = localStorage.getItem("AccountData");
  const parsedData: any = JSON.parse(accountData);
  const { t } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    if (parsedData?.IBAN.slice(0, 2) === "DK") {
      setBankCode(parsedData?.IBAN.slice(4, 8));
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [bankCode, flag]);

  useEffect(() => {
    dispatch(getAllAccounts(accountId));
  }, [dispatch]);

  useEffect(() => {
    if (accountSelector?.data) {
      setCardDetails(accountSelector?.data.data);
    }
  }, [accountSelector, cardDetails]);

  useEffect(() => {
    dispatch(getCardAccountBalance(accountId)).then(() => setLoader(true));
  }, [dispatch, accountId]);

  useEffect(() => {
    if (BalanceSelector?.data) {
      setBalance(BalanceSelector?.data.data?.float);
      setLoader(false);
    }
  }, [BalanceSelector, loader]);

  useEffect(() => {
    document.addEventListener("CardValue", (event: any) => {
      const { detail } = event;
      setAccountId(detail.accountId);
    });
  }, []);

  const eyeClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className="interest-balance-box">
      <div className="interest-balance-box_wrapper">
        <InterestAccountCard
          ibanNo={parsedData?.IBAN}
          currency={parsedData?.currency}
          handleEyeClick={eyeClick}
          name={parsedData?.accountName}
          hideInfo
          showLogo
        />
        <div className="interest-balance-box-text">
          <p className="interest-balance-text">{t("interestAccount.balanceText")}</p>
          <div className="acc-balance">
            <p className="balance">{loader ? <Spinner /> : balance}</p>
            <p className="balance-currency">{parsedData?.currency}</p>
          </div>
        </div>
        {/* <div className="card-balance-btn-wrapper">
          <button
            onClick={() => navigate("/sendFunds")}
            className="send-founds-btn"
          >
            <img className="balance-plus-btn" src={Arrow2} alt="plus" />
            {t("cardDetails.transfer")}
          </button>
          <button
            onClick={() => navigate("/convert")}
            className="convert-founds-btn"
          >
            <img className="balance-plus-btn" src={Convert} alt="plus" />
            {t("cardDetails.convert")}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default InterestAccountPageCard;
