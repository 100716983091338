import React, { useEffect } from "react";
import { FormattedCard } from "../interface";
import "../index.css";
import CardTemplate from "../../../assets/images/cardAssets/omwCardTemplate.svg"
import { useTranslation } from "react-i18next";

const PrepaidCard = (props: {
  activeCard?: string,
  showStatus?: boolean,
  data: FormattedCard,
  onClick: (card: FormattedCard, cardId: string) => void
}) => {
  const { activeCard, showStatus, data, onClick } = props;
  const { t } = useTranslation();

  const handleCardClick = () => {
    onClick(data, data.cardId); // Call the onClick function with the cardId
  };

  function maskCardNumber(cardNumber: string) {
    // Assuming cardNumber is a string with the last 4 digits
    const masked = '*'.repeat(12) + cardNumber;
    return masked.replace(/(.{4})/g, '$1 ');
  }

  return (
    <div className="cardContainer">
      <div className="cardGeneralInfo">
        <p className="availableBalanceText">{data.cardType}</p>
        <p className="amountCurrencyText">{data.availableBalance} {data.currency}</p>
        <p className="cardTypeText">**** {data.maskedCardNumber}</p>
      </div>
      <img
        onClick={handleCardClick}
        src={CardTemplate}
        className={`${activeCard} cardTemplateImage`}
      />
      {showStatus && <p className="amountCurrencyText m-0"><strong style={{fontWeight: 600}}>{t("bankAccountCards.cardHolderName")}</strong> {data.embossedName}</p>}
      <div className="cardGeneralInfo">
        <p className="cardTypeText"><strong style={{fontWeight: 600}}>{t("bankAccountCards.label")}</strong> {data.cardLabel || "-"}</p>
      </div>
      {showStatus && <p className="cardStatusText"><strong style={{fontWeight: 600}}>{t("bankAccountCards.cardStatus")}</strong> {data.status}</p>}
    </div>
  );
};

export default PrepaidCard;

// {/* <div className="cardGeneralInfo">
//         <p className="cardTypeText"><strong>Label:</strong> {data.cardLabel || "-"}</p>
//       </div>
//       {data.cardType === "Virtual Card"
//         ? <div
//           className={`cardBody ${activeCard}`}
//           onClick={handleCardClick} // Attach the click handler
//         >
//           <div className="cardOMWLabel">
//             <img src={CardLogo} />
//           </div>
//           <div className="maskedCardNumberDiv">
//             <p className="maskedCardNumber" style={{ fontFamily: 'OCR A Extended' }}>{maskCardNumber(data.maskedCardNumber)}</p>
//           </div>
//           <div className="cardHolderDiv">
//             <p className="cardHolderName" style={{ fontFamily: 'OCR A Extended' }}>{data.embossedName}</p>
//             <img className="visaVector" src={MasterCardVector} />
//           </div>
//         </div>
//         :
//         // <div
//         //   className={`${activeCard} physicalCardBody`}
//         //   onClick={handleCardClick} // Attach the click handler
//         // >
//         // </div>
//         <img
//           onClick={handleCardClick}
//           src={CardTemplate}
//           className={`${activeCard} physicalCardImage`} />
//       } */}