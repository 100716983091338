import { io } from "socket.io-client";
import { store } from "../redux/store";
import BASE_URL from "../utils/baseURL";

let _socket: any = null

const connectSocket = (jwtToken: string) => {
  const userId: any = localStorage.getItem("userId");

  const socket = io(BASE_URL, {
    withCredentials: true,
    auth: {
      authorization: `Bearer ${jwtToken}`,
    },
    query: {
      userId: userId,
    }
  });
  socket.connect()
  socket.on("connect", () => {
    _socket = socket
    store.dispatch({ type: 'SOCKET_REF', payload: socket })
    console.log("socket connected", socket.id)
  })

  socket.on("disconnect", () => {
    _socket = null
    socket.removeAllListeners()
    store.dispatch({ type: 'SOCKET_REF', payload: null })
    console.log("socket disconnected", socket.id); // undefined
  });
}

const disconnectSocket = () => {
  if (_socket !== null)
    _socket.disconnect()
}

export {
  connectSocket,
  disconnectSocket
}