import {
  ExpenseCardResponse,
  UserExpensePayload,
  UserExpenseResponse,
} from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";

export const getUserExpense = createAsyncThunk<
  UserExpensePayload,
  UserExpenseResponse
>("userExpense/getUserExpense", async (userID) => {
  const token = localStorage.getItem("token");
  let data: any;
  try {
    data = await apiHelper(
      `payment/getExpense?accountId=${userID}`,
      "get",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return data;
  } catch (err: any) {
    data = {};
  }
  return data;
});
export const getTheExpenseDetails = createAsyncThunk<ExpenseCardResponse>(
  "exchangeRate,getExchangeRates",
  (): any => {
    const data = [
      {
        place: "Supermarkets",
        operation: "23 operations",
        amount: "1000 $",
      },
      {
        place: "Restaurants",
        operation: "11 operations",
        amount: "1000 $",
      },
      {
        place: "Health",
        operation: "23 operations",
        amount: "1000 $",
      },
      {
        place: "Supermarkets",
        operation: "23 operations",
        amount: "1000 $",
      },
    ];
    return data;
  }
);
