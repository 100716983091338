import React from "react";
import { useTranslation } from "react-i18next";
import { ActiveUserIcon,ActiveUserGif } from "../../constants/Images";

const ActiveUser = (props:any) => {
  const { t } = useTranslation();

  return (
    <div
      style={{ padding: 30, marginBottom: 30, flex: 1 }}
      className="nonActiveUserDiv"
    >
      <img
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "170px",
          marginTop: 10,
          marginBottom: 10,
        }}
        src={ActiveUserGif}
        alt="loading..."
        className="non-active-user-img"
      />
      {/* <h2
        style={{
          color: "rgb(156, 197, 248)",
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        {t("complianceMode.intro")} {props?.firstName} {props?.lastName}!
      </h2> */}
      <p className="thanksForRegisteringtext">
        {/* Thank you for registering with OneMoneyWay, we look forward to
          have you as a client. */}
        {t("complianceMode.parahfirst")}
      </p>
      <p className="detialstext" style={{ marginBottom: 15 }}>
        {/* Your account details will be issued once you have passed through
          our compliance, and will be associated with your user. */}
        {t("complianceMode.parahsecond")}
      </p>
      {/* <h6>
        {t("complianceMode.parahthird")}
      </h6> */}
      <p className="detialstext">
        {" "}
        {t("complianceMode.parahfourth")}{" "}
      </p>
    </div>
  );
};

export default ActiveUser;
