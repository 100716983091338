import React from "react";
import { Divider, IconButton } from "@mui/material";
import { Modal, Button, Spinner, Col } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { CardDetails, OrderDetails } from "../../../../redux/toolkit/cards/interface";
import { SelectedBusinessAccount } from "../../interface";
import moment from "moment";
import * as iso from "iso-3166-1";
import { t } from "i18next";

const CardOrderDetailsModal = (props: {
  viewCardOrderDetails: boolean;
  selectedBusinessAcc: SelectedBusinessAccount;
  cardDetails: CardDetails;
  orderDetails: OrderDetails;
  createdAt: string;
  handleCardActivateClick: () => void;
  handleClose: () => void;
}) => {
  const { viewCardOrderDetails, selectedBusinessAcc, cardDetails, orderDetails, createdAt, handleCardActivateClick, handleClose } = props;

  const updateCardSelector = useSelector(cardSelectors.updateCardSelect);

  return (
    <Modal
      show={viewCardOrderDetails}
      centered
      onHide={handleClose}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Title>{t("cardOrders.modalTitle")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">
        <p className="mb-1" style={{ fontWeight: "600" }}>{t("cardOrders.cardDetails")}:</p>
        <p className="mb-1">{t("cardOrders.businessAccount")}: {selectedBusinessAcc.accountName || '-'}</p>
        <p className="mb-1">{t("cardOrders.embossedName")}: {cardDetails.embossed_name || '-'}</p>
        <p className="mb-1">{t("cardOrders.date")}: {moment(createdAt).format('DD MMM, YYYY')} at {moment(createdAt).format('hh:mm A')}</p>
        <Divider sx={{ background: 'darkGray' }} />

        <p className="mt-3 mb-1" style={{ fontWeight: "600" }}>{t("cardOrders.cardholderInfo")}:</p>
        {cardDetails.cardholder && (
          <>
            <p className="mb-1">{t("cardOrders.cardHolderName")}: {`${cardDetails.cardholder.title || ''} ${cardDetails.cardholder.first_name || ''} ${cardDetails.cardholder.last_name || ''}`}</p>
            <p className="mb-1">{t("cardOrders.DOB")}: {cardDetails.cardholder.dob || '-'}</p>
            <p className="mb-1">{t("cardOrders.email")}: {cardDetails.cardholder.email || '-'}</p>
            <p className="mb-1">{t("cardOrders.telephoneNumber")}: {cardDetails.cardholder.tel || '-'}</p>
            <p className="mb-1">{t("cardOrders.mobileNumber")}: {cardDetails.cardholder.mobile || '-'}</p>
            <p className="mb-1">{t("cardOrders.gender")}: {cardDetails.cardholder.gender || '-'}</p>
          </>
        )}
        <Divider sx={{ background: 'darkGray' }} />

        <p className="mt-3 mb-1" style={{ fontWeight: "600" }}>{t("cardOrders.cardHolderAddress")}: {cardDetails.delivery_address ? "" : `${t("cardOrders.sameDeliveryAddress")}`}</p>
        <p className="mb-1">
          {[
            cardDetails.address.line_1,
            cardDetails.address.line_2 && ` ${cardDetails.address.line_2}`,
            cardDetails.address.line_3 && ` ${cardDetails.address.line_3}`,
            cardDetails.address.city,
            cardDetails.address.post_code,
            iso.whereNumeric(cardDetails.address.country_code)?.country
          ].filter(Boolean).join(", ")}
        </p>
        {/* <Divider sx={{ background: 'darkGray' }} /> */}

        {cardDetails.delivery_address &&
          <>
            <p className="mt-3 mb-1" style={{ fontWeight: "600" }}>{t("cardOrders.deliveryAddress")}:</p>
            <p className="mb-1">{t("cardOrders.fao")}: {cardDetails.delivery_address.fao}</p>
            <p className="mb-1">
              {[
                cardDetails.delivery_address.address.line_1,
                cardDetails.delivery_address.address.line_2 && ` ${cardDetails.delivery_address.address.line_2}`,
                cardDetails.delivery_address.address.line_3 && ` ${cardDetails.delivery_address.address.line_3}`,
                cardDetails.delivery_address.address.city,
                cardDetails.delivery_address.address.post_code,
                iso.whereNumeric(cardDetails.delivery_address.address.country_code)?.country
              ].filter(Boolean).join(", ")}
            </p>
            {/* <Divider sx={{ background: 'darkGray' }} /> */}
          </>
        }

        {/* <p className="mt-3 mb-1" style={{ fontWeight: "600" }}>Order Details:</p>
        <p className="mb-1">Number of cards: {orderDetails.no_of_cards || "NA"}</p>
        <p className="mb-1">Invoiced: {orderDetails.invoiced ? "Yes" : "No"}</p>
        <p className="mb-1">Paid: {orderDetails.paid ? "Yes" : "No"}</p>
        <p className="mb-1">Manufacturing: {orderDetails.manufacturing ? "Yes" : "No"}</p> */}
        {orderDetails.card_numbers.length > 0 && (
          <p className="mb-0">{t("cardOrders.cardCodeNeeded")}: {orderDetails.card_numbers[0]}</p>
        )}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "0px" }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              text={t("cardOrders.activateCard")}
              onClick={handleCardActivateClick}
              isLoading={updateCardSelector.status === "loading"}
              style={{ width: '95%' }}
            // disabled={orderDetails.card_numbers.length === 0}
            />
          </Col>

          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              text={t("cardOrders.ok")}
              onClick={handleClose}
              style={{
                width: '95%',
                backgroundColor: "white",
                color: "#006ff4",
                border: "1px solid #006ff4",
              }}
            />
          </Col>
        </div>
      </Modal.Footer>
    </Modal>
  )
};

export default CardOrderDetailsModal;