import React from "react";
import { Card } from "react-bootstrap";
import CustomTimer from "../../../components/shared/Timer";
import "../index.css";

const TimerModalSection = (props: any) => {
  return (
    <Card className="card-send-msg">
      <div className="verify-titlee">
        <div className="verify-titlee-icon">
          {props?.isTimer ? (
            <img
              src={props?.conversionIcon}
              width="65"
              onClick={props?.refreshHandler}
            />
          ) : (
            <CustomTimer
              timerDuration={props?.timerDuration}
              onComplete={props?.onTimerComplete}
            />
          )}
        </div>
        <div>
          <h3 style={{ color: "#FFFFFF" }}>Convert Funds</h3>
        </div>
      </div>
      <div className="receipt-status">{props?.statusText}</div>
    </Card>
  );
};

export default TimerModalSection;
