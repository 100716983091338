import React from "react";
import { useTranslation } from "react-i18next";

const FundTransferDetails = (props: any) => {
  console.log("FundTransferDetailsprops: ", props);

  const { t } = useTranslation();

  return (
    <div className="">
      <div className="row receipt__data">
        <div className="col-12 conversionDetailText">
          <h6>Bank:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.bank || "-"}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>Recipient:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.recipient || "-"}</p>
        </div>

        {/* <div className="col-12 conversionDetailText">
          <h6>Description:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.description || "-"}</p>
        </div> */}

        <div className="col-12 conversionDetailText">
          <h6>Amount:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.amount || "-"}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>Currency:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.currency || "-"}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>Reason:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.reason || "-"}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>Reference:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.reference || "-"}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>Date:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.date || "-"}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>Payment Status:</h6>
        </div>

        <div className="col-12 conversionDetailText">
          <p>{props?.status || "-"|| "-"}</p>
        </div>

        {props?.showRecurring && (
          <>
            <div className="col-6 fund-send-con">
              <h6>Recurrig Type:</h6>
            </div>
            <div className="col-6 fund-send-con">
              <p>{props?.recurringType || "-"}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default FundTransferDetails;
