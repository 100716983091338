import React from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import CardTemplate from "../../../../assets/images/cardAssets/omwCardTemplate.svg"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createCardPromptActions } from "../../../../redux/toolkit/cards/reducer";

const CreateCardNowPrompt = (props: {
  showCreateCardNowPrompt: boolean,
  setShowCreateCardNowPrompt: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const { showCreateCardNowPrompt, setShowCreateCardNowPrompt } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    setShowCreateCardNowPrompt(false);
    dispatch(createCardPromptActions.shownToUser());
  }

  return (
    <Modal show={showCreateCardNowPrompt} onHide={handleClose} centered >
      <Modal.Header style={{ borderBottom: "0px" }}>
        <div>
          <Modal.Title>{t("createCardPrompt.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <img
            src={CardTemplate}
            className={`cardTemplateViewDetails`}
            onClick={() => navigate("/bankAccountCards", { state: { applyCard: true } })}
            style={{
              cursor: "pointer"
            }}
          />
          <p className="m-0 p-0">
            {t("createCardPrompt.text")}
          </p>
        </div>
      </Modal.Body>
      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="button"
          onClick={() => navigate("/bankAccountCards", { state: { applyCard: true } })}
          text={t("createCardPrompt.buttonText")!}
          spinnerVariant="sm"
        />
      </ModalFooter>
    </Modal>
  )
};

export default CreateCardNowPrompt;