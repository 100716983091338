import React from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./index.css";




// interface Props {
//  timerDuration:number;
// }


const renderTime = ({ remainingTime }:any) => {
    if (remainingTime === 0) {
      return <div className="timer">Too lale...</div>;
    }
  
    return (
      <div className="timer">
        {/* <div className="text">Remaining</div> */}
        <div className="value">{remainingTime}</div>
        <div className="lblSecond">seconds</div>
      </div>
    );
  };


// function CustomTimer({title,description}: Props) {
function CustomTimer({
    timerDuration=30, 
    // colors=["#004777", "#F7B801", "#A30000", "#A30000"],
    colors=["#FFFFFF", "#F7B801", "#A30000", "#A30000"],
    onComplete=() => ({ shouldRepeat: false, delay: 2 })
}:any) {

  return (
    <CountdownCircleTimer
        size={130}
          isPlaying
          duration={timerDuration}
          colors={colors}
          colorsTime={[15, 10, 5, 0]}
          onComplete={onComplete}
        >
          {renderTime}
        </CountdownCircleTimer>
  );
}

export default CustomTimer;